<template>
  <a-modal
    v-model:visible="localVisible"
    title="Edit Category"
    centered
    :footer="null"
    :width="520"
    :z-index="1002"
    :afterClose="close"
    :destroyOnClose="true"
  >
    <template #closeIcon>
      <a-button
        class="ant-btn-sm circular"
        style="width: 36px; height: 36px; background-color: #f0f2f5; top: 2px"
      >
        <FeatherIcon
          type="x"
          size="18"
        />
      </a-button>
    </template>
    <div class="category-bar">
      <div v-if="data && data.analytic">
        <div
          v-for="(category, level) in categoryData"
          :id="`category-item-${msgId}`"
          :key="category.key"
          class="category-item"
        >
          <div class="category-name">{{ category.name }}:</div>
          <EditCategoryBar
            :msg-id="msgId"
            :editable="editable"
            :category-data="category"
            :no-save="true"
            :is-from-modal="true"
            :width-popover="widthPopover"
            @onChange="onCategoryChange"
            @togglePopover="togglePopover()"
          >
            <span class="category-tag">
              <span
                v-for="(c, cIndex) in category.data"
                :key="c"
                class="inner-tag"
                :style="{
                  background: getColorRgb(c, idx, level),
                  color: getColor(c, idx, level),
                }"
                :class="{ 'inactive-category': !getIsActiveCategory(level, c) }"
              >
                <span>{{ c }}</span>
                <span
                  v-if="c !== 'others'"
                  class="close-icon ml-1"
                  @click.stop="removeCategory(level, cIndex)"
                >
                  <FeatherIcon
                    type="x"
                    size="14"
                  />
                </span>
              </span>
            </span>
          </EditCategoryBar>
        </div>
      </div>
    </div>
    <div class="action-button-modal">
      <div class="button-left">
        <a-button
          size="large"
          type="default"
          class="cancel-button"
          :outlined="true"
          @click="close"
        >
          Cancel
        </a-button>
      </div>
      <div class="button-right">
        <a-button
          size="large"
          type="ghost"
          class="apply-all-button mr-2"
          :outlined="true"
          @click="applyWhole"
        >
          Apply to All
        </a-button>
        <a-button
          size="large"
          class="apply-button"
          type="default"
          :class="{
            'apply-button-dirty': isDirty,
          }"
          :disabled="!isDirty"
          @click="applySingle"
          >Apply</a-button
        >
      </div>
      <!-- <div class="button-right">
        <a-button
          size="large"
          type="ghost"
          class="apply-all-button mr-2"
          :class="{
            'dirty-custom': isDirty,
          }"
          :outlined="true"
          :disabled="!isDirty"
          @click="applyWhole"
        >
          Apply to post and comments
        </a-button>
        <a-button
          size="large"
          class="apply-button"
          type="default"
          :class="{
            'ant-btn-primary': isDirty,
          }"
          :disabled="!isDirty"
          @click="applySingle"
          >Apply</a-button
        >
      </div> -->
    </div>
  </a-modal>
</template>

<script>
import { toRefs, watch, ref, inject, computed } from 'vue';
import { useStore } from 'vuex';
import { notification } from 'ant-design-vue';
import EditCategoryBar from '../EditCategoryBar.vue';
import api from '@/services/api';
import helper from '@/services/helper';

export default {
  components: {
    EditCategoryBar,
  },
  props: {
    msgId: {
      type: String,
      default: '',
    },
    data: {
      type: Object,
      default: () => {},
    },
    visible: Boolean,
  },
  emits: ['close'],
  setup(props, { emit }) {
    const store = useStore();
    const catKey = computed(() => store.getters['config/categoryLevelKey']);
    const categoryConfig = computed(() => store.getters['config/categoryData']);
    const editable = computed(
      () => store.getters['account/messageEditableRole']
    );
    // TODO : apply + apply all button for edit
    // Idea - edit cat nosave mode will return event without saving to api
    // Then apply ..
    // Then emit edit cat local
    const { msgId } = toRefs(props);
    const isDirty = ref(false);
    let widthPopover = ref();

    const togglePopover = () => {
      const width = document.getElementById(
        `category-item-${msgId.value}`
      ).clientWidth;
      widthPopover.value = width ? width : 460;
    };

    const categoryLevelMap = computed(() =>
      categoryConfig.value.reduce((catLevelMap, catLevel) => {
        catLevelMap[catLevel.level] = catLevel.categories.reduce(
          (levelMap, cat) => {
            levelMap[cat.category] = cat;
            return levelMap;
          },
          {}
        );
        return catLevelMap;
      }, {})
    );

    // let subCategoryName;
    // let sc = inject('subCategoryName');
    // if (sc) {
    //   subCategoryName = sc;
    // } else {
    //   const fallbackCategory = JSON.parse(localStorage.getItem('category-name'));
    //   subCategoryName = ref(fallbackCategory);
    //   console.log('TRY localStorage method', subCategoryName.value);
    // }
    const onEditMessage = inject('onEditMessage');

    const { visible, data } = toRefs(props);
    const localVisible = ref(false);
    watch(
      () => visible.value,
      () => {
        localVisible.value = visible.value;
        if (!localVisible.value) {
          tempCat.value = {
            category: [],
          };
        }
        isDirty.value = false;
        initCategoryVal();
      }
    );
    const close = () => {
      emit('close');
    };

    const buttonSize = ref('small');
    const categoryData = ref([]);

    const initCategoryVal = () => {
      let result = [];
      if (data.value && data.value.analytic && categoryConfig.value) {
        for (let i in categoryConfig.value) {
          const k = catKey.value[i];
          const l = data.value.analytic[k] || [];
          const cc = categoryConfig.value[i];
          result.push({
            name: cc.name,
            key: k,
            data: l,
          });
        }
      }

      categoryData.value = result;
    };

    const tempCat = ref({
      category: [],
    });
    const onCategoryChange = (obj) => {
      isDirty.value = true;
      const { category: catList, messageId } = obj;
      const listData = JSON.parse(JSON.stringify(categoryData.value));
      let changedData = [];
      if (tempCat.value.category) {
        changedData = tempCat.value.category;
      }
      for (let c of catList) {
        const { level, category } = c;
        const key = catKey.value[level];
        for (let i in listData) {
          if (listData[i].key === key) {
            const obj = {
              name: listData[i].name,
              key,
              data: category,
            };
            listData[i] = obj;
            let foundIndex = changedData.findIndex((obj) => obj.level === i);
            if (foundIndex === -1) {
              changedData.push({
                level: i,
                category,
              });
            } else {
              changedData[foundIndex].category = category;
            }
          }
        }
      }
      tempCat.value = {
        messageId,
        category: changedData,
      };
      categoryData.value = listData;
    };

    const removeCategory = (targetLevel, cIndex) => {
      isDirty.value = true;
      const listData = JSON.parse(JSON.stringify(categoryData.value));
      listData[targetLevel].data.splice(cIndex, 1);

      // if remove all
      if (
        listData[targetLevel] &&
        listData[targetLevel].data &&
        listData[targetLevel].data.length === 0
      ) {
        listData[targetLevel].data = ['others'];
      }

      categoryData.value = listData;

      // update category
      let categoryUpdated = [];
      categoryUpdated.push({
        category: listData[targetLevel].data,
        level: targetLevel,
      });

      onCategoryChange({
        category: categoryUpdated,
        messageId: msgId.value,
      });
    };

    const doUpdate = async (mode, obj) => {
      const { messageId, category } = obj;
      onEditMessage({
        messageId,
        editing: true,
      });
      const result = await api
        .updateCategory(messageId, mode, category)
        .catch(() => {});
      if (result && result.status === 200) {
        notification.success({
          message: 'Success',
          description: 'Message category edited.',
        });
        onEditMessage({
          messageId,
          category,
        });
      } else {
        notification.error({
          message: 'Error',
          description: 'Message category edit failed.',
        });
      }
      onEditMessage({
        messageId,
        editing: false,
      });
      close();
    };

    const applySingle = () => {
      doUpdate('single', tempCat.value);
    };

    const applyWhole = () => {
      // check empCat.value.category = 0
      if (
        tempCat.value &&
        tempCat.value.category &&
        tempCat.value.category.length === 0
      ) {
        let result = [];
        if (data.value && data.value.analytic && categoryConfig.value) {
          for (let i in categoryConfig.value) {
            const k = catKey.value[i];
            const l = data.value.analytic[k] || [];
            const cc = categoryConfig.value[i];
            result.push({
              level: `${cc.level}`,
              category: l,
            });
          }
        }
        tempCat.value['messageId'] = msgId.value;
        tempCat.value['category'] = result;
      }

      doUpdate('applyall', tempCat.value);
    };

    initCategoryVal();

    const getColorRgb = (catKey, index, level) => {
      const color = getColor(catKey, index, level);
      const rgbColor = helper.hexToRGB(color, '0.1');
      return rgbColor;
    };

    const getColor = (catKey, index, level) => {
      return helper.getColorByCategory(level, catKey, index);
    };

    const getIsActiveCategory = (level, cat) => {
      if (categoryLevelMap.value[level] && categoryLevelMap.value[level][cat]) {
        const category = categoryLevelMap.value[level][cat];
        return category && category.visible;
      }
      return false;
    };

    return {
      isDirty,
      localVisible,
      buttonSize,
      categoryData,
      onCategoryChange,
      applySingle,
      applyWhole,
      close,
      togglePopover,
      widthPopover,
      removeCategory,
      editable,
      getColorRgb,
      getColor,
      tempCat,
      getIsActiveCategory,
    };
  },
};
</script>

<style lang="scss" scoped>
.action-button-modal {
  display: flex;
  // justify-content: flex-end;
  justify-content: space-between;
  align-items: center;
  .apply-all-button {
    // width: 117px;
    height: 44px;
    color: #9299b8;
    border: 1px solid #d9d9d9;
  }
  .dirty-custom {
    color: #9299b8;
    border: 1px solid #d9d9d9;
  }
  .apply-button {
    width: 79px;
    height: 44px;
    // color: #ffffff;
    // background: #20c997;
  }
  .apply-button-dirty {
    color: #ffffff;
    background: #20c997;
  }
  .cancel-button {
    background: #f8f9fb;
    color: #5a5f7d;
    margin-right: 8px;
    border: 1px solid #f1f2f6;
  }
}

.ant-modal-body {
  padding: 30px !important;
}

.category-action {
  display: flex;
  justify-content: space-between;
  font-size: 12px;
  font-weight: 500;
  color: #9299b8;
  border-top: 1px solid #f1f2f6;
  padding-top: 15px;
  margin-top: 10px;
}

.custom-button-link {
  align-self: center;
  font-weight: 500;
  font-size: 12px;
  color: #9299b8;
}

.category-bar {
  // display: flex;
  // flex-wrap: wrap;
  // padding: 20px;
  padding: 0px 6px 26px 6px;
  .category-item {
    margin-bottom: 24px;
  }
  .category-name {
    margin-bottom: 8px;
    font-weight: 500;
    font-size: 13px;
    line-height: 17px;
    text-transform: capitalize;
  }

  .category-tag {
    border: 1px solid #e3e6ef;
    border-radius: 4px;
    padding: 6px 8px;
    margin-right: 4px;
    margin-bottom: 8px;
    display: flex;
    align-items: center;
    overflow-x: auto;
    cursor: text;
    .category-name {
      font-size: 13px;
      line-height: 20px;
      margin-right: 4px;
    }
    .inner-tag {
      &.inactive-category {
        border-style: dashed;
        border-color: #e3e6ef !important;
        background: rgba(248, 249, 251, 0.8) !important;
        color: rgba(173, 180, 210, 1) !important;
        border-width: 1px;
      }
      font-size: 11px;
      line-height: 20px;
      margin-right: 4px;
      padding: 3px 6px;
      border-radius: 4px;
      white-space: nowrap;
      display: flex;
      &.level-0 {
        color: rgba(95, 99, 242, 1);
        background: rgba(95, 99, 242, 0.15);
      }
      &.level-1 {
        color: rgba(91, 202, 244, 1);
        background: rgba(91, 202, 244, 0.15);
      }
      &.level-2 {
        color: rgba(228, 88, 124, 1);
        background: rgba(228, 88, 124, 0.15);
      }
      &.level-3 {
        color: rgba(77, 212, 172, 1);
        background: rgba(77, 212, 172, 0.15);
      }
      &.level-4 {
        color: rgba(255, 189, 89, 1);
        background: rgba(255, 189, 89, 0.15);
      }
      .close-icon {
        color: #5a5f7d;
        display: flex;
        align-items: center;
        cursor: pointer;
      }
    }
  }
}
</style>
