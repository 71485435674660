<template>
  <div class="category-bar">
    <template v-if="data && data.analytic">
      <span
        v-for="(category, level) in categoryData"
        :key="category.key"
        class="category-tag"
        :class="{ 'cursor-pointer': !isReadOnly }"
      >
        <EditCategoryBar
          :msg-id="msgId"
          :category-data="category"
          :editable="editable"
        >
          <div class="outer-tag">
            <a-tooltip>
              <template #title>
                <span>{{ category.name }}</span>
              </template>
              <span class="category-name"> {{ category.name }}: </span>
            </a-tooltip>

            <span
              v-for="(c, idx) in category.data"
              :key="c"
              class="inner-tag"
              :style="{
                background: getColorRgb(c, idx, level),
                color: getColor(c, idx, level),
              }"
              :class="{ 'inactive-category': !getIsActiveCategory(level, c) }"
            >
              {{ c }}
            </span>
          </div>
        </EditCategoryBar>
      </span>
    </template>
  </div>
</template>

<script>
import { computed, toRefs, inject } from 'vue';
import { useStore } from 'vuex';
import EditCategoryBar from './EditCategoryBar.vue';
import helper from '@/services/helper';
import { UserRole } from '@/enum/UserRoleEnum';

export default {
  components: {
    EditCategoryBar,
  },
  props: {
    msgId: {
      type: String,
      default: '',
    },
    data: {
      type: Object,
      default: () => {},
    },
    editable: Boolean,
  },
  setup(props) {
    const store = useStore();
    const { data } = toRefs(props);
    const subCategoryName = inject('subCategoryName');
    const categoryConfig = computed(() => store.state.config.categoryData);
    const catKey = computed(() => store.getters['config/categoryLevelKey']);
    const categoryLevelMap = computed(() =>
      categoryConfig.value.reduce((catLevelMap, catLevel) => {
        catLevelMap[catLevel.level] = catLevel.categories.reduce(
          (levelMap, cat) => {
            levelMap[cat.category] = cat;
            return levelMap;
          },
          {}
        );
        return catLevelMap;
      }, {})
    );

    const categoryData = computed(() => {
      let result = [];
      if (data.value && data.value.analytic && subCategoryName.value) {
        for (let key in catKey.value) {
          const k = catKey.value[key];
          const dataByKey = data.value.analytic[k] || [];
          if (dataByKey && dataByKey.length > 0) {
            const name = subCategoryName.value[k];
            result.push({
              name,
              key: k,
              data: dataByKey,
            });
          }
        }
      }
      return result;
    });

    const isReadOnly = computed(() => {
      const role = store.getters['account/userRole'];
      return [UserRole.USER, UserRole.VIEWER].includes(role);
    });

    const getColorRgb = (catKey, index, level) => {
      const color = getColor(catKey, index, level);
      const rgbColor = helper.hexToRGB(color, '0.1');
      return rgbColor;
    };

    const getColor = (catKey, index, level) => {
      return helper.getColorByCategory(level, catKey, index);
    };

    const getIsActiveCategory = (level, cat) => {
      if (categoryLevelMap.value[level] && categoryLevelMap.value[level][cat]) {
        const category = categoryLevelMap.value[level][cat];
        return category && category.visible;
      }
      return false;
    };

    return {
      categoryData,
      getColorRgb,
      getColor,
      getIsActiveCategory,
      isReadOnly,
    };
  },
};
</script>

<style lang="scss" scoped>
.category-bar {
  display: flex;
  flex-wrap: wrap;
  row-gap: 8px;
  padding: 20px;
  .category-tag {
    border: 1px solid #e3e6ef;
    border-radius: 4px;
    padding: 6px 8px;
    margin-right: 4px;
    display: flex;
    align-items: center;
    word-wrap: normal;
    .outer-tag {
      display: flex;
      flex-wrap: wrap;
      align-items: center;
      row-gap: 4px;
    }
    .category-name {
      font-size: 13px;
      line-height: 20px;
      margin-right: 4px;
      text-transform: capitalize;
      color: #5a5f7d;
      max-width: 100px;
      overflow: hidden;
      text-overflow: ellipsis;
    }
    .inner-tag {
      &.inactive-category {
        border-style: dashed;
        border-color: #e3e6ef !important;
        background: rgba(248, 249, 251, 0.8) !important;
        color: rgba(173, 180, 210, 1) !important;
        border-width: 1px;
      }

      font-size: 10px;
      line-height: 14px;
      margin-right: 4px;
      padding: 3px 6px;
      border-radius: 4px;
      text-transform: capitalize;
      white-space: nowrap;
      &:last-child {
        margin-right: 0;
      }
      &.level-0 {
        color: rgba(95, 99, 242, 1);
        background: rgba(95, 99, 242, 0.15);
      }
      &.level-1 {
        color: rgba(91, 202, 244, 1);
        background: rgba(91, 202, 244, 0.15);
      }
      &.level-2 {
        color: rgba(228, 88, 124, 1);
        background: rgba(228, 88, 124, 0.15);
      }
      &.level-3 {
        color: rgba(77, 212, 172, 1);
        background: rgba(77, 212, 172, 0.15);
      }
      &.level-4 {
        color: rgba(255, 189, 89, 1);
        background: rgba(255, 189, 89, 0.15);
      }
    }
  }
}
</style>
