<template>
  <div>
    <a-dropdown
      :trigger="['click']"
      :get-popup-container="(trigger) => trigger.parentNode"
      @click.stop=""
    >
      <template #overlay>
        <a-menu>
          <a-menu-item
            key="1"
            @click.stop="openLink"
          >
            <FeatherIcon
              type="external-link"
              size="14"
            />
            <span style="margin-left: 15px">Open Link</span>
          </a-menu-item>
          <a-menu-item
            key="2"
            @click.stop="
              helper.copyUrl(data.raw_data.redirect_url || data.raw_data.link)
            "
          >
            <FeatherIcon
              type="copy"
              size="14"
            />
            <span style="margin-left: 15px">Copy Link</span>
          </a-menu-item>
          <!-- <li class="ant-dropdown-menu-item"></li> -->
          <a-menu-item
            v-if="editable"
            key="3"
            @click.stop="toggleEditModal(true)"
          >
            <FeatherIcon
              type="edit-2"
              size="14"
            />
            <span style="margin-left: 15px">Edit Category</span>
          </a-menu-item>
          <a-menu-item
            v-if="editable"
            key="4"
            @click.stop="toggleRejectModal(true)"
          >
            <FeatherIcon
              type="slash"
              size="14"
            />
            <span style="margin-left: 15px">Reject</span>
          </a-menu-item>
        </a-menu>
      </template>
      <slot></slot>
    </a-dropdown>
    <RejectMessage
      :visible="rejectVisible"
      :data="data"
      @close="onCloseReject"
    />
    <EditCategoryModal
      :visible="editVisible"
      :data="data"
      :msg-id="msgId"
      @close="toggleEditModal(false)"
    />
  </div>
</template>

<script>
import { toRefs, ref, inject } from 'vue';
import { notification } from 'ant-design-vue';

import RejectMessage from './MessageMenu/RejectMessage.vue';
import EditCategoryModal from './MessageMenu/EditCategoryModal.vue';
import helper from '@/services/helper';

export default {
  name: 'MessageMoreMenu',
  components: {
    RejectMessage,
    EditCategoryModal,
  },
  props: {
    msgId: {
      type: String,
      default: '',
    },
    data: {
      type: Object,
      default: () => {},
    },
    editable: Boolean,
    subCategoryName: {
      type: Object,
      default: () => {},
    },
    parentName: {
      type: String,
      default: '',
    },
  },
  setup(props) {
    const { msgId } = toRefs(props);
    const onEditMessage = inject('onEditMessage');

    const rejectVisible = ref(false);
    const toggleRejectModal = (state) => {
      rejectVisible.value = state;
    };

    const onCloseReject = (result) => {
      if (result) {
        onEditMessage({
          messageId: msgId.value,
          removed: true,
        });
      }
      toggleRejectModal(false);
    };

    const openLink = () => {
      if (
        props.data &&
        props.data.raw_data &&
        (props.data.raw_data.redirect_url || props.data.raw_data.link)
      ) {
        helper.openUrl(
          props.data.raw_data.redirect_url || props.data.raw_data.link,
        );
      } else {
        notification.error({
          message: 'Error',
          description: 'Invalid url given, cannot open this external link.',
        });
      }
    };

    const editVisible = ref(false);
    const toggleEditModal = (state) => {
      editVisible.value = state;
    };

    return {
      editVisible,
      rejectVisible,
      onCloseReject,
      openLink,
      toggleEditModal,
      toggleRejectModal,
      helper,
    };
  },
};
</script>

<style lang="scss" scoped>
.ps {
  max-height: 150px !important;
}
</style>
