<template>
  <a-card :title="'Active Time'">
    <!-- <NoDataAspect v-if="!mode" /> -->
    <!-- <NoDataFound v-else-if="!loading && selectedData && selectedData.length === 0" /> -->
    <!-- <div v-else-if="!loading" v-resize="onResize" style="height: 420px">
      <Heatmap
        :x-axis-categories="xAxisCategories"
        :y-axis-categories="yAxisCategories"
        :data="selectedData"
        :data-lable="dataLabel"
        :tooltip-formatter-function="tooltipFormatterFunction"
        :description-formatter-function="descriptionFormatterFunction"
      ></Heatmap>
    </div> -->
    <div
      v-if="loading"
      class="load-wrapper"
    >
      <a-skeleton
        :loading="loading"
        active
      />
    </div>

    <div
      v-else-if="!loading && selectedData && selectedData.length > 0"
      style="height: 420px"
    >
      <Heatmap
        :mode="mode"
        :x-axis-categories="xAxisCategories"
        :y-axis-categories="yAxisCategories"
        :data="selectedData"
        :data-lable="dataLabel"
        :tooltip-formatter-function="tooltipFormatterFunction"
        :description-formatter-function="descriptionFormatterFunction"
      ></Heatmap>
    </div>

    <NoDataFound
      v-else-if="!loading && selectedData && selectedData.length === 0"
    />
  </a-card>
</template>

<script>
import NoDataAspect from '@/components/Error/NoDataAspect.vue';
import NoDataFound from '@/components/Error/NoDataFound.vue';
import Heatmap from '@/components/Chart/Heatmap.vue';
import numeral from 'numeral';
import api from '@/services/api';
import { ref, reactive, watchEffect, computed, toRefs } from 'vue';

const ActiveTime = {
  name: 'ActiveTime',
  components: {
    NoDataAspect,
    NoDataFound,
    Heatmap,
  },
  props: {
    mode: {
      type: String,
      default: 'engagement',
    },
    filter: Object,
  },
  setup(props) {
    let { mode, filter } = toRefs(props);
    let loading = ref(true);
    let error = ref(false);
    let engagementData = ref([]);
    let countData = ref([]);
    let viewData = ref([]);

    const selectedData = computed(() => {
      if (mode.value) {
        if (mode.value.toLowerCase() === 'engagement') {
          return engagementData.value;
        } else if (mode.value.toLowerCase() === 'count') {
          return countData.value;
        } else if (mode.value.toLowerCase() === 'view_count') {
          return viewData.value;
        } else {
          return engagementData.value;
        }
      } else {
        // console.log('heatmap', engagementData.value);
        return engagementData.value;
      }
    });
    const dataLabel = computed(() => {
      if (mode.value) {
        if (mode.value.toLowerCase() === 'engagement') {
          return 'Total Engagement';
        } else if (mode.value.toLowerCase() === 'count') {
          return 'Total Mention';
        } else if (mode.value.toLowerCase() === 'view_count') {
          return 'Total View Count';
        } else {
          return 'Total Engagement';
        }
      } else {
        return 'Total Engagement';
      }
    });
    const xAxisCategories = [
      '12am',
      '1am',
      '2am',
      '3am',
      '4am',
      '5am',
      '6am',
      '7am',
      '8am',
      '9am',
      '10am',
      '11am',
      '12pm',
      '1pm',
      '2pm',
      '3pm',
      '4pm',
      '5pm',
      '6pm',
      '7pm',
      '8pm',
      '9pm',
      '10pm',
      '11pm',
    ];
    const yAxisCategories = [
      'Sunday',
      'Monday',
      'Tuesday',
      'Wednesday',
      'Thursday',
      'Friday',
      'Saturday',
    ];
    const getPointCategoryName = function (point, dimension) {
      var series = point.series,
        isY = dimension === 'y',
        axis = series[isY ? 'yAxis' : 'xAxis'];
      return axis.categories[point[isY ? 'y' : 'x']];
    };
    const tooltipFormatterFunction = function () {
      let value = 'Unknown';
      if (this.point != null && this.point.value != null) {
        value = numeral(this.point.value).format('0,0');
      }
      return (
        '<b>' +
        dataLabel.value +
        '</b> ' +
        value +
        '<br>on ' +
        getPointCategoryName(this.point, 'y') +
        '<br>at ' +
        getPointCategoryName(this.point, 'x')
      );
    };
    const isAllZero = function (arr) {
      let allZero = true;
      for (let engageObj of arr) {
        if (engageObj[2] > 0) {
          allZero = false;
          break;
        }
      }
      return allZero;
    };
    const descriptionFormatterFunction = function () {
      let value = 'Unknown';
      if (this.point != null && this.point.value != null) {
        value = numeral(this.point.value).format('0,0');
      }
      return (
        value +
        ' ' +
        dataLabel.value +
        ' on ' +
        getPointCategoryName(this.point, 'y') +
        ' at ' +
        getPointCategoryName(this.point, 'x')
      );
    };
    const loadData = async (apiFilter) => {
      const qs = reactive({});
      const result = await api.getActiveTimeData(apiFilter, qs).catch(() => {
        console.error('Heatmap api call error', result);
        error.value = true;
      });
      // console.log('result', result.message);
      if (
        result.message != null &&
        result.message.countData != null &&
        result.message.engagementData != null &&
        result.message.viewData != null
      ) {
        if (isAllZero(result.message.engagementData)) {
          engagementData.value = [];
        } else {
          engagementData.value = result.message.engagementData;
        }
        if (isAllZero(result.message.countData)) {
          countData.value = [];
        } else {
          countData.value = result.message.countData;
        }
        if (isAllZero(result.message.viewData)) {
          viewData.value = [];
        } else {
          viewData.value = result.message.viewData;
        }
      }
      loading.value = false;
    };
    watchEffect(() => {
      loading.value = true;
      error.value = false;
      loadData(filter.value);
    });

    return {
      loading,
      selectedData,
      dataLabel,
      xAxisCategories,
      yAxisCategories,
      tooltipFormatterFunction,
      descriptionFormatterFunction,
    };
  },
};

export default ActiveTime;
</script>

<style lang="scss" scoped>
.load-wrapper {
  height: 450px;
  display: flex;
  flex-direction: column;
  justify-content: center;
}
</style>
