<template>
  <a-layout class="blank-layout">
    <slot></slot>
  </a-layout>
</template>

<style lang="css" scoped>
.blank-layout {
  display: block;
}
</style>
