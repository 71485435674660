<template>
  <a-card
    class="channel-overview no-body-padding"
    title="Channel Performance"
  >
    <div
      v-if="loading"
      class="loading-wrapper"
    >
      <a-skeleton
        :loading="loading"
        active
      />
    </div>
    <div v-else-if="!loading && noData">
      <NoDataFound></NoDataFound>
    </div>
    <SourceSelection
      v-else-if="!loading && isMobileScreen"
      :sourceSelected="source"
      @onSourceSelect="onSourceSelect"
    ></SourceSelection>
    <!-- TABLE SOV -->
    <div
      v-else-if="!loading && channelData && channelData.length"
      class="channel-table-wrapper"
    >
      <div class="channel-table">
        <a-table
          :data-source="channelData"
          :columns="isMobileScreen ? channelMobileColumns : channelColumns"
          :pagination="false"
          :scroll="{ x: !isMobileScreen ? 'calc(700px + 50%)' : 0, y: 0 }"
          class="table-campaign-channel"
        >
          <template #customTypeTitle="">
            <span class="category-title">Share of Voice (SOV)</span>
          </template>
          <template #type="{ text }">
            <div
              v-if="mode === 'engagement'"
              class="badge-wrapper"
            >
              <FeatherIcon
                v-if="text === 'reaction'"
                :type="'thumbs-up'"
                size="12"
              />
              <FeatherIcon
                v-if="text === 'comment'"
                :type="'message-square'"
                size="12"
              />
              <FeatherIcon
                v-if="text === 'share'"
                :type="'share-2'"
                size="12"
              />
              <FeatherIcon
                v-if="text === 'engagementPerMention'"
                :type="'at-sign'"
                size="12"
              />
              <span
                v-if="text === 'engagement'"
                class="category-text"
                >Total Engagement</span
              >
              <span
                v-else-if="text === 'reaction'"
                class="category-text"
                >Reactions</span
              >
              <span
                v-else-if="text === 'comment'"
                class="category-text"
              >
                Comment
              </span>
              <span
                v-else-if="text === 'share'"
                class="category-text"
              >
                Share
              </span>
              <span
                v-else-if="text === 'engagementPerMention'"
                class="category-text"
              >
                Engagement<wbr />/<wbr />Mentioned
              </span>
              <span
                v-else
                class="category-text"
              >
                {{ text }}
              </span>
            </div>
            <div
              v-else-if="mode === 'mention'"
              class="badge-wrapper"
            >
              <FeatherIcon
                v-if="text === 'post'"
                :type="'file-text'"
                size="12"
              />
              <FeatherIcon
                v-if="text === 'nonOriginMention'"
                :type="'message-square'"
                size="12"
              />
              <FeatherIcon
                v-if="text === 'frequency'"
                :type="'at-sign'"
                size="12"
              />
              <span
                v-if="text === 'mention'"
                class="category-text"
                >Total Mentioned</span
              >
              <span
                v-else-if="text === 'post'"
                class="category-text"
                >Post</span
              >
              <span
                v-else-if="text === 'nonOriginMention'"
                class="category-text"
                >Comment<wbr />/<wbr />Reply</span
              >
              <span
                v-else-if="text === 'frequency'"
                class="category-text"
              >
                Frequency <wbr />(mentions/day)
              </span>
              <span
                v-else
                class="category-text"
              >
                {{ text }}
              </span>
            </div>
            <div
              v-else
              class="badge-wrapper"
            >
              <FeatherIcon
                v-if="text === 'videoView'"
                :type="'video'"
                size="12"
              />
              <FeatherIcon
                v-if="text === 'pageView'"
                :type="'bar-chart'"
                size="12"
              />
              <span
                v-if="text === 'view'"
                class="category-text"
                >Total View</span
              >
              <span
                v-else-if="text === 'videoView'"
                class="category-text"
                >Video View</span
              >
              <span
                v-else-if="text === 'pageView'"
                class="category-text"
                >Page View</span
              >
              <span
                v-else
                class="category-text"
              >
                {{ text }}
              </span>
            </div>
          </template>

          <template #customFacebookTitle="">
            <img
              class="source-img"
              :src="require('@/assets/images/source-icon/svg/facebook.svg')"
              alt="logo"
            />
          </template>

          <template #customTwitterTitle="">
            <img
              class="source-img"
              :src="require('@/assets/images/source-icon/svg/twitter.svg')"
              alt="logo"
            />
          </template>
          <template #customInstagramTitle="">
            <img
              class="source-img"
              :src="require('@/assets/images/source-icon/svg/instagram.svg')"
              alt="logo"
            />
          </template>
          <template #customYoutubeTitle="">
            <img
              class="source-img youtube-img"
              :src="
                require('@/assets/images/source-icon/svg/Youtube-Large.svg')
              "
              alt="logo"
            />
          </template>
          <template #customPantipTitle="">
            <img
              class="source-img"
              :src="require('@/assets/images/source-icon/svg/pantip.svg')"
              alt="logo"
            />
          </template>
          <template #customBlockditTitle="">
            <img
              class="source-img"
              :src="require('@/assets/images/source-icon/svg/blockdit.svg')"
              alt="logo"
            />
          </template>
          <template #customTiktokTitle="">
            <img
              class="source-img"
              :src="require('@/assets/images/source-icon/svg/tiktok.svg')"
              alt="logo"
            />
          </template>
          <template #customWebsiteTitle="">
            <img
              class="source-img"
              :src="require('@/assets/images/source-icon/svg/website.svg')"
              alt="logo"
            />
          </template>
        </a-table>
      </div>
    </div>
  </a-card>
</template>

<script>
import { toRefs, watch, ref, reactive, computed, watchEffect } from 'vue';
import { useStore } from 'vuex';
import _ from 'lodash';

import api from '@/services/api';
import helper from '@/services/helper';

import SourceSelection from '@/components/Selections/SourceSelection.vue';
import { generateChannelColumns } from '@/services/PerfomanceChannel';
import NoDataFound from '@/components/Error/NoDataFound.vue';

export default {
  name: 'PerformanceChannel',

  components: {
    SourceSelection,
    NoDataFound,
  },

  props: {
    mode: String,
    filterResult: Object,
  },

  setup(props) {
    const isMobileScreen = computed(() => store.state.isMobileScreen);
    let loading = ref(false);

    const allSrcList = api.getAllSource();
    const srcOpt = allSrcList.map((src) => {
      return {
        label: _.capitalize(src),
        value: src,
      };
    });
    const sourceOptions = ref(srcOpt);
    const selectedSource = ref([...allSrcList]);
    let source = ref(selectedSource.value[0]);
    const onSourceSelect = (e) => {
      source.value = e;
      initMobile();
    };

    const store = useStore();
    const brand = computed(() => store.state.account.brand);

    const { filterResult, mode } = toRefs(props);

    const channelDataAll = ref([]);
    const channelData = ref([]);
    const channelDataByMode = reactive({
      engagement: [],
      mention: [],
      view: [],
    });
    // TABLE SOV
    const channelColumns = generateChannelColumns();

    let channelMobileColumns = ref([]);

    const getColor = (catKey, index) => {
      return helper.getColorByCategory(filterResult.value.level, catKey, index);
    };

    const convertValue = (value) => {
      if (value) {
        return helper.numeral(value);
      }
      return 0;
    };

    const noData = computed(() => {
      for (let item of channelData.value) {
        for (let key in item) {
          if (key !== 'type' && item[key].value !== 0) {
            return false;
          }
        }
      }
      return true;
    });

    const initMobile = () => {
      const countList = [];
      const engageList = [];
      const viewList = [];
      channelMobileColumns.value = [];
      channelMobileColumns.value.push(channelColumns[0]);
      for (let column of channelColumns) {
        if (column.key === source.value) {
          channelMobileColumns.value.push(column);
        }
      }
      let obj;
      for (let key of channelDataAll.value) {
        if (
          key.type === 'engagement' ||
          key.type === 'mention' ||
          key.type === 'view'
        ) {
          if (key.type === 'engagement') {
            obj = {
              type: key.type,
              [source.value]: key[source.value],
            };
            engageList.push(obj);
          } else if (key.type === 'mention') {
            obj = {
              type: 'mentioned',
              [source.value]: key[source.value],
            };
            countList.push(obj);
          } else if (key.type === 'view') {
            obj = {
              type: key.type,
              [source.value]: key[source.value],
            };
            viewList.push(obj);
          }
        }
      }
      for (let key of channelDataAll.value) {
        const obj = {
          type: key.type,
          [source.value]: key[source.value],
        };
        const type = key.type;
        if (type === 'comment') {
          engageList.push(obj);
        } else if (
          type === 'reaction' ||
          type === 'share' ||
          type === 'engagementPerMention'
        ) {
          engageList.push(obj);
        } else if (type === 'post' || type === 'frequency') {
          countList.push(obj);
        } else if (type === 'videoView' || type === 'pageView') {
          viewList.push(obj);
        } else if (type === 'nonOriginMention') {
          countList.push(obj);
        }
      }
      channelDataByMode.engagement = engageList;
      channelDataByMode.mention = countList;
      channelDataByMode.view = viewList;

      channelData.value = channelDataByMode[mode.value];
    };

    const initDesktop = () => {
      const countList = [];
      const engageList = [];
      const viewList = [];
      let totalEngagement = {};
      let totalMention = {};
      let totalView = {};
      for (let key of channelDataAll.value) {
        if (
          key.type === 'engagement' ||
          key.type === 'mention' ||
          key.type === 'view'
        ) {
          switch (key.type) {
            case 'engagement':
              totalEngagement = {
                type: key.type,
                facebook: key.facebook,
                twitter: key.twitter,
                instagram: key.instagram,
                youtube: key.youtube,
                pantip: key.pantip,
                blockdit: key.blockdit,
                tiktok: key.tiktok,
                website: key.website,
              };
              break;
            case 'mention':
              totalMention = {
                type: key.type,
                facebook: key.facebook,
                twitter: key.twitter,
                instagram: key.instagram,
                youtube: key.youtube,
                pantip: key.pantip,
                blockdit: key.blockdit,
                tiktok: key.tiktok,
                website: key.website,
              };
              break;
            case 'view':
              totalView = {
                type: key.type,
                facebook: key.facebook,
                twitter: key.twitter,
                instagram: key.instagram,
                youtube: key.youtube,
                pantip: key.pantip,
                blockdit: key.blockdit,
                tiktok: key.tiktok,
                website: key.website,
              };
              break;
          }
        }
      }
      if (totalEngagement) {
        engageList.push(totalEngagement);
      }
      if (totalMention) {
        countList.push(totalMention);
      }
      if (totalView) {
        viewList.push(totalView);
      }
      for (let key of channelDataAll.value) {
        const obj = {
          type: key.type,
          facebook: key.facebook,
          twitter: key.twitter,
          instagram: key.instagram,
          youtube: key.youtube,
          pantip: key.pantip,
          blockdit: key.blockdit,
          tiktok: key.tiktok,
          website: key.website,
        };
        const type = key.type;
        if (type === 'comment') {
          engageList.push(obj);
        } else if (
          type === 'reaction' ||
          type === 'share' ||
          type === 'engagementPerMention'
        ) {
          engageList.push(obj);
        } else if (type === 'post' || type === 'frequency') {
          countList.push(obj);
        } else if (type === 'videoView' || type === 'pageView') {
          viewList.push(obj);
        } else if (type === 'nonOriginMention') {
          countList.push(obj);
        }
      }
      channelDataByMode.engagement = engageList;
      channelDataByMode.mention = countList;
      channelDataByMode.view = viewList;

      channelData.value = channelDataByMode[mode.value];
    };

    const getSOV = async (fv) => {
      if (fv) {
        const result = await api.getCampaignPerformanceChannelSOV(
          fv,
          brand.value
        );
        if (result.message.data) {
          channelDataAll.value = result.message.data;
          if (isMobileScreen.value) {
            const sources = selectedSource.value[0];
            initMobile(sources);
          } else {
            initDesktop();
          }
        }
      }
    };

    const init = async (fv) => {
      loading.value = true;
      await getSOV(fv);
      loading.value = false;
    };

    watchEffect(() => {
      init(filterResult.value);
    });

    watch(
      () => filterResult.value,
      () => {
        const srcList = filterResult.value.source;
        // console.log('WTF', filterResult.value.level);

        if (srcList) {
          if (srcList.length > 0) {
            sourceOptions.value = srcList.map((src) => {
              return {
                label: _.capitalize(src),
                value: src,
              };
            });
            selectedSource.value = [...srcList];
          } else if (srcList.length === 0) {
            selectedSource.value = [...allSrcList];
          }
        }
      }
    );

    watch(
      () => mode.value,
      () => {
        channelData.value = channelDataByMode[mode.value];
      }
    );

    watch(
      () => isMobileScreen.value,
      () => {
        if (!isMobileScreen.value) {
          selectedSource.value = [...allSrcList];
          initDesktop();
        } else {
          source.value = selectedSource.value[0];
          initMobile();
        }
      }
    );

    return {
      sourceOptions,
      selectedSource,
      onSourceSelect,
      isMobileScreen,
      channelData,
      getColor,
      convertValue,
      channelColumns,
      channelDataByMode,
      channelMobileColumns,
      channelDataAll,
      loading,
      source,
      noData,
    };
  },
};
</script>

<style lang="scss">
.channel-overview {
  .ant-card-body {
    padding: 24px !important;
  }
  .loading-wrapper {
    min-height: 400px;
    display: flex;
    align-items: center;
    padding: 20px;
  }

  .chart-wrapper {
    width: 100%;
    min-height: 400px;

    .chart {
      height: 400px;
    }
  }

  .source-selector {
    padding: 24px;
  }

  .source-selector-mobile {
    .source-select-title {
      font-weight: 500;
      line-height: 24px;
      color: #272b41;
      margin-bottom: 5px;
    }

    .source-select {
      .ant-select-selector {
        border: 1px solid #e3e6ef;
        border-radius: 4px;
        min-height: 42px !important;

        .ant-select-selection-search input {
          height: 42px;
        }

        .ant-select-selection-placeholder {
          line-height: 40px !important;
        }
      }

      .source-item {
        display: flex;
        align-items: center;

        .source-img {
          height: 16px;
          width: 16px;
        }
      }
    }
  }

  .no-data-wrapper {
    min-height: 400px;
    padding: 20px;
  }

  .ant-card-head .ant-card-head-wrapper {
    flex-flow: row;
    align-items: center;
  }

  .expand-sov-table {
    display: flex;
    justify-content: flex-end;
    padding: 24px 24px 0;

    .expand-text {
      color: #097cff;
      font-size: 12px;
    }
  }

  // TABLE CSS
  .channel-table-wrapper {
    padding: 0;

    .channel-table {
      .youtube-img {
        width: 30px;
        height: 30px;
      }

      table tr th:first-child {
        width: 160px;
      }
      .table-campaign-channel {
        border: 1px solid #f1f2f6;
        border-bottom: none;
      }
      .badge-wrapper {
        min-width: 100px !important;
        i {
          margin-right: 8px;
        }

        .category-text {
          padding-left: 0 !important;
        }
      }

      @media screen and (max-width: 700px) {
        .badge-wrapper {
          min-width: 50px;
        }
      }

      .source-value {
        &.highlight-color {
          background: #fff;
          // background: #def7ef;
        }
      }

      .category-data {
        color: #5a5f7d;
      }
    }
  }

  .source-selector-mobile {
    margin-bottom: 20px;

    .source-select-title {
      font-weight: 500;
      line-height: 24px;
      color: #272b41;
      margin-bottom: 5px;
    }

    .source-select {
      .ant-select-selector {
        border: 1px solid #e3e6ef;
        border-radius: 4px;
        min-height: 42px !important;

        .ant-select-selection-search input {
          height: 42px;
        }

        .ant-select-selection-placeholder {
          line-height: 40px !important;
        }
      }

      .source-item {
        display: flex;
        align-items: center;

        .source-img {
          height: 16px;
          width: 16px;
        }
      }
    }

    .ant-select-selection-item {
      font-size: 14px;
      line-height: 22px;
      font-weight: 500;
      text-transform: capitalize;
    }

    .fa-stack {
      height: 13px;
      line-height: 13px;
      position: relative;
      vertical-align: middle;
      width: 13px;
      font-size: 13px;
      margin-right: 8px;

      .fa-circle {
        left: 0;
        position: absolute;
        text-align: center;
        width: 100%;
        font-size: 13px;
      }

      .icon-social {
        left: 0;
        position: absolute;
        text-align: center;
        width: 100%;
        font-size: 8px;
        color: #fff;
        padding: 3px 0;
      }

      .icon-twitter {
        color: #1da1f2;
      }

      .icon-instagram {
        color: #dd2a7b;
      }

      .icon-youtube {
        color: #e32212;
      }

      .icon-pantip {
        color: #7459c8;
      }

      .icon-website {
        color: #252525;
      }
    }

    .icon-facebook {
      color: #1977f2;
      margin-right: 8px;
    }

    .source-img {
      height: 13px;
      width: 13px;
      margin-right: 8px;
    }
  }
}

@media screen and (max-width: 767px) {
  .channel-overview {
    .channel-table-wrapper {
      padding: 0 !important;
    }

    .social-select-list {
      display: block !important;
    }
  }
}
</style>
