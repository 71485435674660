<template>
  <div class="no-result">
    <img
      class="img-logo"
      src="../../assets/images/nodata/file.svg"
      alt="no result"
    />
    <div class="no-data-title">No results found.</div>
    <div class="no-data-subtitle">
      Try adjusting your search or filter to find what you're looking for.
    </div>
  </div>
</template>

<script>
export default {
  name: 'NoResult',
  setup() {
    return {};
  },
};
</script>

<style lang="scss" scoped>
.no-result {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  min-height: 300px;
  .img-logo {
    width: 175px;
    height: auto;
    margin-bottom: 16px;
  }
  .no-data-title {
    line-height: 22px;
    font-size: 18px;
    color: #666d92;
    font-weight: 500;
  }

  .no-data-subtitle {
    margin-top: 10px;
    font-weight: 500;
    font-size: 14px;
    line-height: 22px;
    text-align: center;
    color: #666d92;
  }
}
</style>
