<template>
  <div v-if="loading">Loading ...</div>
  <div
    v-else
    class="message-item-window"
  >
    <div class="message-head">
      <div class="left-block">
        <div class="profile-img-wrapper">
          <a class="profile-img">
            <img
              v-if="item.user && item.user.photo"
              class="user-img"
              :src="item.user.photo"
            />
          </a>
        </div>
        <div class="left-title">
          <div class="username-row">
            {{ item.user.username }}
          </div>
          <div class="source-row">
            <div
              class="source-tag"
              :class="item.info.source"
            >
              <span class="logo">
                <span
                  v-if="item.info.source === 'facebook'"
                  class="facebook"
                >
                  <!-- eslint-disable-next-line vue/no-v-html -->
                  <span
                    class="fontawesome-wrap"
                    v-html="facebookIcon"
                  />
                </span>
                <span
                  v-else-if="item.info.source === 'twitter'"
                  class="twitter"
                >
                  <!-- eslint-disable-next-line vue/no-v-html -->
                  <span
                    class="fontawesome-wrap"
                    v-html="twitterIcon"
                  />
                </span>
                <span
                  v-else-if="item.info.source === 'instagram'"
                  class="instagram"
                >
                  <!-- eslint-disable-next-line vue/no-v-html -->
                  <span
                    class="fontawesome-wrap"
                    v-html="instagramIcon"
                  />
                </span>
                <span
                  v-else-if="item.info.source === 'youtube'"
                  class="youtube"
                >
                  <!-- eslint-disable-next-line vue/no-v-html -->
                  <span
                    class="fontawesome-wrap"
                    v-html="youtubeIcon"
                  />
                </span>
                <span
                  v-else-if="item.info.source === 'pantip'"
                  class="pantip"
                >
                  <FeatherIcon
                    type="message-square"
                    size="9"
                  />
                </span>
                <span
                  v-else
                  class="website"
                >
                  <FeatherIcon
                    type="globe"
                    size="8"
                  />
                </span>
              </span>
              {{ item.info.source }}
            </div>
          </div>
          <div
            v-if="dateStr"
            class="date-tag"
          >
            Posted on {{ dateStr }}
          </div>
        </div>
      </div>
      <div class="right-block">
        <div
          v-if="item.analytic"
          class="sentiment-circle"
          :class="sentimentClass"
        >
          <FeatherIcon
            :type="sentimentIcon || 'meh'"
            size="28"
          />
        </div>
      </div>
    </div>
    <div class="content-wrapper">
      <div
        v-if="item.raw_data && item.raw_data.title"
        class="message-title"
      >
        {{ item.raw_data.title }}
      </div>
      <div v-if="item.raw_data && item.raw_data.hastag">
        <span
          v-for="tag in item.raw_data.hashtag"
          :key="tag"
          class="hashtag"
          :color="currentSourceColor"
        >
          {{ tag }}
        </span>
      </div>
      <span>
        {{ textContent }}
      </span>
    </div>
    <div class="engagement-bar">
      <div class="left-block">
        <div class="total-engage">
          <span class="total-engage-title">Total: </span>
          <span class="total-engage-value">
            {{ item.analytic.engagement_score }}
          </span>
        </div>
        <div
          v-if="item && item.summary && item.summary.like_count"
          class="engage-value icon"
          :title="numeral(item.summary.like_count, '0,0', true)"
        >
          <FeatherIcon
            type="thumbs-up"
            size="14"
            class="engage-icon"
          />
          <span>{{ numeral(item.summary.like_count, '0,0.0a', true) }}</span>
        </div>
        <div
          v-if="item && item.summary && item.summary.dislike_count"
          class="engage-value icon"
          :title="numeral(item.summary.like_count, '0,0', true)"
        >
          <FeatherIcon
            type="thumbs-down"
            size="14"
            class="engage-icon"
          />
          <span>{{ numeral(item.summary.dislike_count, '0,0.0a', true) }}</span>
        </div>
        <div
          v-if="item && item.summary && item.summary.comment_count"
          class="engage-value icon"
          :title="numeral(item.summary.comment_count, '0,0', true)"
        >
          <FeatherIcon
            type="message-square"
            size="14"
            class="engage-icon"
          />
          <span>{{ numeral(item.summary.comment_count, '0,0.0a', true) }}</span>
        </div>
        <div
          v-if="item && item.summary && item.summary.share_count"
          class="engage-value icon"
          :title="numeral(item.summary.share_count, '0,0', true)"
        >
          <FeatherIcon
            type="share-2"
            size="14"
            class="engage-icon"
          />
          <span>{{ numeral(item.summary.share_count, '0,0.0a', true) }}</span>
        </div>
        <div
          v-if="item.info.source === 'twitter'"
          class="engage-value icon"
          :title="numeral(item.summary.retweet_count, '0,0', true) || '0'"
        >
          <FeatherIcon
            type="repeat"
            size="14"
            class="engage-icon"
          />
          <span>{{
            numeral(item.summary.retweet_count, '0,0.0a', true) || '0'
          }}</span>
        </div>
      </div>
    </div>
    <div class="category-bar">
      <span
        v-for="(category, level) in categoryData"
        :key="category.key"
        class="category-tag"
      >
        <div class="outer-tag">
          <span class="category-name"> {{ category.name }}: </span>
          <span
            v-for="c in category.data"
            :key="c"
            class="inner-tag"
            :class="`level-${level}`"
          >
            {{ c }}
          </span>
        </div>
      </span>
    </div>
  </div>
</template>

<script>
import { ref, toRefs, onMounted } from 'vue';

import api from '@/services/api';
import helper from '@/services/helper';

import FeatherIcon from '@/components/Icon/FeatherIcon.vue';

import { icon } from '@fortawesome/fontawesome-svg-core';
import {
  faFacebookF,
  faYoutube,
  faTwitter,
  faInstagram,
} from '@fortawesome/free-brands-svg-icons';

export default {
  components: {
    FeatherIcon,
  },
  props: {
    messageId: {
      type: String,
      default: '',
    },
  },
  setup(props) {
    console.log(faFacebookF);

    const { messageId } = toRefs(props);
    const item = ref({});
    const loading = ref(true);
    const dateStr = ref('');
    const iconSrc = ref('');
    const textContent = ref('');
    const sentimentIcon = ref('meh');
    const sentimentClass = ref('neutral-bg');
    const categoryData = ref([]);

    let subCategoryName;
    const fallbackCategory = JSON.parse(localStorage.getItem('category-name'));
    subCategoryName = ref(fallbackCategory);

    onMounted(async () => {
      console.log('Mounted IFW', messageId.value, icon(faTwitter).html);
      // Get feed by ID
      loading.value = true;
      if (messageId.value) {
        const result = await api.getFeedbyID(messageId.value).catch(() => {
          this.error = true;
        });
        if (result && result.message) {
          item.value = result.message;
          // Prepare data
          if (item.value.info) {
            const { created_at, source } = item.value.info;
            dateStr.value = helper.formatDate(created_at, 'D MMM YYYY, H:mm A');
            console.log('SRC', source);
            // Raw data
            const { text } = item.value.raw_data;
            if (text && text.length > 250) {
              textContent.value = helper.truncate(text) + '...';
            } else {
              textContent.value = text;
            }
            // Analytic
            if (item.value.analytic.sentiment_score > 1) {
              sentimentClass.value = 'positive-bg';
              sentimentIcon.value = 'smile';
            } else if (item.value.analytic.sentiment_score < -1) {
              sentimentClass.value = 'negative-bg';
              sentimentIcon.value = 'frown';
            } else {
              sentimentClass.value = 'neutral-bg';
              sentimentIcon.value = 'meh';
            }
            // Cat
            let catResult = [];
            if (item.value && item.value.analytic && subCategoryName.value) {
              const catKey = ['categories'];
              for (let i in subCategoryName.value) {
                catKey.push(`sub_categories_level_${i + 1}`);
              }
              for (let key in catKey) {
                const k = catKey[key];
                const dataByKey = item.value.analytic[k] || [];
                if (dataByKey && dataByKey.length > 0) {
                  const name = subCategoryName.value[k];
                  catResult.push({
                    name,
                    key: k,
                    data: dataByKey,
                  });
                }
              }
            }
            categoryData.value = catResult;
          }
          loading.value = false;
        }
      }
    });

    return {
      item,
      dateStr,
      iconSrc,
      loading,
      textContent,
      twitterIcon: icon(faTwitter).html,
      facebookIcon: icon(faFacebookF).html,
      instagramIcon: icon(faInstagram).html,
      youtubeIcon: icon(faYoutube).html,
      sentimentIcon,
      sentimentClass,
      categoryData,
      numeral: helper.numeral,
    };
  },
  styles: [],
};
</script>

<style>
.message-item-window {
  min-width: 400px;
  padding: 12px 0 12px 12px;
  font-family: 'DM Sans', 'IBM Plex Sans Thai', sans-serif !important;
}
/* Message Head */
.message-head {
  display: flex;
  justify-content: space-between;
}
.message-head .left-block {
  display: flex;
}
.message-head .left-block .profile-img-wrapper {
  margin-right: 15px;
}
.message-head .left-block .profile-img {
  display: inline-block;
  vertical-align: middle;
  width: 40px;
  height: 40px;
  min-width: 40px;
  min-height: 40px;
  border-radius: 40px;
  overflow: hidden;
  background-color: #ddd;
  border: 1px solid #ddd;
}
.message-head .left-block .profile-img .user-img {
  width: 40px;
  height: 40px;
}
.message-head .left-block .username-row {
  font-size: 14px;
  line-height: 18px;
  font-weight: 500;
  margin-bottom: 3px;
  overflow: hidden;
  text-overflow: ellipsis;
}
.message-head .left-block .source-row {
  line-height: 16px;
  display: flex;
  align-items: center;
  margin-bottom: 4px;
}
.message-head .left-block .date-tag {
  font-size: 13px;
  color: #9299b8;
}
.message-head .left-block .source-row .source-tag {
  display: flex;
  align-items: center;
  flex: 0 0 auto;
  border-radius: 18px;
  height: 18px;
  line-height: 15px;
  font-size: 10px;
  font-weight: 500;
  text-transform: capitalize;
  padding: 2px 6px 2px 4px;
  white-space: nowrap;
  background-color: rgba(44, 153, 255, 0.15);
}
.message-head .left-block .source-row .source-tag .logo {
  height: 14px;
  width: 14px;
  border-radius: 24px;
  color: #fff;
  margin-right: 4px;
  font-size: 10px;
  line-height: 15px;
  overflow: hidden;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  background-color: #252525;
}
.message-head .left-block .source-row .source-tag.facebook {
  background-color: rgba(24, 119, 242, 0.15);
}
.message-head .left-block .source-row .source-tag.facebook .logo {
  background-color: #1877f2;
}
.message-head .left-block .source-row .source-tag.twitter {
  background-color: rgba(34, 164, 240, 0.15);
}
.message-head .left-block .source-row .source-tag.twitter .logo {
  background-color: #22a4f0;
}
.message-head .left-block .source-row .source-tag.pantip {
  background-color: rgba(118, 116, 188, 0.15);
}
.message-head .left-block .source-row .source-tag.pantip .logo {
  background-color: #7674bc;
}
.message-head .left-block .source-row .source-tag.youtube {
  background-color: rgba(255, 0, 0, 0.15);
}
.message-head .left-block .source-row .source-tag.youtube .logo {
  background-color: #ff0000;
}
.message-head .left-block .source-row .source-tag.instagram {
  background-color: rgba(228, 64, 95, 0.15);
}
.message-head .left-block .source-row .source-tag.instagram .logo {
  background-color: #e4405f;
}
.message-head .profile-img-wrapper {
  position: relative;
}
.message-head .profile-img-wrapper .count-badge {
  position: absolute;
  font-size: 10px;
  border: 2px solid #fff;
  border-radius: 24px;
  background: #3371ff;
  bottom: 0;
  right: 0;
  line-height: 12px;
  width: 16px;
  height: 16px;
  color: #fff;
  text-align: center;
  font-weight: 500;
}
.right-block {
  display: flex;
}
.right-block .more-menu {
  margin-left: 18px;
  color: #adb4d2;
}
.message-item-head {
  padding: 0 20px;
}
.message-title {
  font-size: 16px;
  line-height: 20px;
  margin-bottom: 12px;
  color: #272b41;
}
.content-wrapper {
  font-size: 15px;
  line-height: 24px;
  margin: 16px 0 30px 0;
  color: #5a5f7d;
}
.content-wrapper .hashtag-wrapper {
  margin-bottom: 18px;
}
.content-wrapper .hashtag {
  margin-right: 4px;
  margin-bottom: 4px;
}
.engage-bar {
  padding: 0 20px;
  margin-bottom: 14px;
}
.engage-bar.no-category {
  margin-bottom: 36px;
}
.category-bar {
  border-top: 1px solid #f1f2f6;
}
.seemore {
  margin-left: 3px;
  color: #333;
  font-weight: 500;
}

/* Sentiment Circle */
.sentiment-circle {
  height: 28px;
  width: 28px;
  border-radius: 48px;
  padding: 10px;
}
.sentiment-circle.positive-bg {
  background-color: rgba(32, 201, 151, 0.15);
  color: #20c997;
}
.sentiment-circle.neutral-bg {
  background-color: #fff5e2;
  color: #febc3c;
}
.sentiment-circle.negative-bg {
  background-color: rgba(255, 77, 79, 0.15);
  color: #ff4d4f;
}
.sentiment-circle.small {
  height: 32px;
  width: 32px;
  border-radius: 32px;
  padding: 8px;
}
/* TOTAL engage */
.engagement-bar {
  font-size: 13px;
  color: #5a5f7d;
  font-weight: 500;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-top: 1px solid #f1f2f6;
  padding: 12px 0;
}
.engagement-bar .total-engage {
  background-color: #f8f9fb;
  white-space: nowrap;
  padding: 6px 15px;
  border-radius: 5px;
  font-size: 14px;
  margin: 8px 15px 8px 0;
}
.engagement-bar .total-engage .total-engage-title {
  margin-right: 3px;
}
.engagement-bar .total-engage .total-engage-value {
  color: #3371ff;
}
.engagement-bar .engage-value {
  color: #9299b8;
  margin-right: 20px;
}
.engagement-bar .engage-value.icon {
  display: flex;
  align-items: center;
}
.engagement-bar .engage-value .engage-icon {
  margin-right: 6px;
}
.engagement-bar .counter-wrapper-inner {
  display: flex;
  width: 100%;
}
.engagement-bar .left-block,
.engagement-bar .right-block {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
}
.engagement-bar.dark .total-engage {
  background-color: #ffffff;
}
/* Cat bar */
.category-bar {
  display: flex;
  flex-wrap: wrap;
  row-gap: 8px;
  padding-top: 20px;
}
.category-bar .category-tag {
  border: 1px solid #e3e6ef;
  border-radius: 4px;
  padding: 6px 8px;
  margin-right: 4px;
  display: flex;
  align-items: center;
}
.category-bar .category-tag .outer-tag {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  row-gap: 4px;
}
.category-bar .category-tag .category-name {
  font-size: 13px;
  line-height: 20px;
  margin-right: 4px;
  text-transform: capitalize;
  color: #5a5f7d;
}
.category-bar .category-tag .inner-tag {
  font-size: 10px;
  line-height: 14px;
  margin-right: 4px;
  padding: 3px 6px;
  border-radius: 4px;
  text-transform: capitalize;
  white-space: nowrap;
}
.category-bar .category-tag .inner-tag:last-child {
  margin-right: 0;
}
.category-bar .category-tag .inner-tag.level-0 {
  color: #5f63f2;
  background: rgba(95, 99, 242, 0.15);
}
.category-bar .category-tag .inner-tag.level-1 {
  color: #5bcaf4;
  background: rgba(91, 202, 244, 0.15);
}
.category-bar .category-tag .inner-tag.level-2 {
  color: #e4587c;
  background: rgba(228, 88, 124, 0.15);
}
.category-bar .category-tag .inner-tag.level-3 {
  color: #4dd4ac;
  background: rgba(77, 212, 172, 0.15);
}
.category-bar .category-tag .inner-tag.level-4 {
  color: #ffbd59;
  background: rgba(255, 189, 89, 0.15);
}
/* Others */
.hashtag {
  box-sizing: border-box;
  margin: 0 8 px 0 0;
  color: #000000d9;
  font-size: 14px;
  font-variant: tabular-nums;
  line-height: 1.5715;
  list-style: none;
  font-feature-settings: tnum;
  display: inline-block;
  height: auto;
  padding: 0 7 px;
  font-size: 12px;
  line-height: 20px;
  white-space: nowrap;
  background: #fafafa;
  border: 1 px solid #d9d9d9;
  border-radius: 2 px;
  opacity: 1;
  transition: all 0.3s;
  color: #096dd9;
  background: #e6f7ff;
  border-color: #91d5ff;
}

.fontawesome-wrap {
  height: 8px;
}
.fontawesome-wrap svg {
  height: 8px;
}
</style>
