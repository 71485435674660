<template>
  <a-layout-sider
    id="filter-sidebar"
    :width="350"
    theme="light"
    collapsed-width="0"
    :trigger="null"
    :collapsed="filterCollapsed"
  >
    <div class="filter-head">
      <div class="head-text">
        <FeatherIcon
          type="filter"
          size="14"
        />
        <span> Filters </span>
      </div>
      <a-button @click="cancelFilter">
        <FeatherIcon
          type="x"
          size="14"
        />
      </a-button>
    </div>
    <div class="filter-content">
      <FilterSource v-show="filterActiveKey" />
      <FilterCategory />
      <FilterMessageType />
      <FilterSentiment />
      <FilterContentType />
      <FilterOwnMedia />
    </div>
    <div class="filter-footer">
      <a-button @click="resetFilter">Reset</a-button>
      <a-button
        type="primary"
        @click="applyFilter"
        >Apply</a-button
      >
    </div>
  </a-layout-sider>
</template>

<script>
import { computed, watch } from 'vue';
import { useStore } from 'vuex';

import FilterSource from './Filters/FilterSource.vue';
import FilterCategory from './Filters/FilterCategory.vue';
import FilterMessageType from './Filters/FilterMessageType.vue';
import FilterSentiment from './Filters/FilterSentiment.vue';
import FilterContentType from './Filters/FilterContentType.vue';
import FilterOwnMedia from './Filters/FilterOwnMedia.vue';

export default {
  components: {
    FilterSource,
    FilterCategory,
    FilterMessageType,
    FilterSentiment,
    FilterContentType,
    FilterOwnMedia,
  },
  setup() {
    const store = useStore();
    const tempCriteria = computed(() => store.getters['filter/tempCriteria']);
    const filterCollapsed = computed(() => store.state.filter.filterCollapsed);
    const filterActiveKey = computed(() => store.state.filter.activeSource);

    const closeFilter = () => {
      store.dispatch('filter/toggleFilter', true);
    };
    const applyFilter = () => {
      store.dispatch('filter/applyFilter');
      // store.dispatch('filter/createQueryUrl');
      closeFilter();
    };
    const resetFilter = () => {
      store.dispatch('filter/resetFilter');
      closeFilter();
    };
    const cancelFilter = () => {
      store.dispatch('filter/cancelFilter');
      closeFilter();
    };

    watch(
      () => filterCollapsed.value,
      () => {
        // cancel filter
        if (filterCollapsed.value) {
          cancelFilter();
        }
      }
    );

    return {
      closeFilter,
      applyFilter,
      resetFilter,
      filterCollapsed,
      tempCriteria,
      cancelFilter,
      filterActiveKey,
    };
  },
};
</script>

<style lang="scss">
#filter-sidebar {
  overflow: auto;
  height: 100vh;
  max-height: -webkit-fill-available;
  position: fixed;
  right: 0;
  z-index: 100;
  display: flex;
  flex-direction: column;
  overflow: hidden;

  .filter-head {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 16px 24px;
    border-bottom: 1px solid #f0f0f0;
    user-select: none;

    .head-text {
      font-size: 18px;
      line-height: 22px;
      color: #272b41;
      font-weight: 500;

      i {
        color: #3371ff;
        margin-right: 14px;
      }
    }
  }

  .filter-content {
    flex: 1 1 auto;
    overflow-y: auto;
    height: calc(100% - 151px);
  }

  .filter-footer {
    border-top: 1px solid #fafafa;
    height: 76px;
    padding: 16px 24px;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  .content-collapse {
    margin-top: 16px;
  }

  .s-tag {
    background-color: #f0f5ff;
    border-radius: 4px;
    color: #5a5f7d;
    margin-bottom: 8px;
    border: none;
    padding: 5px 10px;
    font-weight: 400;
  }
}
</style>
