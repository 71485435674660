<template>
  <a-spin :spinning="isSaving">
    <div
      :id="`inner-${msgId}`"
      class="message-item"
      :class="{ 'retweet-item': isRetweet }"
      @click="emitClick"
    >
      <div
        v-if="showCommentTag && !isOrigin"
        class="comment-tag"
      >
        <FeatherIcon
          type="message-square"
          class="mr-1"
        ></FeatherIcon>
        Comment
      </div>
      <SelectBoxHeader>
        <template
          v-if="$slots['header-prefix']"
          #header-prefix
        >
          <slot name="header-prefix"></slot>
        </template>
        <MessageHead
          class="message-item-head"
          :username="username"
          :source="source"
          :order-no="orderNo"
          :profile-image="profileImage"
          :fallback-image="fallbackImage"
          :date="dateStr"
          :updatedAt="updateDateStr"
          :userLink="userLink"
          :isRetweet="isRetweet"
          :commentInPage="commentInPage"
          :hasRetweet="hasRetweet"
        >
          <template #right>
            <div class="right-block">
              <SentimentCircleIcon :data="itemData" />
              <MessageItemMoreMenu
                :msg-id="msgId"
                :data="itemData"
                :editable="editable"
                :sub-category-name="subCategoryName"
                :parentName="parentName"
              >
                <FeatherIcon
                  class="more-menu"
                  type="more-horizontal"
                  size="24"
                />
              </MessageItemMoreMenu>
            </div>
          </template>
        </MessageHead>
      </SelectBoxHeader>

      <div
        v-if="hasMedia"
        class="media-wrapper"
      >
        <img
          v-if="useLazy"
          :id="`img-${msgId}`"
          v-lazy="imageUrl"
          class="media-img"
          @error="onImageError($event, fallbackMessageImageUrl)"
        />
        <img
          v-else
          :id="`img-${msgId}`"
          :src="imageUrl"
          class="media-img"
          @error="onImageError($event, fallbackMessageImageUrl)"
        />
      </div>
      <div class="content-wrapper">
        <div
          v-if="itemData.raw_data && itemData.raw_data.title"
          class="message-title"
        >
          {{ item.raw_data.title }}
        </div>
        <div
          v-if="itemData && itemData.raw_data.hashtag"
          class="mb-1"
        >
          <a-tag
            v-for="tag in itemData.raw_data.hashtag"
            :key="tag"
            class="hashtag"
            :color="currentSourceColor"
          >
            {{ tag }}
          </a-tag>
        </div>
        <span
          class="word-break-all highlight-text-content"
          v-html="textContent"
        >
        </span>
        <a
          v-if="showLongText && isLongText"
          class="seemore"
          @click.stop="toggleFullText"
        >
          <span v-if="showFullText">See Less</span>
          <span v-else>See More</span>
        </a>
        <a
          v-if="!showLongText && isLongText"
          class="seemore"
          @click.stop="seeMore"
        >
          See More
        </a>
        <div v-if="hasThumb && imageUrl">
          <div class="thumbnail-wrapper">
            <img
              v-if="useLazy"
              :id="`thumb-${msgId}`"
              v-lazy="imageUrl"
              :fallback-src="fallbackMessageImageUrl"
              class="thumb-img"
              @error="onImageError($event, fallbackMessageImageUrl)"
            />
            <img
              v-else
              :id="`thumb-${msgId}`"
              :src="imageUrl"
              class="thumb-img"
              @error="onImageError($event, fallbackMessageImageUrl)"
            />
          </div>
          <OcrText
            v-if="isShowOcr"
            class="mt-2"
            :text="ocrImageText"
            :trigger="isDomAdmin ? 'hover' : ''"
          ></OcrText>
        </div>
        <div
          v-if="mediaBlock"
          class="media-block"
        >
          <template v-if="videoObj && videoObj.url">
            <iframe
              v-if="videoObj.type === 'facebook'"
              :src="videoObj.url"
              width="500"
              height="420"
              style="border: none; overflow: hidden"
              scrolling="no"
              frameborder="0"
              allowTransparency="true"
            ></iframe>
            <iframe
              v-else-if="videoObj.type === 'instagram'"
              :src="videoObj.url"
              width="400"
              height="600"
              style="border: none; overflow: hidden"
              scrolling="no"
              frameborder="0"
              allowTransparency="true"
            ></iframe>
            <template v-else-if="videoObj.type === 'youtube'">
              <div :id="`yt-${msgId}`"></div>
            </template>
            <div
              v-else-if="videoObj.type === 'twitter'"
              class="tweet-wrapper"
            >
              <Tweet
                :tweet-id="videoObj.url"
                cards="visible"
                conversation="none"
                lang="en"
                theme="dark"
                align="left"
                :width="400"
                class="inner-tweet-wrapper"
              >
              </Tweet>
            </div>
          </template>
          <img
            v-else-if="imageUrl"
            class="media-img"
            :src="imageUrl"
            @error="onImageError($event, fallbackMessageImageUrl)"
          />
        </div>
      </div>
      <EngagementBar
        v-if="!isRetweet"
        class="engage-bar"
        :class="{ 'no-category': !showCategory }"
        :data="item"
        :isMobile="isMobileScreen"
      />
      <CategoriesBar
        v-if="showCategory"
        class="category-bar"
        :msg-id="msgId"
        :data="item"
        :editable="editable"
      />
      <MessageActionBar
        :data="item"
        :isDomAdmin="isDomAdmin"
      />
      <MessageTimeBar :updateDateStr="updatedAtTitle" />
    </div>
    <div
      :id="`copy-${parentName}-${msgId}`"
      @click="helper.copyUrl(item.raw_data.redirect_url || item.raw_data.link)"
    ></div>
  </a-spin>
</template>

<script>
/* eslint-disable no-unused-vars, max-len, vue/no-unused-components */

import {
  toRefs,
  computed,
  ref,
  onMounted,
  watch,
  watchEffect,
  reactive,
  inject,
} from 'vue';
import { useStore } from 'vuex';

import imagesLoaded from 'imagesloaded';
import getYouTubeID from 'get-youtube-id';
import YouTubePlayer from 'youtube-player';
import Tweet from './VueTweet.vue';

import MessageHead from './MessageHead.vue';
import EngagementBar from './EngagementBar.vue';
import SentimentCircleIcon from './SentimentCircleIcon.vue';
import CategoriesBar from './CategoriesBar.vue';
import MessageItemMoreMenu from './MessageItemMoreMenu.vue';

import MessageActionBar from './MessageActionBar.vue';
import MessageTimeBar from './MessageTimeBar.vue';

import helper from '@/services/helper';
import SelectBoxHeader from '../../views/Explore/SelectBoxHeader.vue';
import FeatherIcon from '../Icon/FeatherIcon.vue';
import OcrText from '@/components/Tag/OcrText.vue';
export default {
  components: {
    MessageHead,
    EngagementBar,
    CategoriesBar,
    SentimentCircleIcon,
    MessageItemMoreMenu,
    Tweet,
    SelectBoxHeader,
    MessageActionBar,
    MessageTimeBar,
    OcrText,
  },
  props: {
    useLazy: Boolean,
    item: {
      type: Object,
      default: () => {},
    },
    isCount: Boolean,
    orderNo: {
      type: Number,
      default: -1,
    },
    showCommentTag: Boolean,
    showCategory: Boolean,
    hasMedia: Boolean,
    mediaBlock: Boolean,
    hasThumb: Boolean,
    gridItem: Boolean,
    windowWidth: {
      type: Number,
      default: 600,
    },
    updateVar: {
      type: String,
      default: '',
    },
    showLongText: Boolean,
    showAdminBar: Boolean,
    parentName: {
      type: String,
      default: 'def',
    },
  },
  emits: ['click'],
  setup(props, { emit }) {
    const store = useStore();
    const editable = computed(
      () => store.getters['account/messageEditableRole'],
    );
    const keywordPhrase = computed(
      () => store.getters['filter/filterCriteria'].keywordPhrase,
    );
    const isDomAdmin = computed(() => store.getters['account/isDomAdmin']);
    const isMobileScreen = computed(() => store.state.isMobileScreen);

    let subCategoryName;
    let sc = inject('subCategoryName');
    if (sc) {
      subCategoryName = sc;
    } else {
      const fallbackCategory = JSON.parse(
        localStorage.getItem('category-name'),
      );
      subCategoryName = ref(fallbackCategory);
      console.log('TRY localStorage method', subCategoryName.value);
    }
    const { item: itemData, gridItem, hasThumb, mediaBlock } = toRefs(props);
    const showFullText = ref(false);
    const toggleFullText = () => {
      showFullText.value = !showFullText.value;
    };

    const localData = reactive({
      msgId: '',
      username: '',
      userLink: '',
      source: '',
      profileImage: '',
      fallbackImage: '',
      dateStr: '...',
      updateDateStr: '...',
      updatedAtTitle: '',
      isRetweet: false,
      hasRetweet: false,
      currentSourceColor: '#2C99FF',
      isOrigin: false,
      commentInPage: '',
    });

    watchEffect(() => {
      // flatten and prepare data
      if (itemData.value && itemData.value.info) {
        const { id, source, created_at, updated_at, is_origin, page } =
          itemData.value.info;
        // console.count(`effect-${id}`);
        localData.msgId = id;
        localData.source = source || 'others';
        localData.dateStr = helper.formatDate(created_at, 'D MMM YYYY, H:mm A');
        localData.updateDateStr = helper.timeFromNow(updated_at);
        localData.updatedAtTitle = helper.formatDate(
          updated_at,
          'D MMM YYYY, H:mm A',
        );
        // Set source color
        if (source === 'facebook') localData.currentSourceColor = '#1877F2';
        if (source === 'twitter') localData.currentSourceColor = '#1DA1F2';
        if (source === 'pantip') localData.currentSourceColor = '#7674BC';
        if (source === 'youtube') localData.currentSourceColor = '#FF0000';
        if (source === 'instagram') localData.currentSourceColor = '#E4405F';
        if (source === 'website') localData.currentSourceColor = '#252525';
        // User
        if (itemData.value.user) {
          const { link, username, photo, fallback_photo } = itemData.value.user;
          localData.userLink = link || '';
          localData.username = username || 'User';
          localData.profileImage = photo || '';
          if (source === 'facebook') {
            localData.fallbackImage = fallback_photo || '';
          }
        }
        if (itemData.value.raw_data) {
          const { image, fallback_image } = itemData.value.raw_data;
          localData.imageUrl = image || '';
          localData.fallbackMessageImageUrl = fallback_image || '';
        }

        // text
        let text = '';
        if (itemData.value && itemData.value.highlighted_text) {
          text = itemData.value.highlighted_text;
        } else if (
          itemData.value &&
          itemData.value.raw_data &&
          itemData.value.raw_data.text
        ) {
          text = itemData.value.raw_data.text;
        }
        localData.isLongText = text && text.length > 250;

        // Check has inpage
        if (!is_origin && page) {
          localData.commentInPage = page;
        }

        // Check is retweet?
        localData.isRetweet = source === 'twitter' && !is_origin;
        if (source === 'twitter' && is_origin) {
          if (itemData.value.summary && itemData.value.summary.retweet_count) {
            localData.hasRetweet = true;
          } else {
            localData.hasRetweet = false;
          }
        }
        localData.isOrigin = is_origin;
      }
    });

    const textContent = computed(() => {
      // TODO - check long text from raw text only
      let text = '';
      if (itemData.value && itemData.value.highlighted_text) {
        text = itemData.value.highlighted_text;
      } else if (
        itemData.value &&
        itemData.value.raw_data &&
        itemData.value.raw_data.text
      ) {
        text = itemData.value.raw_data.text;
      }
      if (text) {
        if (text && text.length > 250) {
          if (showFullText.value) {
            return text;
          }
          return helper.truncate(text) + '...';
        } else {
          return text;
        }
      }
      return '';
    });

    const onImageError = (e, fallbackMessageImageUrl) => {
      if (fallbackMessageImageUrl && e.target.src !== fallbackMessageImageUrl) {
        e.target.src = fallbackMessageImageUrl;
      } else {
        e.target.remove();
      }
    };

    const videoObj = computed(() => {
      if (itemData.value) {
        const { info, raw_data } = itemData.value;
        // youtube
        if (raw_data.video) {
          return {
            type: info.source,
            url: getYouTubeID(raw_data.link),
          };
        }
        // fb / ig / twitter
        if (info.has_video) {
          const obj = {
            url: '',
            type: info.source,
          };
          if (info.source === 'facebook') {
            // Check valid video
            if (info.video_url && info.video_url.indexOf('/videos/') > -1) {
              // Ok
              obj.url =
                'https://www.facebook.com/plugins/video.php?href=' +
                encodeURI(info.video_url) +
                '&width=500&show_text=false&height=420';
            } else {
              return {};
            }
          } else if (info.source === 'instagram') {
            obj.url = info.video_url;
          } else if (info.source === 'twitter') {
            obj.url = info.id.split('_').pop();
          } else if (info.source === 'youtube') {
            obj.url = getYouTubeID(raw_data.link);
          }
          return obj;
        }
      }
      return {};
    });

    const isSaving = computed(() => {
      if (itemData.value) {
        return itemData.value.editing || false;
      }
      return false;
    });

    const resizeItem = (msgId) => {
      setTimeout(() => {
        if (gridItem.value) {
          const inner = document.getElementById(`inner-${msgId}`);
          const msg = document.getElementById(`msg-${msgId}`);
          const grid = document.getElementsByClassName('masonry')[0];
          if (grid) {
            const rowGap = parseInt(
              window.getComputedStyle(grid).getPropertyValue('grid-row-gap'),
            );
            const rowHeight = parseInt(
              window.getComputedStyle(grid).getPropertyValue('grid-auto-rows'),
            );
            if (inner) {
              const bound = inner.getBoundingClientRect();
              const rowSpan = Math.ceil(
                (bound.height + rowGap + 14) / (rowHeight + rowGap),
              );
              msg.style.gridRowEnd = 'span ' + rowSpan;
            } else {
              console.log('INNER NOT FOUND', msgId);
            }
          }
        }
      });
    };

    watch(
      () => props.windowWidth,
      () => {
        if (localData.msgId) {
          resizeItem(localData.msgId);
        }
      },
    );
    watch(
      () => props.updateVar,
      () => {
        // console.log('OHH its time', localData.msgId);
        setTimeout(() => {
          if (localData.msgId) {
            resizeItem(localData.msgId);
          }
        });
      },
    );
    onMounted(() => {
      if (localData.msgId) {
        const elem = document.getElementById(`img-${localData.msgId}`);
        if (elem) {
          imagesLoaded(elem, () => {
            // console.log('OH YEAH', elem);
            resizeItem(localData.msgId);
          }).on('fail', () => {
            // console.log('FAILed');
            elem.remove();
            resizeItem(localData.msgId);
          });
        } else {
          // calculate height without image
          resizeItem(localData.msgId);
        }
      } else {
        // console.log('Naaaa');
      }
      if (hasThumb.value) {
        const elem = document.getElementById(`thumb-${localData.msgId}`);
        if (elem) {
          imagesLoaded(elem, () => {}).on('fail', () => {
            // elem.target.src = helper.getDefaultPlaceholder();
            elem.remove();
          });
        }
      }
      setTimeout(() => {
        if (
          mediaBlock.value &&
          videoObj.value &&
          videoObj.value.type === 'youtube'
        ) {
          // If youtube load player
          let player = YouTubePlayer(`yt-${localData.msgId}`);
          player.loadVideoById(videoObj.value.url);
        }
      });
    });

    const seeMore = () => {
      store.dispatch('seemore/show', itemData.value);
    };

    const emitClick = (e) => {
      emit('click', e);
    };

    const ocrImageText = computed(() => {
      const imageText = itemData.value.highlighted_image_text;
      return imageText;
    });

    const isShowOcr = computed(() => {
      return (
        ocrImageText.value &&
        keywordPhrase.value &&
        keywordPhrase.value.trim() &&
        helper.isHightlight(ocrImageText.value)
      );
    });

    return {
      ...localData,
      onImageError,
      textContent,
      showFullText,
      toggleFullText,
      seeMore,
      itemData,
      videoObj,
      subCategoryName,
      isSaving,
      emitClick,
      editable,
      isDomAdmin,
      isMobileScreen,
      helper,
      ocrImageText,
      isShowOcr,
    };
  },
};
</script>

<style lang="scss" scoped>
.message-item {
  padding-top: 20px;
  position: relative;

  .comment-tag {
    // position: absolute;
    // top: 5px;
    // left: 0;
    width: 95px;
    margin-top: -10px;
    margin-bottom: 5px;
    color: #2c99ff;
    background-color: #dff0ff;
    font-size: 12px;
    line-height: 14px;
    padding: 4px 8px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 0 4px 4px 0;
  }

  .right-block {
    display: flex;
    align-items: center;

    .more-menu {
      cursor: pointer;
      margin-left: 18px;
      color: #adb4d2;
    }
  }

  .message-item-head {
    padding: 0 20px;
  }

  .message-title {
    font-size: 16px;
    line-height: 20px;
    margin-bottom: 12px;
    color: #272b41;
    font-weight: 500;
  }

  .content-wrapper {
    padding: 0 20px 14px 20px;
    font-size: 15px;
    line-height: 24px;
    margin: 16px 0;
    color: #5a5f7d;
    border-bottom: 1px solid #f1f2f6;

    .hashtag-wrapper {
      margin-bottom: 18px;
    }

    .hashtag {
      margin-right: 4px;
      margin-bottom: 4px;
    }
  }

  .engage-bar {
    padding: 0 20px;
    margin-bottom: 14px;

    &.no-category {
      margin-bottom: 36px;
    }
  }

  .category-bar {
    border-top: 1px solid #f1f2f6;
    padding: 20px;
  }

  .seemore {
    margin-left: 3px;
    color: #333;
    font-weight: 500;
  }

  .media-wrapper {
    border-top: 1px solid #f1f2f6;
    margin-top: 15px;

    .media-img {
      width: 100%;
      height: auto;
    }
  }

  .media-block {
    background: #303030;
    text-align: center;
    border-radius: 8px;
    margin: 12px 0;

    .media-img {
      border-radius: 8px;
      border: 1px solid #e3e6ef;
      width: 100%;
      height: auto;
    }

    .tweet-wrapper {
      display: flex;
      justify-content: center;
      min-height: 650px;
      height: 100%;
    }
  }

  .thumbnail-wrapper {
    margin-top: 16px;
    display: block;
    border-radius: 4px;
    overflow: hidden;
    width: 80px;
    height: 80px;
    background-color: #adb4d2;

    .thumb-img {
      width: 80px;
      height: 80px;
    }
  }
}

.retweet-item {
  .content-wrapper {
    border-bottom: none;
    margin-bottom: 0;
  }
}
</style>
<style scoped>
:deep(strong) {
  font-weight: bold;
}
</style>
