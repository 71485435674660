<template>
  <div class="login-page">
    <img
      class="logo-bg-image"
      :src="require('../assets/images/login/bg.svg')"
      alt="logo bg"
    />
    <a-row class="login-page-row">
      <a-col
        :xs="0"
        :sm="12"
        class="logo-block-wrapper"
      >
        <img
          class="sec-image"
          :src="require('../assets/images/login/left-base.png')"
        />
        <img
          class="logo-image"
          :src="require('../assets/images/logo/logo_dom-white.svg')"
        />
        <img
          class="center-image"
          :src="require('../assets/images/login/left-mid.svg')"
        />
        <div class="title-1">A Launchpad to Your Success !</div>
        <div class="title-2">
          Hearing customers voice clearer and faster, seethrough competitors’
          strategy to ensure your business success.
        </div>
      </a-col>
      <a-col
        :xs="24"
        :sm="12"
        class="login-form-wrapper"
      >
        <a-form
          class="login-form"
          :model="formState"
          layout="vertical"
          @finish="handleSubmit"
        >
          <div class="form-title">
            <span class="blue-text">Login</span>
            <span>Account</span>
          </div>
          <a-form-item
            name="brand"
            label="Account"
          >
            <a-input
              v-model:value="formState.brand"
              type="text"
            />
          </a-form-item>
          <a-form-item
            name="username"
            label="Username"
          >
            <a-input
              v-model:value="formState.username"
              type="text"
            />
          </a-form-item>
          <a-form-item
            name="password"
            label="Password"
          >
            <a-input
              v-model:value="formState.password"
              type="password"
            />
          </a-form-item>
          <div class="space-between-center">
            <a-checkbox @change="onChange">Keep me logged in</a-checkbox>
            <router-link to="/forgot-password"> Forgot password? </router-link>
            <!-- <router-link class="forgot-pass-link" to=""> Forgot password? </router-link> -->
          </div>
          <a-form-item>
            <a-button
              class="login-button"
              html-type="submit"
              type="primary"
              size="large"
            >
              {{ isLoading ? 'Loading...' : 'Log In' }}
            </a-button>
          </a-form-item>
        </a-form>
      </a-col>
    </a-row>
  </div>
</template>

<script>
import { reactive, ref } from 'vue';
import { useStore } from 'vuex';
import { useRouter } from 'vue-router';
import { notification } from 'ant-design-vue';

import api from '@/services/api';

export default {
  setup() {
    const { dispatch } = useStore();
    const router = useRouter();
    const checked = ref(null);
    const isLoading = ref(false);

    const doLogin = async (brand, username, password, mode) => {
      if (isLoading.value) {
        return;
      }
      isLoading.value = true;
      const loginResult = await api
        .login({
          username,
          password,
          mode,
          brand,
          grant_type: 'password',
        })
        .catch((e) => {
          // TODO handle error login
          notification.error({
            message: 'Login Failed !!',
            description:
              'Please re-check your username and password then try again',
          });
          console.error(e);
          isLoading.value = false;
        });
      if (loginResult) {
        console.log(loginResult);
        notification.success({
          message: 'Login Success',
        });
        await dispatch('account/setConfig', loginResult);
        await dispatch('filter/clearFilter');

        await dispatch('filter/initFilter').then(() => {
          // Clear old v2 data
          if (window.localStorage) {
            window.localStorage.removeItem('domv2');
            window.localStorage.removeItem('domv2brand');
          }
          // Redirect to home
          isLoading.value = false;
          if (loginResult.userInfo.role === 'accountmanager') {
            router.push('/user-manager');
          } else if (loginResult.userInfo.role === 'viewer') {
            router.push('/prepare-viewer');
          } else {
            router.push('/');
          }
        });
      }
    };

    const onChange = (checked) => {
      checked.value = checked;
    };

    const formState = reactive({
      brand: '',
      username: '',
      password: '',
    });

    const handleSubmit = async () => {
      // router.push('/');
      // dispatch('login');
      const { brand, username, password } = formState;
      doLogin(brand, username, password, 'dom');
    };
    return {
      checked,
      handleSubmit,
      onChange,
      formState,
      isLoading,
    };
  },
};
</script>

<style lang="scss" scoped>
@import '../config/theme/colors.json';
.login-page {
  background: #495c83;
  height: 100vh;
  min-height: -webkit-fill-available;
  width: 100vw;
  padding: 76px;
  position: relative;
  overflow-y: scroll;
  overflow-x: hidden;
  .logo-bg-image {
    object-fit: cover;
    position: absolute;
    left: 0;
    top: 0;
    z-index: 0;
    width: 100%;
    max-width: 100vw;
    height: -webkit-fill-available;
    overflow: hidden;
  }
  .login-page-row {
    z-index: 100;
    background-color: #fff;
    border-radius: 10px;
    overflow: hidden;
    height: 100%;
    min-height: 500px;
    .logo-block-wrapper {
      z-index: 100;
      background: linear-gradient(90deg, #283457, #495482);
      color: #fff;
      padding: 0 !important;
      position: relative;
      .sec-image {
        width: 100%;
        height: 100%;
        position: absolute;
        top: 0;
        left: 0;
      }
      .logo-image {
        position: absolute;
        height: 30px;
        width: auto;
        top: 30px;
        left: 30px;
      }
      .center-image {
        position: absolute;
        width: 80%;
        max-width: 443px;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -60%);
      }
      .title-1 {
        position: absolute;
        width: 100%;
        bottom: 128px;
        font-size: 24px;
        line-height: 30px;
        font-weight: 700;
        padding: 15px;
        text-align: center;
      }
      .title-2 {
        position: absolute;
        width: 100%;
        bottom: 50px;
        font-size: 14px;
        line-height: 24px;
        font-weight: 400;
        padding: 15px;
        text-align: center;
      }
    }
    .login-form-wrapper {
      z-index: 100;
      background: #fff;
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }
  .login-form {
    width: 360px;
    max-width: 80%;
    .form-title {
      margin-bottom: 48px;
      font-size: 24px;
      font-weight: 500;
      .blue-text {
        color: #3371ff;
        margin-right: 4px;
      }
    }
    .ant-form-item-label > label {
      color: #666d92;
      font-size: 14px;
      letter-spacing: 0.08em;
    }
    .ant-input {
      border-radius: 4px;
    }
    .login-button {
      width: 160px;
      margin-top: 32px;
    }
  }

  // xs only
  @media only screen and (max-width: 576px) {
    padding: 15px;
    .logo-bg-image {
      display: none;
    }
    .login-form {
      padding: 20px;
    }
  }
}
</style>
