
import DashboardSettingDetail from '@/views/Preset/DashboardSettingDetail.vue';
import { computed, ref } from 'vue';
import { useStore } from 'vuex';
export default {
  components: { DashboardSettingDetail },
  props: {
    mobile: {
      type: Boolean,
    },
  },
  name: 'DashboardSettingButton',
  setup() {
    const store = useStore();
    const open = ref(false);
    const activeCampaign = computed(() => {
      const activeCampaignCriteria =
        store.getters['presetFilter/activeCampaignFilter'];

      return {
        filter: activeCampaignCriteria,
      };
    });
    return {
      activeCampaign,
      open,
    };
  },
};
