<template>
  <a-tabs
    v-model:activeKey="activeKey"
    class="menu-tabs"
    @change="setKey"
  >
    <a-tab-pane
      v-for="tab in tabMenuList"
      :key="tab.value"
      :tab="tab.label"
      :forceRender="true"
    ></a-tab-pane>
  </a-tabs>
</template>

<script>
import { ref, toRefs, watch } from 'vue';
export default {
  name: 'MenuTab',
  props: {
    tabKey: String,
    tabLists: {
      type: Array,
      default: () => [],
    },
  },
  setup(props, { emit }) {
    const { tabKey, tabLists } = toRefs(props);
    const activeKey = ref(tabKey.value);
    const tabMenuList = ref(tabLists);

    const setKey = (value) => {
      activeKey.value = value;
      emit('setActiveKey', value);
    };

    watch(
      () => tabKey.value,
      () => {
        activeKey.value = tabKey.value;
      }
    );

    return {
      activeKey,
      tabMenuList,
      setKey,
    };
  },
};
</script>

<style lang="scss" scoped>
.menu-tabs {
  width: 100%;
}
</style>

<style scoped>
:deep(.ant-tabs) {
  align-items: center;
  margin-bottom: 15px;
}

:deep(.ant-tabs-line) {
  width: 100%;
}
:deep(.ant-tabs-bar) {
  margin-bottom: 0;
}
</style>
