import { resolveComponent as _resolveComponent, normalizeClass as _normalizeClass, createVNode as _createVNode, withCtx as _withCtx, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, renderSlot as _renderSlot, createTextVNode as _createTextVNode, openBlock as _openBlock, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-a0d2a84c"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "title" }
const _hoisted_2 = { class: "ask-text" }
const _hoisted_3 = { class: "action-button-modal text-right" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_FeatherIcon = _resolveComponent("FeatherIcon")!
  const _component_a_col = _resolveComponent("a-col")!
  const _component_a_button = _resolveComponent("a-button")!
  const _component_a_row = _resolveComponent("a-row")!
  const _component_a_modal = _resolveComponent("a-modal")!

  return (_openBlock(), _createBlock(_component_a_modal, {
    centered: "",
    width: 520,
    footer: null,
    closable: false,
    onCancel: $setup.closeConfirm
  }, {
    default: _withCtx(() => [
      _createElementVNode("div", null, [
        _createVNode(_component_a_row, null, {
          default: _withCtx(() => [
            _createVNode(_component_a_col, { span: "2" }, {
              default: _withCtx(() => [
                _createVNode(_component_FeatherIcon, {
                  type: "alert-circle",
                  size: "18",
                  class: _normalizeClass($setup.color)
                }, null, 8, ["class"])
              ]),
              _: 1
            }),
            _createVNode(_component_a_col, { span: "22" }, {
              default: _withCtx(() => [
                _createElementVNode("div", _hoisted_1, _toDisplayString($props.title), 1),
                _createElementVNode("div", _hoisted_2, [
                  _renderSlot(_ctx.$slots, "default", {}, undefined, true)
                ]),
                _createElementVNode("div", _hoisted_3, [
                  _createVNode(_component_a_button, {
                    size: "large",
                    type: "default",
                    class: "cancel-button mr-2",
                    outlined: true,
                    onClick: $setup.closeConfirm
                  }, {
                    default: _withCtx(() => [
                      _createTextVNode(" Cancel ")
                    ]),
                    _: 1
                  }, 8, ["onClick"]),
                  _createVNode(_component_a_button, {
                    size: "large",
                    type: "default",
                    class: _normalizeClass($setup.btnColor),
                    onClick: $setup.onConfirm
                  }, {
                    default: _withCtx(() => [
                      _createTextVNode(_toDisplayString($props.okTitle), 1)
                    ]),
                    _: 1
                  }, 8, ["class", "onClick"])
                ])
              ]),
              _: 3
            })
          ]),
          _: 3
        })
      ])
    ]),
    _: 3
  }, 8, ["onCancel"]))
}