import { resolveComponent as _resolveComponent, createVNode as _createVNode, createTextVNode as _createTextVNode, withCtx as _withCtx, createElementVNode as _createElementVNode, openBlock as _openBlock, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "d-flex flex-align-right" }
const _hoisted_2 = { class: "action" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_PresetDetail = _resolveComponent("PresetDetail")!
  const _component_a_button = _resolveComponent("a-button")!
  const _component_a_modal = _resolveComponent("a-modal")!

  return (_openBlock(), _createBlock(_component_a_modal, {
    title: "Save to campaign dashboard",
    width: "620px",
    bodyStyle: { padding: 0 },
    onCancel: $setup.onCancel
  }, {
    footer: _withCtx(() => [
      _createElementVNode("div", _hoisted_1, [
        _createElementVNode("div", _hoisted_2, [
          _createVNode(_component_a_button, {
            type: "light",
            onClick: $setup.onCancel
          }, {
            default: _withCtx(() => [
              _createTextVNode("Cancel")
            ]),
            _: 1
          }, 8, ["onClick"]),
          _createVNode(_component_a_button, {
            type: "primary",
            disabled: $setup.isFormError,
            onClick: $setup.onSave
          }, {
            default: _withCtx(() => [
              _createTextVNode("Save")
            ]),
            _: 1
          }, 8, ["disabled", "onClick"])
        ])
      ])
    ]),
    default: _withCtx(() => [
      _createVNode(_component_PresetDetail, {
        mode: "campaign",
        item: $setup.presetData.preset,
        "first-validate": $props.firstValidate,
        onDataChange: $setup.onDetailChange
      }, null, 8, ["item", "first-validate", "onDataChange"])
    ]),
    _: 1
  }, 8, ["onCancel"]))
}