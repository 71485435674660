
import api from '@/services/api';
import { PresetInfoType } from '@/types/preset-management/PresetType';
import { computed, onBeforeMount, PropType, reactive, ref, watch } from 'vue';
import _ from 'lodash';
export default {
  name: 'PresetDetail',
  props: {
    item: {
      type: Object as PropType<Partial<PresetInfoType>>,
    },
    firstValidate: {
      type: Boolean,
      default: false,
    },
    mode: {
      type: String,
      default: 'preset',
    },
  },
  emits: ['dataChange'],
  setup(props, { emit }) {
    const createAsCampaign = computed(() => {
      return props.mode === 'campaign';
    });
    const formRef = ref();
    const formErrors = ref<string[]>([]);
    const validationEnabled = ref(false);
    const isFormValid = computed<boolean>(() => {
      return formErrors.value.length === 0;
    });

    const data = reactive<Partial<PresetInfoType>>({
      presetName: '',
      presetDescription: '',
    });

    const validatePresetName = async (_, value) => {
      if (!validationEnabled.value) return Promise.resolve();

      if (!value) {
        return Promise.reject('Please input the preset name');
      } else {
        const res = await api
          .getUserFilterPresetByName({ name: value })
          .catch(() => {});
        if (res) {
          const foundPresetId = res.message.id;
          if (foundPresetId !== props.item?.id) {
            return Promise.reject('Already have a preset filter name');
          }
        }
      }
      return Promise.resolve();
    };
    const rules = {
      presetName: [{ required: true, validator: validatePresetName }],
    };

    const submitForm = async () => {
      if (formRef.value) {
        try {
          await formRef.value.validate();
          formErrors.value = [];
        } catch (error: any) {
          formErrors.value = Object.values(error.errorFields).flatMap(
            (field: any) => field.errors
          );
        }
      }
    };

    const init = async () => {
      validationEnabled.value = true;
      await submitForm();
      validationEnabled.value = false;
      emit('dataChange', { ...data, isValid: isFormValid.value });
    };

    watch(
      () => data.presetName,
      () => {
        emit('dataChange', { ...data, isValid: false });
      }
    );

    watch(
      () => data.presetName,
      _.debounce(async () => {
        init();
      }, 300)
    );

    watch(
      () => data.presetDescription,
      () => {
        if (!data.presetName) {
          init();
        } else {
          emit('dataChange', { ...data, isValid: isFormValid.value });
        }
      }
    );

    onBeforeMount(async () => {
      if (props.item) {
        data.presetName = props.item.presetName;
        data.presetDescription = props.item.presetDescription;
      }

      if (props.firstValidate) {
        init();
      }
    });
    return { data, rules, formRef, isFormValid, formErrors, createAsCampaign };
  },
};
