<template>
  <div
    class="lineage-item"
    :class="{
      'non-origin': message.lineage.level > 0,
      'target-item-wrapper': message.isTarget,
    }"
  >
    <div
      v-if="message.lineageControl && message.lineageControl.hasPrev"
      class="load-more-card"
      :class="loadMoreClass"
      @click="getPrevious"
    >
      <span v-if="loadingPrev"> Loading&nbsp;</span>
      <span v-else>Load previous {{ message.lineage.index }}&nbsp;</span>
      <span v-if="message.lineage.level === 1">comments</span>
      <span v-if="message.lineage.level === 2">replies</span>
    </div>
    <template v-if="message.lineage.level === 0">
      <MessageItem
        class="list-item"
        :item="message"
        :show-category="true"
        :media-block="true"
        :show-long-text="true"
        :parentName="parentName"
      />
      <div
        v-if="mentionCount > 0"
        class="comment-count-bar"
      >
        {{ mentionCount }} Comments
      </div>
    </template>
    <CommentItem
      v-else
      :level="message.lineage.level"
      :item="message"
    />

    <div
      v-if="
        message.lineageControl &&
        message.lineageControl.hasReplies &&
        !loadingReplies
      "
      class="load-more-card message-subcomment"
      @click="getReply"
    >
      <span v-if="message.lineage.child_count > 1">
        Load more {{ message.lineage.child_count }} replies
      </span>
      <span v-if="message.lineage.child_count === 1"> Load more reply </span>
    </div>
    <div
      v-if="message.lineageControl && message.lineageControl.hasNext"
      class="load-more-card"
      :class="loadMoreClass"
      @click="getNext"
    >
      <span v-if="loadingNext"> Loading&nbsp;</span>
      <span v-else>Load more {{ message.lineageControl.nextCount }}&nbsp;</span>
      <span v-if="message.lineage.level === 1">comments</span>
      <span v-if="message.lineage.level === 2">replies</span>
    </div>
    <div
      v-if="message.lineageControl && message.lineageControl.hasFinalNext"
      class="load-more-card"
      :class="loadMoreClass"
      @click="getNextFinal"
    >
      <span v-if="loadingFinalNext"> Loading&nbsp;</span>
      <span v-else
        >Load more {{ message.lineageControl.finalNextCount }} comments</span
      >
    </div>
  </div>
</template>

<script>
import { toRefs, ref, computed } from 'vue';
import { useStore } from 'vuex';
import MessageItem from '@/components/Message/MessageItem.vue';
import CommentItem from '@/components/Message/CommentItem.vue';

export default {
  name: 'LineageItem',
  components: {
    MessageItem,
    CommentItem,
  },
  props: {
    message: {
      type: Object,
      default: () => {},
    },
    mentionCount: {
      type: Number,
      default: 0,
    },
    parentName: {
      type: String,
      default: '',
    },
  },
  setup(props) {
    const store = useStore();
    const loadingPrev = ref(false);
    const loadingNext = ref(false);
    const loadingFinalNext = ref(false);
    const loadingReplies = ref(false);
    const { message } = toRefs(props);

    const getPrevious = async () => {
      loadingPrev.value = true;
      await store.dispatch('seemore/getPreviousLineage', message.value);
      loadingPrev.value = false;
    };

    const getNext = async () => {
      loadingNext.value = true;
      await store.dispatch('seemore/getNextLineage', message.value);
      loadingNext.value = false;
    };

    const getReply = async () => {
      loadingReplies.value = true;
      await store.dispatch('seemore/getRepliesLineage', message.value);
      loadingReplies.value = false;
    };

    const getNextFinal = async () => {
      loadingFinalNext.value = true;
      const { lastCommentId } = message.value.lineageControl;
      await store.dispatch('seemore/getFinalNextLineage', lastCommentId);
      loadingFinalNext.value = false;
    };

    const loadMoreClass = computed(() => {
      if (message.value && message.value.lineage) {
        if (message.value.lineage.level === 1) {
          return 'message-comment';
        } else if (message.value.lineage.level === 2) {
          return 'message-subcomment';
        }
      }
      return '';
    });

    const messageClass = computed(() => {
      if (message.value && message.value.lineage) {
        if (message.value.lineage.level === 1) {
          return 'message-comment';
        } else if (message.value.lineage.level === 2) {
          return 'message-subcomment';
        }
      }
      return '';
    });

    return {
      loadingPrev,
      loadingNext,
      loadingFinalNext,
      loadingReplies,
      getPrevious,
      getNext,
      getReply,
      getNextFinal,
      loadMoreClass,
      messageClass,
    };
  },
};
</script>

<style lang="scss" scoped>
.lineage-item {
  &.non-origin {
    padding-right: 24px;
  }
  // &.target-item-wrapper {
  // }
  .load-more-card {
    cursor: pointer;
    margin-bottom: 16px;
    margin-top: 8px;
    color: #2b8dfe;
    font-size: 12px;
  }
  .comment-count-bar {
    border-top: 1px solid #f1f2f6;
    padding: 20px 24px 10px 24px;
    color: #272b41;
    font-weight: 500;
    font-size: 16px;
  }
  .message-comment {
    margin-left: 24px;
  }
  .message-subcomment {
    margin-left: 70px;
  }
}
</style>
