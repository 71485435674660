<template>
  <a-spin :spinning="item.editing || false">
    <div
      class="comment-item"
      :class="{ 'reply-item': level > 1, 'target-item': item.isTarget }"
    >
      <div
        :id="`copy-${parentName}-${msgId}`"
        @click="
          helper.copyUrl(item.raw_data.redirect_url || item.raw_data.link)
        "
      ></div>
      <div class="profile">
        <div v-lazy-container="{ selector: 'img', error: onImageError }">
          <img
            :data-src="profileImage"
            class="user-img"
          />
        </div>
        <!--        <img v-if="profileImage" class="user-img" :src="profileImage" />-->
        <!--        <img v-else class="anonymous-img" :src="require('@/assets/images/icon/anonymous-profile.svg')" />-->
      </div>
      <div class="content">
        <div class="content-wrapper">
          <div class="left-block">
            <div class="title-row">
              <div class="username">
                {{ item.user.username }}
              </div>
              <div class="time">
                {{ formattedDate }}
              </div>
            </div>
            <div
              class="text-row"
              v-html="textContent"
            ></div>
            <EngagementBar
              class="engage-bar"
              :data="item"
              :dark="true"
            />
          </div>
          <div class="right-block">
            <div class="right-block-wrapper">
              <SentimentCircleIcon
                :data="item"
                :small="true"
              />
              <MessageItemMoreMenu
                :msg-id="msgId"
                :data="item"
                :editable="editable"
                :sub-category-name="subCategoryName"
                :parentName="parentName"
              >
                <FeatherIcon
                  class="more-menu"
                  type="more-horizontal"
                  size="24"
                />
              </MessageItemMoreMenu>
            </div>
          </div>
        </div>
        <CategoriesBar
          class="category-bar"
          :msg-id="msgId"
          :data="item"
          :editable="editable"
        />
      </div>
    </div>
  </a-spin>
</template>

<script>
import { toRefs, computed } from 'vue';
import { useStore } from 'vuex';
import helper from '@/services/helper';
import EngagementBar from './EngagementBar.vue';
import CategoriesBar from './CategoriesBar.vue';
import SentimentCircleIcon from './SentimentCircleIcon.vue';
import MessageItemMoreMenu from './MessageItemMoreMenu.vue';

export default {
  components: {
    EngagementBar,
    CategoriesBar,
    SentimentCircleIcon,
    MessageItemMoreMenu,
  },
  props: {
    level: {
      type: Number,
      default: 1,
    },
    item: {
      type: Object,
      default: () => {},
    },
    parentName: {
      type: String,
      default: '',
    },
  },
  setup(props) {
    const { item } = toRefs(props);

    const store = useStore();
    const editable = computed(
      () => store.getters['account/messageEditableRole'],
    );
    const msgId = computed(() => {
      if (item.value && item.value.info) {
        const { id } = item.value.info;
        return id;
      }
      return '';
    });
    const profileImage = computed(() => {
      if (item.value && item.value.user) {
        const { photo } = item.value.user;
        return photo;
      }
      return helper.getDefaultImageProfile(item.value.info.source);
    });
    // NO usable fallback image for comment right now
    // const fallbackImage = computed(() => {
    //   if (item.value && item.value.user) {
    //     const { fallback_photo } = item.value.user;
    //     return fallback_photo;
    //   }
    //   return '';
    // });
    // const source = computed(() => {
    //   if (item.value && item.value.info) {
    //     const { source } = item.value.info;
    //     return source;
    //   }
    //   return '';
    // });
    const onImageError = computed(() => {
      // if (source.value === 'facebook') {
      //   if (fallbackImage.value && profileImage.value !== fallbackImage.value) {
      //     return fallbackImage.value;
      //   } else {
      //     const img = helper.getDefaultImageProfile();
      //     return img;
      //   }
      // } else {
      const img = helper.getDefaultImageProfile(item.value.info.source);
      return img;
      // }
    });
    const formattedDate = computed(() => {
      if (item.value && item.value.info) {
        const { created_at } = item.value.info;
        return helper.timeFromNow(created_at);
      }
      return '...';
    });
    const textContent = computed(() => {
      let text = '';
      if (item.value && item.value.highlighted_text) {
        text = item.value.highlighted_text;
      } else if (
        item.value &&
        item.value.raw_data &&
        item.value.raw_data.text
      ) {
        text = item.value.raw_data.text;
      }
      return text;
    });

    return {
      editable,
      msgId,
      profileImage,
      onImageError,
      formattedDate,
      textContent,
      helper,
    };
  },
};
</script>

<style lang="scss" scoped>
.comment-item {
  display: flex;
  padding-left: 24px;
  &.reply-item {
    padding-left: 70px;
  }

  .profile {
    border-radius: 36px;
    overflow: hidden;
    width: 40px;
    height: 40px;
    min-width: 40px;
    min-height: 40px;
    margin-right: 15px;
    .user-img {
      width: 40px;
      height: 40px;
    }
    .anonymous-img {
      width: 40px;
      height: 40px;
    }
  }
  .content {
    flex: 1 1 auto;
    .content-wrapper {
      background: #eff0f3;
      border-radius: 8px;
      padding: 10px 10px 0 10px;
      display: flex;
      .engage-bar {
        margin: 0;
      }
      .title-row {
        display: flex;
        margin-bottom: 10px;
        align-items: baseline;
        .username {
          color: #272b41;
          font-size: 14px;
          font-weight: 500;
          margin-right: 10px;
        }
        .time {
          color: #adb4d2;
          font-size: 13px;
        }
      }
      .text-row {
        font-size: 14px;
        line-height: 19px;
        margin-bottom: 16px;
        color: #5a5f7d;
        overflow-wrap: anywhere;
      }
    }
    .category-bar {
      padding: 0;
      margin: 8px 0 16px 0;
    }
  }
  .left-block {
    flex: 1 1 auto;
  }
  .right-block {
    flex: 0 0 auto;
    .right-block-wrapper {
      display: flex;
      align-items: center;
    }
    .more-menu {
      margin-left: 8px;
      color: #adb4d2;
    }
  }
  &.target-item {
    .content-wrapper {
      background: #faeacc;
    }
  }
}
</style>

<style scoped>
:deep(img.img-in-post) {
  max-width: 50%;
}
</style>
