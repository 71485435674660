
import { computed } from 'vue';
import { useStore } from 'vuex';
// import helper from '@/services/helper';

import ExportTreeSelect from '@/components/TreeSelect/ExportTreeSelect.vue';
import helper from '../../../services/helper';
import api from '@/services/api';

export default {
  components: {
    ExportTreeSelect,
  },
  emits: ['onChangeSource'],
  props: {
    selected: { type: Object },
  },
  setup(props, { emit }) {
    const store = useStore();
    const sourceTreeData = computed(
      () => store.getters['account/sourceTreeData']
    );

    const onSourceSelect = (sList: string[]) => {
      let result: Set<string> = new Set();
      let sourceList: string[] = [...sList].map((v: string) =>
        v === 'website::website' ? 'website' : v
      );

      const websiteCount: number = sourceList.filter((v: string) =>
        v.startsWith('website::')
      ).length;

      const isSelectAllWebsite: boolean =
        (sourceList.includes('website') && websiteCount === 0) ||
        websiteCount === sourceTreeData.value.defaultState.Website.length;

      if (isSelectAllWebsite) {
        sourceList = sourceList.concat(
          sourceTreeData.value.defaultState.Website
        );
      }

      sourceList = Array.from(new Set(sourceList));

      for (const source of sourceList) {
        if (source) {
          const splitted = source.split('::');
          if (splitted && splitted[1]) {
            result.add(splitted[1]);
          } else if (splitted && splitted[0]) {
            result.add(splitted[0]);
          }
        }
      }
      if (isSelectAllWebsite) {
        result.delete('website');
      }

      emit('onChangeSource', Array.from(result));
    };

    const sourceTreeDataComputed = computed(() => {
      if (sourceTreeData.value && sourceTreeData.value.tree) {
        const result = [];
        for (let data of sourceTreeData.value.tree) {
          if (data.id !== 'allChannels') {
            let obj = {
              title: '',
              key: '',
              children: [],
              icon: '',
              // slots: {
              //   title: 'title',
              // },
            };
            obj.title = helper.capitalize(data.name);
            // obj.value = data.children && data.children.length ? '' : data.name;
            // obj.key = data.children && data.children.length ? '' : data.name.toLowerCase();
            obj.icon = data.name;

            // obj.value = data.name;
            obj.key = data.name.toLowerCase();
            obj.value = data.name.toLowerCase();
            obj.hasChild = data.children && data.children.length > 0;

            if (data.children && data.children.length) {
              for (let child of data.children) {
                obj.children.push({
                  title: child.label,
                  value: child.value,
                  key: child.value,
                });
              }
            }

            result.push(obj);
          }
        }
        return result;
      }
      return [];
    });

    const sourceTreeSelected = computed(() => {
      if (selectedValue.value?.length > 0) {
        const socialSources = api.getSocialSource();

        let sources = [];
        for (const source of selectedValue.value) {
          if (!socialSources.includes(source)) {
            sources.push(`website::${source}`);
          } else {
            sources.push(source);
          }
        }
        return sources;
      } else if (sourceTreeData.value && sourceTreeData.value.tree) {
        const result = [];
        for (let data of sourceTreeData.value.tree) {
          if (data.id === 'allChannels') continue;

          if (data.children && data.children.length) {
            for (let child of data.children) {
              result.push(child.value);
            }
          } else {
            result.push(data.name);
          }
        }
        return result;
      }
      return [];
    });

    const selectedValue = computed(() => props.selected);

    return {
      sourceTreeData,
      sourceTreeDataComputed,
      sourceTreeSelected,
      onSourceSelect,
    };
  },
};
