<template>
  <div class="overview-page">
    <CustomPageHeaderVue
      title="Overview"
      :hasSubtitle="false"
      :has-advance-search="true"
      :has-filter-preset="true"
    ></CustomPageHeaderVue>
    <div
      class="page-content"
      @scroll="onScroll"
    >
      <MessageAlertNewSetup></MessageAlertNewSetup>
      <div class="page-tab-column">
        <MenuTab
          :tab-lists="tabMenuList"
          :tab-key="activeKey"
          @setActiveKey="setActiveKey"
        ></MenuTab>
        <div
          v-if="isMobileScreen"
          class="mobile-title"
        >
          <h5
            v-if="activeKey === '1'"
            class="page-title"
          >
            Overview
          </h5>
          <h5
            v-else-if="activeKey === '2'"
            class="page-title"
          >
            Channel Performance
          </h5>
          <h5
            v-else-if="activeKey === '3'"
            class="page-title"
          >
            Topic/Trending
          </h5>
        </div>
        <div
          v-if="activeKey !== '1'"
          class="filter-div"
        >
          <div
            v-if="!isMobileScreen"
            class="social-movement-filter-mode"
          >
            <span class="social-movement-data-aspect">Measurement: </span>
            <a-radio-group
              v-model:value="selectedDataAspect"
              button-style="solid"
              size="default"
            >
              <a-radio-button
                v-for="mode in modeOptions"
                :key="mode.value"
                :value="mode.value"
                style="height: 34px"
              >
                {{ mode.label }}
              </a-radio-button>
            </a-radio-group>
          </div>
          <div
            v-else
            class="mobile-social-movement-filter-mode"
          >
            <a-dropdown
              placement="bottomRight"
              :getPopupContainer="(trigger) => trigger.parentNode"
            >
              <img :src="require('@/assets/images/icon/icon-sliders.svg')" />
              <template #overlay>
                <a-menu
                  id="mode-menu-mobile"
                  @click="handleModeClick"
                >
                  <a-menu-item-group title="Measurement:">
                    <a-menu-item
                      v-for="mode in modeOptions"
                      :key="mode.value"
                      :style="
                        selectedDataAspect === mode.value
                          ? selectedModeStyle
                          : unSelectedModeStyle
                      "
                    >
                      {{ mode.label }}
                    </a-menu-item>
                  </a-menu-item-group>
                </a-menu>
              </template>
            </a-dropdown>
          </div>
        </div>
      </div>
      <OverviewSection v-if="activeKey === '1'"></OverviewSection>
      <ChannelPerformanceSection
        v-else-if="activeKey === '2'"
        :mode="selectedDataAspect"
      ></ChannelPerformanceSection>
      <TopicTrendingSection
        v-if="activeKey === '3'"
        :mode="selectedDataAspect"
      ></TopicTrendingSection>
    </div>
  </div>
</template>

<script>
import { useStore } from 'vuex';
import CustomPageHeaderVue from '../../components/Layout/CustomPageHeader.vue';
import MenuTab from '../../components/Tab/MenuTab.vue';
import OverviewSection from './Overview/OverviewSection.vue';
import TopicTrendingSection from './TopicTrending/TopicTrendingSection.vue';
import ChannelPerformanceSection from './ChannelPerformance/ChannelPerformanceSection.vue';
import MessageAlertNewSetup from '@/components/Message/MessageAlertNewSetup.vue';

import { ref, computed, watch } from 'vue';

export default {
  name: 'Overview',
  components: {
    CustomPageHeaderVue,
    MenuTab,
    OverviewSection,
    TopicTrendingSection,
    ChannelPerformanceSection,
    MessageAlertNewSetup,
  },
  setup() {
    const store = useStore();
    const isMobileScreen = computed(() => store.state.isMobileScreen);
    const activeKey = ref('1');

    const tabMenuList = [
      {
        label: 'Overview',
        value: '1',
      },
      {
        label: 'Channel Performance',
        value: '2',
      },
      {
        label: 'Topic/Trending',
        value: '3',
      },
    ];

    let mode = ref('engagement'); //engagement,mention,view
    let selectedDataAspect = ref('engagement');

    const modeOptions = [
      { label: 'Engagement', value: 'engagement' },
      { label: 'Mentioned', value: 'mention' },
      { label: 'View', value: 'view' },
    ];

    const setActiveKey = (value) => {
      activeKey.value = value;
    };

    const handleModeClick = async (e) => {
      selectedDataAspect.value = e.key;
    };

    const checkActiveKey = computed(() => {
      if (activeKey.value === '2') {
        return false;
      } else {
        return true;
      }
    });

    // hide popover when scrolling
    const onScroll = () => {
      const scrollElement = document.getElementsByClassName('ant-popover');

      if (scrollElement) {
        for (let ele of scrollElement) {
          ele.style.display = 'none';
        }
      }
    };

    const selectedModeStyle = {
      color: ' #097cff',
      background: 'rgba(9, 124, 255, 0.05)',
    };

    const unSelectedModeStyle = {
      color: '#5A5F7D',
    };

    watch(
      () => activeKey.value,
      () => {
        store.dispatch('filter/toggleSource', checkActiveKey.value);
      }
    );

    return {
      activeKey,
      setActiveKey,
      tabMenuList,
      onScroll,
      modeOptions,
      mode,
      selectedDataAspect,
      handleModeClick,
      isMobileScreen,
      selectedModeStyle,
      unSelectedModeStyle,
    };
  },
};
</script>

<style lang="scss" scoped>
.overview-page {
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;
  .page-content {
    overflow: auto;
    padding: 20px 30px;
    // min-height: 715px;
    background-color: transparent;
    // background-color: rgb(244, 245, 247);
    .page-tab-column {
      justify-content: space-between;
      align-items: center;
      flex-wrap: wrap;
      row-gap: 8px;
      padding-bottom: 35px;
      .mobile-title {
        margin-top: 23px;
        .page-title {
          font-size: 18px;
        }
      }
      .filter-div {
        margin-top: -45px;
        @media (max-width: 1045px) {
          margin-top: 25px;
        }
        .social-movement-filter-mode {
          display: flex;
          justify-content: flex-end;
          align-items: center;

          .social-movement-data-aspect {
            margin-right: 14px;
            font-size: 14px;
            color: #5a5f7d;
            @media screen and (min-width: 1046px) and (max-width: 1160px) {
              display: none;
            }
          }
        }
      }

      @media screen and (max-width: 767px) {
        .filter-div {
          margin-left: auto;
          .social-movement-filter-mode {
            justify-content: flex-end;
            text-align: right;
          }
        }
      }
    }
    @media only screen and (max-width: 1199px) {
      padding: 20px 15px;
    }
    @media only screen and (max-width: 991px) {
      min-height: 580px;
    }
  }
}
</style>
