<template>
  <div class="wrapper">
    <div>
      <div class="title">Total</div>
      <div class="value-wrapper">
        <div class="value">{{ formattedTotal }}</div>
      </div>
    </div>
    <div class="margin-top">
      <div class="title">Reaction</div>
      <div class="value-wrapper">
        <div class="value">{{ formattedReaction }}</div>
        <!-- <div
          class="percent-content"
          v-bind:class="[
            'percent-content',
            reactionPercentChange >= 0 ? 'percent-content-positive' : 'percent-content-negative',
          ]"
        >
          <font-awesome-icon :icon="reactionPercentChange >= 0 ? faArrowUp : faArrowDown" />
          {{ formattedReactionPercentChange }}
        </div> -->
      </div>
    </div>
    <div class="margin-top">
      <div class="title">Comments</div>
      <div class="value-wrapper">
        <div class="value">{{ formattedComments }}</div>
        <!-- <div
          class="percent-content"
          v-bind:class="[
            'percent-content',
            commentsPercentChange >= 0 ? 'percent-content-positive' : 'percent-content-negative',
          ]"
        >
          <font-awesome-icon :icon="commentsPercentChange >= 0 ? faArrowUp : faArrowDown" />
          {{ formattedCommentsPercentChange }}
        </div> -->
      </div>
    </div>
    <div class="margin-top">
      <div class="title">Share</div>
      <div class="value-wrapper">
        <div class="value">{{ formattedShare }}</div>
        <!-- <div
          class="percent-content"
          v-bind:class="[
            'percent-content',
            sharePercentChange >= 0 ? 'percent-content-positive' : 'percent-content-negative',
          ]"
        >
          <font-awesome-icon :icon="sharePercentChange >= 0 ? faArrowUp : faArrowDown" />
          {{ formattedSharePercentChange }}
        </div> -->
      </div>
    </div>
  </div>
</template>
<script>
import numeral from 'numeral';
import VueTypes from 'vue-types';
import { toRefs, computed } from 'vue';
import { faArrowDown, faArrowUp } from '@fortawesome/free-solid-svg-icons';

export const EngagementDetail = {
  name: 'EngagementDetail',
  props: {
    total: Number,
    reaction: VueTypes.number.def(0),
    reactionPercentChange: VueTypes.number.def(0.0),
    comments: VueTypes.number.def(0),
    commentsPercentChange: VueTypes.number.def(0.0),
    share: VueTypes.number.def(0),
    sharePercentChange: VueTypes.number.def(0.0),
  },
  setup(props) {
    let {
      total,
      reaction,
      comments,
      share,
      reactionPercentChange,
      commentsPercentChange,
      sharePercentChange,
    } = toRefs(props);
    const formattedReactionPercentChange = computed(() => {
      return numeral(reactionPercentChange.value / 100)
        .format('0a %')
        .replace('-', '');
    });
    const formattedCommentsPercentChange = computed(() => {
      return numeral(commentsPercentChange.value / 100)
        .format('0a %')
        .replace('-', '');
    });
    const formattedSharePercentChange = computed(() => {
      return numeral(sharePercentChange.value / 100)
        .format('0a %')
        .replace('-', '');
    });
    const formattedReaction = computed(() => {
      return numeral(reaction.value).format('0,0');
    });
    const formattedComments = computed(() => {
      return numeral(comments.value).format('0,0');
    });
    const formattedShare = computed(() => {
      return numeral(share.value).format('0,0');
    });
    const formattedTotal = computed(() => {
      return numeral(total.value).format('0,0');
    });
    return {
      faArrowUp,
      faArrowDown,
      reaction,
      comments,
      share,
      reactionPercentChange,
      commentsPercentChange,
      sharePercentChange,
      formattedReactionPercentChange,
      formattedCommentsPercentChange,
      formattedSharePercentChange,
      formattedReaction,
      formattedComments,
      formattedShare,
      formattedTotal,
    };
  },
};
export default EngagementDetail;
</script>
<style scoped>
.title {
  font-family: DM Sans;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 16px;
  color: #9299b8;
}

.value {
  font-family: DM Sans;
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  line-height: 21px;
  color: #272b41;
}

.margin-top {
  margin-top: 10px;
}

.wrapper {
  min-width: 143px;
}

.value-wrapper {
  display: flex;
}

.percent-content {
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  margin-left: 25px;
  margin-right: 3px;
}

.percent-content-positive {
  color: #5bbe94;
}

.percent-content-negative {
  color: #ff4d4f;
}
</style>
