<template>
  <a-modal
    :zIndex="10000"
    title="Details"
    centered
    :width="520"
    :footer="null"
    :maskClosable="false"
    class="advance-search-operator"
  >
    <div>
      <div>
        <div>
          <FeatherIcon
            size="16"
            type="users"
            color="#3371FF"
          ></FeatherIcon
          ><span class="ml-2 gray-800-color fw-500"
            >What is Social Media Account Group?</span
          >
        </div>
        <div class="gray-600-color">
          Social media account group คือ การจับกลุ่ม social account ของ channel
          ต่างๆ ตามมุมมองที่ผู้ใช้งานสนใจ เช่น Group List ของ Negative
          Influencer, Owned media, Partner เป็นต้น
          ช่วยให้ผู้ใช้งานสามารถเจาะจงกลุ่มที่ต้องการวิเคราะห์ได้
        </div>
        <div
          v-if="isAdmin"
          class="divider"
        ></div>
      </div>
      <div
        v-if="isAdmin"
        class="how-to-setting"
      >
        <div>
          <FeatherIcon
            size="16"
            type="help-circle"
            color="#3371FF"
          ></FeatherIcon
          ><span class="ml-2 gray-800-color fw-500">How to Setting?</span>
        </div>
        <div class="gray-600-color mt-2 mb-2">
          Admin สามารถสร้าง group ของ social account ได้ตามขั้นตอนดังต่อไปนี้
          <div class="mt-2">
            1. ไปยังเมนู DOM Setting > Dashboard<br />
            2. เลือกแท็บ Social Media Account Group<br />
            3. กดปุ่ม Create New Group จากนั้นกำหนดชื่อ group และเพิ่ม social
            media account ที่ต้องการ<br />
          </div>
        </div>
        <a
          href="/settings"
          target="_blank"
          class="primary-color"
          >Go to Social Account Group Setting
          <FeatherIcon
            type="external-link"
            size="14"
        /></a>
      </div>
    </div>
  </a-modal>
</template>

<script>
import { computed } from 'vue';
import { useStore } from 'vuex';

export default {
  name: 'SocialMediaAccountGroupDetailModal',
  setup() {
    const store = useStore();
    const role = computed(() => store.getters['account/userRole']);
    const isAdmin = computed(() => ['admin', 'domadmin'].includes(role.value));
    return {
      role,
      isAdmin,
    };
  },
};
</script>

<style lang="scss" scoped>
@import '../../../config/theme/colors.json';

.primary-color {
  color: $primary-color;
}
.gray-800-color {
  color: $gray-800;
}
.gray-600-color {
  color: $gray-600;
}
.divider {
  height: 32px;
}
</style>
