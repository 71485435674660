<template>
  <div class="mobile-table">
    <table class="table">
      <tbody>
        <template
          v-for="(item, index) in lists"
          :key="item.id"
        >
          <tr
            :class="{ expand: expandRowId === index }"
            :rowspan="expandRowId === index ? 2 : 1"
            class="item-row"
          >
            <td
              v-if="index <= 2"
              class="top-index text-center"
              @click="showMessageModal(item.id, item.info.user_name)"
            >
              <img
                v-if="index === 0"
                :src="require('@/assets/images/icon/01-medal-gold.svg')"
              />
              <img
                v-if="index === 1"
                :src="require('@/assets/images/icon/02-medal-silver.svg')"
              />
              <img
                v-if="index === 2"
                :src="require('@/assets/images/icon/03-medal-bronze.svg')"
              />
            </td>
            <td
              v-else
              class="text-center index-td"
              @click="showMessageModal(item.id, item.info.user_name)"
            >
              {{ index + 1 }}
            </td>
            <td
              class="top info-td"
              @click="showMessageModal(item.id, item.info.user_name)"
            >
              <div class="d-flex">
                <div class="user-photo-wrap">
                  <img
                    class="user-photo"
                    :src="item.newProfileImage"
                    @error="onImageError($event, item)"
                  />
                  <SourceTag
                    :source="item.source"
                    :showLabel="false"
                  ></SourceTag>
                </div>
                <div class="user-info">
                  <div class="username">
                    {{ item.info.user_name }}
                  </div>
                  <div class="account">
                    {{ '@' + item.info.user_short_id }}
                  </div>
                </div>
              </div>
            </td>
            <td
              class="text-center engagement-td top"
              @click="showMessageModal(item.id, item.info.user_name)"
            >
              <div class="unit-text">
                <span v-if="sortBy === 'engagement'">Engagement</span>
                <span v-else-if="sortBy === 'count'">Mentions</span>
              </div>
              <div class="value-text blue-text">
                <span v-if="sortBy === 'engagement'">
                  {{ item.totalEngage }}
                </span>
                <span v-else-if="sortBy === 'count'">
                  {{ item.totalMention }}
                </span>
              </div>
            </td>
          </tr>
        </template>
      </tbody>
    </table>
  </div>
  <!-- <SeeMoreDialog /> -->
</template>

<script>
import { ref, reactive, provide, toRefs, computed } from 'vue';
import {
  faFacebookF,
  faYoutube,
  faTwitter,
  faInstagram,
} from '@fortawesome/free-brands-svg-icons';
import { useStore } from 'vuex';
import { DataSet } from 'vis-data';
import { Network } from 'vis-network';
import helper from '@/services/helper';
import api from '@/services/api';
// import SeeMoreDialog from '@/components/Seemore/SeeMoreDialog.vue';
import SourceTag from '@/components/SourceTag.vue';
const networkGraphOption = {
  autoResize: true,
  height: '500px',
  width: '100%',
  interaction: {
    hover: true,
    navigationButtons: true,
    keyboard: {
      enabled: true,
      bindToWindow: false,
    },
  },
  edges: {
    smooth: {
      forceDirection: 'none',
      roundness: 0.4,
    },
    scaling: {
      min: 2,
      max: 10,
    },
  },
  nodes: {
    color: {
      highlight: {
        border: '#ff4d4d',
        background: '#ff4d4d',
      },
      hover: {
        border: '#99ffbb',
        background: '#99ffbb',
      },
    },
    scaling: {
      min: 25,
      max: 35,
      label: {
        min: 15,
        max: 18,
      },
    },
  },
  physics: {
    enabled: true,
    forceAtlas2Based: {
      gravitationalConstant: -50,
      centralGravity: 0.01,
      springConstant: 0.1,
      springLength: 80,
      damping: 0.5,
      avoidOverlap: 0.8,
    },
    minVelocity: 2,
  },
};
export default {
  name: 'InfluencerOverview',
  components: {
    // SeeMoreDialog,
    SourceTag,
  },
  props: {
    influencerDataList: Array,
    filterResult: Object,
    sortBy: String,
  },
  setup(props) {
    const store = useStore();
    const { filterResult, influencerDataList: lists } = toRefs(props);
    const isMobileScreen = computed(() => store.state.isMobileScreen);
    const limitCount = ref(10);
    const error = ref(false);
    const isNetworkModalOpen = ref(false);
    const infGraph = ref();
    const expandRowId = ref('');
    const offsetTypeWidth = computed(() => {
      const typeDiv = document.getElementById('InfluencerType');
      if (typeDiv) {
        return typeDiv.offsetWidth;
      } else {
        return 0;
      }
    });
    const toggleExpandRow = (id) => {
      if (expandRowId.value === id) {
        expandRowId.value = '';
      } else {
        expandRowId.value = id;
      }
    };
    const networkData = reactive({
      nodes: new DataSet(),
      edges: new DataSet(),
      loading: false,
      title: '',
      allParent: [],
      networkParent: null,
      edgeLimit: 50,
    });

    const showMessageModal = (userId, username) => {
      let f = {
        payload: {
          title: `Messages from "${username}"`,
        },
        criteria: {
          ...filterResult.value,
          userId,
          sort: {
            direction: 'desc',
            type: 'engagement_score',
          },
          highlight: {
            enable: true,
          },
        },
        type: 'influencer',
      };
      store.dispatch('message/showMessageModal', f);
    };

    const clickMenu = (id) => {
      const scrollElement = document.getElementById(id);
      // const scrollElement = document.getElementsByClassName('user-influencer-menu');
      if (scrollElement) {
        scrollElement.style.display = 'block';
      }
    };

    const htmlTitle = (html) => {
      const container = document.createElement('div');
      container.classList.add('influencer-tooltip');
      container.innerHTML = html;
      return container;
    };

    const setParent = (parent) => {
      networkData.title = `Network graph of "${parent.info.user_name || '-'}"`;
      let actions = parent.actions;
      let reactions = [];
      Object.keys(parent.engagement).forEach((engageKey) => {
        reactions.push({
          key: engageKey,
          value: parent.engagement[engageKey],
        });
      });

      let title =
        '' +
        '<div class="name">' +
        parent.username +
        '</div> <hr>' +
        '<div class="graph-info"><u> Influencer Score </u></div>' +
        '<div class="graph-info"> Influencer Score: &emsp;' +
        parent.node_score +
        '</div><hr>' +
        '<div align="center"><table><tr><td valign="top">' +
        '<div class="graph-info"><u> Actions </u></div>';
      for (let k in actions) {
        title +=
          '<div class="graph-info">  <span>' +
          actions[k].key +
          '</span>&emsp;: &emsp;' +
          actions[k].value +
          '</div>';
      }
      title +=
        '</td><td valign="top"><div class="graph-info"><u> Reactions </u></div>';
      for (let l in reactions) {
        title +=
          '<div class="graph-info">  <span>' +
          reactions[l].key +
          '</span>&emsp;: &emsp;' +
          reactions[l].value +
          '</div>';
      }
      title += '</td></tr></table></div>';
      console.log(parent);

      const img = parent.img || parent.newProfileImage;
      networkData.nodes.update({
        id: parent.id,
        color: '#3d3',
        label: parent.username,
        shape: 'circularImage',
        image: img,
        title: htmlTitle(title),
        value: 2,
      });
    };
    const setChild = async (parent) => {
      const networkArg = {
        ...filterResult.value,
        nodeLimit: 50,
        edgeLimit: networkData.edgeLimit,
      };
      let parentID = parent.id;
      const result = await api
        .getInfluencerChildNode(true, parentID, networkArg)
        .catch(() => {
          console.log('ERRR');
        });
      let childlist = result.message;
      let arr = [];
      for (let i in childlist.children) {
        let id = childlist.children[i].id;
        let actions = childlist.children[i].actions;
        let reactions = childlist.children[i].reactions;
        let title =
          '' +
          '<div class="influencer-tooltip">' +
          '<div class="name">' +
          childlist.peopleInfo[id].user_name +
          '</div> <hr>' +
          '<div class="graph-info"><u> Influencer Score </u></div>' +
          '<div class="graph-info"> Influencer Score: &emsp;' +
          childlist.children[i].node_score +
          '</div><hr>' +
          '<div align="center"><table><tr><td valign="top">' +
          '<div class="graph-info"><u> Actions </u></div>';
        for (let k in actions) {
          title +=
            '<div class="graph-info">  <span>' +
            actions[k].key +
            '</span>&emsp;: &emsp;' +
            actions[k].value +
            '</div>';
        }
        title +=
          '</td><td valign="top"><div class="graph-info"><u> Reactions </u></div>';
        for (let l in reactions) {
          title +=
            '<div class="graph-info">  <span>' +
            reactions[l].key +
            '</span>&emsp;: &emsp;' +
            reactions[l].value +
            '</div>';
        }
        title += '</td></tr></table></div></div>';
        try {
          arr.push({
            id: childlist.children[i].id,
            shape: 'circularImage',
            image: childlist.peopleInfo[id].user_photo,
            label: childlist.peopleInfo[id].user_name,
            title: htmlTitle(title),
            value: 1,
          });
        } catch (e) {
          console.error('Cannot get info', id, e);
        }
      }
      networkData.nodes.update(arr);
      for (let j in childlist.relationship) {
        let node = false;
        let from = childlist.relationship[j].from;
        node = networkData.nodes.get(from);
        if (node) {
          from = node.label;
        }
        node = false;
        let to = childlist.relationship[j].to;
        node = networkData.nodes.get(to);
        if (node) {
          to = node.label;
        }
        childlist.relationship[j].arrows = 'to';
        let title =
          '' +
          '<div class="influencer-tooltip">' +
          '<div class="name">' +
          from +
          '  &#8594;  ' +
          to +
          '</div><hr>' +
          '<div align="center"><table><tr><td valign="top">' +
          '<div class="graph-info"><u> Sentiment Info </u></div>';
        for (let k in childlist.relationship[j].sentiment_count) {
          title +=
            '<div class="graph-info">  <span>' +
            childlist.relationship[j].sentiment_count[k].key +
            '</span>&emsp;: &emsp;' +
            childlist.relationship[j].sentiment_count[k].value +
            '</div>';
        }
        title +=
          '</td><td valign="top"><div class="graph-info"><u> Actions </u></div>';
        for (let l in childlist.relationship[j].actions) {
          title +=
            '<div class="graph-info">  <span>' +
            childlist.relationship[j].actions[l].key +
            '</span>&emsp;: &emsp;' +
            childlist.relationship[j].actions[l].value +
            '</div>';
        }
        title += '</td></tr></table></div></div>';
        childlist.relationship[j].title = htmlTitle(title);
        childlist.relationship[j].value = childlist.relationship[j].edge_score;
        childlist.relationship[j].color = {
          color: '#69a3c5',
          hover: '#55F6F0',
          highlight: '#3CEE40',
          opacity: 0.9,
        };
        childlist.relationship[j].id =
          childlist.relationship[j].from + '' + childlist.relationship[j].to;
      }
      networkData.edges.update(childlist.relationship);
      networkData.loading = false;
    };

    const showNetworkGraph = async (parent) => {
      isNetworkModalOpen.value = true;
      networkData.loading = true;
      networkData.allParent = [];
      networkData.networkParent = parent;
      networkData.nodes.clear();
      networkData.edges.clear();
      setParent(parent);
      await setChild(parent);
      // console.log(infGraph.value, networkData.nodes, networkData.edges, Network, networkGraphOption);

      new Network(
        infGraph.value,
        { nodes: networkData.nodes, edges: networkData.edges },
        networkGraphOption,
      );
    };
    const onNetworkModalCancel = () => {
      console.log('OHH');
    };

    const openUserLink = (item) => {
      if (item && item.info && item.info.user_link) {
        helper.openUrl(item.info.user_link);
      }
    };

    const copyUserLink = (item) => {
      if (item && item.info && item.info.user_link) {
        helper.copyUrl(item.info.user_link);
      }
    };

    const onImageError = (e, value) => {
      if (value.source === 'facebook') {
        if (value.fallbackImage && e.target.src !== value.fallbackImage) {
          e.target.src = value.fallbackImage;
        } else {
          const img = helper.getDefaultImageProfile(value.source);
          e.target.src = img;
        }
      } else {
        const img = helper.getDefaultImageProfile(value.source);
        e.target.src = img;
      }
    };

    provide('showMode', '');

    const onScroll = () => {
      const scrollElement = document.getElementsByClassName(
        'user-influencer-menu',
      );
      if (scrollElement) {
        for (let ele of scrollElement) {
          // ele.remove();
          ele.style.display = 'none';
        }
      }
    };

    const checkStr = (str) => {
      return str.length >= 10;
    };

    return {
      error,
      clickMenu,
      openUserLink,
      onNetworkModalCancel,
      copyUserLink,
      showNetworkGraph,
      onImageError,
      showMessageModal,
      onScroll,
      toggleExpandRow,
      expandRowId,
      limitCount,
      lists,
      faFacebookF,
      faYoutube,
      faTwitter,
      faInstagram,
      isMobileScreen,
      offsetTypeWidth,
      checkStr,
    };
  },
};
</script>

<style lang="scss" scoped>
.list-border {
  border-bottom: 1px solid #f1f2f6;
}
.action-list {
  padding-top: 12px;
  padding-bottom: 4px;
  .title {
    font-size: 14px;
    color: #5a5f7d;
    font-weight: 500;
    text-align: left;
    margin-bottom: 8px;
    @media screen and (max-width: 356px) {
      font-size: 12px;
    }
  }
  .action-row {
    display: flex;
    justify-content: space-between;
    line-height: 16px;
    margin-bottom: 8px;
    .action-title {
      color: #9299b8;
      font-size: 12px;
      display: flex;
      align-items: center;
      @media screen and (max-width: 356px) {
        font-size: 10px !important;
      }
      i {
        margin-right: 5px;
      }
    }
    .action-value {
      color: #5a5f7d;
      @media screen and (max-width: 356px) {
        font-size: 11px;
      }
    }
  }
}

.total-list {
  border: 1px solid #f1f2f6;
  padding: 16px;
  background-color: #f8f9fb;
  display: flex;
  justify-content: space-around;
  margin-top: 20px;
  margin-bottom: 16px;
  border-radius: 5px;
  .total-block {
    text-align: center;
    .value-row {
      font-size: 16px;
      font-weight: 500;
      color: #272b41;
      margin-bottom: 6px;
    }
    .unit-row {
      font-size: 13px;
      color: #9299b8;
      text-transform: capitalize;
    }
  }
}

.item-table {
  width: 100%;
  overflow: auto;
  display: block;
  @media screen and (max-width: 750px) {
    display: none;
  }
  .table {
    border-collapse: separate;
    border-spacing: 0 16px;
    width: 100%;
    td:first-child {
      border-right: none;
      border-top-left-radius: 10px;
      border-bottom-left-radius: 10px;
    }
    td:last-child {
      border-top-right-radius: 10px;
      border-bottom-right-radius: 10px;
    }
  }
  .item-row {
    cursor: pointer;
    td {
      padding: 20px 18px;
      background-color: #fff;
      color: #5a5f7d;
      vertical-align: top;
      border-right: 1px solid #f1f2f6;
      @media screen and (max-width: 1130px) {
        padding: 15px 14px;
      }
      @media screen and (max-width: 1030px) {
        padding: 10px 9px;
        border-right: none;
      }

      &.border-none {
        border-right: none;
      }
      &.middle {
        vertical-align: middle;
      }
      &.top-index {
        width: 36px;
        height: 36px;
        margin-left: 25px;
        img {
          width: 36px;
          height: 36px;
        }
      }
    }
    .user-photo-wrap {
      position: relative;
      user-select: none;
      text-align: center;
      width: 64px;
      height: 64px;
      @media screen and (max-width: 1130px) {
        width: 54px;
        height: 54px;
      }
      @media screen and (max-width: 1060px) {
        width: 44px;
        height: 44px;
      }
      .user-photo {
        width: 64px;
        height: 64px;
        border-radius: 50px;
        @media screen and (max-width: 1130px) {
          width: 54px;
          height: 54px;
        }
        @media screen and (max-width: 1060px) {
          width: 44px;
          height: 44px;
        }
      }
      .source-tag {
        position: absolute;
        top: auto;
        left: auto;
        bottom: 0;
        right: 0;
        .logo {
          height: 16px;
          width: 16px;
          border-radius: 12px;
          color: #fff;
          margin-right: 4px;
          line-height: 14px;
          overflow: hidden;
          display: inline-flex;
          justify-content: center;
          align-items: center;
          background-color: #252525;
        }
        .inner {
          display: flex;
        }
        &.facebook {
          .logo {
            background-color: rgba(24, 119, 242, 1);
            svg {
              height: 10px;
            }
          }
        }
        &.twitter {
          .logo {
            background-color: rgb(34, 164, 240);
            svg {
              height: 10px;
            }
          }
        }
        &.twitterv2 {
          .logo {
            background-color: rgb(34, 164, 240);
            svg {
              height: 10px;
            }
          }
        }
        &.pantip {
          .logo {
            background-color: rgba(118, 116, 188, 1);
            svg {
              height: 12px;
            }
          }
        }
        &.youtube {
          .logo {
            background-color: #ff0000;
            svg {
              height: 10px;
            }
          }
        }
        &.instagram {
          .logo {
            background-color: rgba(228, 64, 95, 1);
            svg {
              height: 10px;
            }
          }
        }
        &.blockdit {
          .logo {
            background-color: #4a69ff;
            .blockdit {
              display: flex;
              align-items: center;
            }
          }
        }
      }
    }
    .user-info {
      margin-left: 16px;
      max-width: 200px;
      @media screen and (max-width: 1130px) {
        max-width: 150px;
      }
      .username {
        line-height: 18px;
        color: #272b41;
        font-weight: 500;
        font-size: 14px;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
      }
      .account {
        margin-bottom: 12px;
        margin-top: 4px;
        line-height: 16px;
        color: #9299b8;
        font-weight: 400;
        font-size: 13px;
      }
    }
    .type-row {
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
      display: flex;
      align-items: center;
      flex-wrap: nowrap;
      width: fit-content;
      margin: 0 auto;
      text-align: center;
      justify-content: left;
      column-gap: 8px;
      row-gap: 8px;
      @media screen and (max-width: 1585px) {
        flex-wrap: wrap;
      }
      .type {
        border-radius: 4px;
        height: 20px;
        line-height: 12px;
        font-size: 10px;
        font-weight: 400;
        text-transform: capitalize;
        padding: 4px 8px;
        white-space: nowrap;
        color: #5a5f7d;
        // display: inline-block;
        display: flex;
        align-items: center;
        background-color: #ebf1ff;
      }
    }

    .type-row-empty {
      height: 20px;
    }

    .unit-text {
      font-size: 13px;
      line-height: 16px;
      font-weight: 500;
      color: #272b41;
      margin-bottom: 8px;
      @media screen and (max-width: 1060px) {
        font-size: 11px;
        line-height: 14px;
      }
      @media screen and (max-width: 991px) {
        font-size: 13px;
        line-height: 16px;
      }
      @media screen and (max-width: 830px) {
        font-size: 11px;
        line-height: 14px;
      }
    }

    .value-text {
      font-size: 16px;
      line-height: 20px;
      font-weight: 500;
      @media screen and (max-width: 1060px) {
        font-size: 14px;
        line-height: 18px;
      }
      @media screen and (max-width: 1030px) {
        font-size: 12px;
        line-height: 16px;
      }
      @media screen and (max-width: 991px) {
        font-size: 14px;
        line-height: 18px;
      }
    }

    .engagement-row {
      display: flex;
      align-items: center;
      margin: 16px 0 0;
      line-height: 16px;
      color: #5a5f7d;
      font-weight: 400;
      font-size: 12px;
      @media screen and (max-width: 1280px) {
        display: block;
      }
      @media screen and (max-width: 1030px) {
        font-size: 10px;
        line-height: 14px;
      }
      @media screen and (max-width: 991px) {
        font-size: 12px;
        line-height: 16px;
      }
      .engagement {
        display: flex;
        vertical-align: middle;
        align-items: center;
        .engagement-icon {
          padding-right: 8px;
        }
        @media screen and (max-width: 1280px) {
          margin: 8px 0;
        }
      }
      .first {
        margin-right: 24px;
        @media screen and (max-width: 1370px) {
          margin-right: 12px;
        }
      }
    }

    .sentiment-list {
      padding-bottom: 16px;
      .sentiment-block {
        margin: 8px 0 0;
      }
      .sentiment-block,
      .sentiment-value {
        display: flex;
        align-items: center;
        .sentiment-circle {
          padding: 3px;
          border-radius: 24px;
          height: 16px;
          width: 16px;
          overflow: hidden;
          text-align: center;
          margin-right: 4px;
          line-height: 10px;
          &.positive {
            background-color: rgba(32, 201, 151, 0.15);
            color: #20c997;
          }
          &.neutral {
            background-color: #fff5e2;
            color: #febc3c;
          }
          &.negative {
            background-color: rgba(255, 77, 79, 0.15);
            color: #ff4d4f;
          }
        }
        .value {
          font-size: 12px;
          color: #5a5f7d;
          @media screen and (max-width: 1030px) {
            font-size: 10px;
          }
          @media screen and (max-width: 991px) {
            font-size: 12px;
          }
          &.zero {
            color: #adbcd2;
          }
        }
      }
      .view {
        display: flex;
        vertical-align: middle;
        align-items: center;
        font-size: 12px;
        color: #5a5f7d;
        .view-icon {
          padding-right: 8px;
        }
      }
    }
    .action-row {
      display: flex;
      .action-title {
        color: #9299b8;
      }
      .action-value {
        font-size: 12px;
        margin-left: 8px;
      }
    }
  }
  .action-list {
    padding-top: 12px;
    padding-bottom: 4px;
    .title {
      font-size: 14px;
      color: #5a5f7d;
      font-weight: 500;
      text-align: left;
      margin-bottom: 8px;
    }
    .action-row {
      display: flex;
      justify-content: space-between;
      line-height: 16px;
      margin-bottom: 8px;
      .action-title {
        color: #9299b8;
        font-size: 12px;
        display: flex;
        align-items: center;
        i {
          margin-right: 5px;
        }
      }
      .action-value {
        color: #5a5f7d;
      }
    }
  }
  .no-bordersmall {
    border-top: none;
  }
}

.mobile-table {
  display: none;
  @media screen and (max-width: 750px) {
    display: block;
  }
  .table {
    width: 100%;
  }
  .item-row {
    border-top: 1px solid #f1f2f6;
    &:first-child {
      border-top: none;
    }
    td {
      cursor: pointer;
      background-color: #fff;
      color: #5a5f7d;
      &.middle {
        vertical-align: middle;
      }
      &.top {
        vertical-align: top;
      }
    }
    .top-index {
      width: 24px;
      height: 30px;
      margin-left: 25px;
      padding: 33px 5px 12px 0;
      vertical-align: top;
      img {
        width: 24px;
        height: 30px;
      }
    }

    .index-td {
      padding: 39px 16px 12px 8px;
      vertical-align: top;
      font-size: 13px;
      line-height: 17px;
      font-weight: 500;
    }
    .info-td {
      padding: 16px 0 12px 0;
      @media screen and (max-width: 450px) {
        padding: 16px 0;
      }
    }
    .engagement-td {
      padding: 16px 10px 12px 0;
      @media screen and (max-width: 376px) {
        padding: 16px 5px 0 0;
      }
    }
    .user-photo-wrap {
      position: relative;
      user-select: none;
      text-align: center;
      width: 64px;
      height: 64px;
      .user-photo {
        width: 64px;
        height: 64px;
        border-radius: 50px;
      }
      .source-tag {
        position: absolute;
        right: -5px;
        top: 48px;
        left: auto;
        bottom: 0;
        .logo {
          height: 16px;
          width: 16px;
          border-radius: 12px;
          color: #fff;
          margin-right: 4px;
          line-height: 14px;
          overflow: hidden;
          display: inline-flex;
          justify-content: center;
          align-items: center;
          background-color: #252525;
        }
        .inner {
          display: flex;
        }
        &.facebook {
          .logo {
            background-color: rgba(24, 119, 242, 1);
            svg {
              height: 10px;
            }
          }
        }
        &.twitter {
          .logo {
            background-color: rgb(34, 164, 240);
            svg {
              height: 10px;
            }
          }
        }
        &.twitterv2 {
          .logo {
            background-color: rgb(34, 164, 240);
            svg {
              height: 10px;
            }
          }
        }
        &.pantip {
          .logo {
            background-color: rgba(118, 116, 188, 1);
            svg {
              height: 12px;
            }
          }
        }
        &.youtube {
          .logo {
            background-color: #ff0000;
            svg {
              height: 10px;
            }
          }
        }
        &.instagram {
          .logo {
            background-color: rgba(228, 64, 95, 1);
            svg {
              height: 10px;
            }
          }
        }
        &.blockdit {
          .logo {
            background-color: #4a69ff;
            .blockdit {
              display: flex;
              align-items: center;
            }
          }
        }
      }
    }
    .user-info {
      margin-left: 16px;
      padding-top: 16px;
      max-width: 200px;
      .username {
        line-height: 18px;
        color: #272b41;
        font-weight: 500;
        font-size: 14px;
        overflow: hidden;
        white-space: normal;
        text-overflow: ellipsis;
        margin-bottom: 4px;
      }
      .account {
        margin-bottom: 12px;
        margin-top: 4px;
        line-height: 16px;
        color: #9299b8;
        font-weight: 400;
        font-size: 13px;
      }
    }

    .unit-text {
      padding-top: 11px;
      font-size: 13px;
      line-height: 16px;
      font-weight: 500;
      color: #5a5f7d;
      margin-bottom: 8px;
    }

    .value-text {
      font-size: 16px;
      line-height: 20px;
      font-weight: 500;
    }
  }
}
.blue-text {
  color: #3371ff;
}

.text-center {
  margin: 36px 0 0 0;
}

.source-tag {
  line-height: 13px;
  color: #434870;
  font-weight: 500;
  font-size: 10px;
}

@media only screen and (max-width: 580px) {
  .source-row {
    justify-content: unset;
  }
  .text-center {
    margin: 23px 0 0 0;
  }
  .item-table {
    .item-row {
      td {
        &.gold-text,
        &.silver-text,
        &.bronze-text {
          width: 24px;
          height: 24px;
          margin-left: unset;
        }
      }
    }
  }
}
</style>
<style scoped>
.ant-tooltip .ant-tooltip-inner {
  padding: 6px 10px;
}
.ant-tooltip .ant-tooltip-inner .type-row {
  white-space: nowrap;
  text-overflow: ellipsis;
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  width: fit-content;
  text-align: left;
  justify-content: left;
  column-gap: 8px;
  row-gap: 8px;
}

.ant-tooltip .ant-tooltip-inner .type-row .type {
  border-radius: 4px;
  height: 20px;
  line-height: 12px;
  font-size: 10px;
  font-weight: 400;
  text-transform: capitalize;
  padding: 4px 8px;
  white-space: nowrap;
  color: #5a5f7d;
  display: flex;
  align-items: center;
  background-color: #ebf1ff;
}
</style>
