<template>
  <div
    v-if="$slots['header-prefix']"
    class="header-wrapper"
  >
    <div class="select-box">
      <slot name="header-prefix"></slot>
    </div>
    <slot></slot>
  </div>
  <slot v-else></slot>
</template>

<script>
export default {
  name: 'SelectBoxHeader',
  setup() {},
};
</script>

<style lang="scss" scoped>
.header-wrapper {
  display: flex;
  align-items: center;
  .select-box {
    padding-left: 20px;
  }
  :deep(.message-head.message-item-head) {
    flex: 1;
    padding-left: 8px;
  }
}
</style>
