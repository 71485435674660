<template>
  <a-popover
    v-if="!['user', 'viewer'].includes(role)"
    overlay-class-name="sentiment-selector-wrap"
    placement="topRight"
  >
    <template #content>
      <div
        class="sentiment-selector"
        :class="sentimentClass"
      >
        <div class="inner-wrapper">
          <FeatherIcon
            class="icon"
            type="smile"
            color="#20c997"
            :size="28"
            @click="setSentiment(5)"
          />
          <FeatherIcon
            class="icon"
            type="meh"
            color="#febc3c"
            :size="28"
            @click="setSentiment(0)"
          />
          <FeatherIcon
            class="icon"
            type="frown"
            color="#ff4d4f"
            :size="28"
            @click="setSentiment(-5)"
          />
        </div>
      </div>
    </template>
    <div
      class="sentiment-circle"
      :class="sentimentClass"
      @click.stop.prevent=""
    >
      <FeatherIcon
        :type="sentimentIcon"
        :size="iconSize"
      />
    </div>
  </a-popover>

  <div
    v-else
    class="sentiment-circle"
    :class="sentimentClass"
    @click.stop.prevent=""
  >
    <FeatherIcon
      :type="sentimentIcon"
      :size="iconSize"
    />
  </div>
</template>

<script>
import { toRefs, computed, inject } from 'vue';
import { notification } from 'ant-design-vue';
import api from '@/services/api';
import { useStore } from 'vuex';
import { UserRole } from '@/enum/UserRoleEnum';

export default {
  name: 'SentimentCircleIcon',
  props: {
    data: {
      type: Object,
      default: () => {},
    },
    small: Boolean,
    editable: Boolean,
  },
  setup(props) {
    const store = useStore();
    const { data, small } = toRefs(props);
    const onEditMessage = inject('onEditMessage');
    const role = computed(() => store.getters['account/userRole']);

    const setSentiment = async (score) => {
      const messageId = data.value.info.id;
      onEditMessage({
        messageId,
        editing: true,
      });
      const result = await api
        .updateSentiment(messageId, 'single', score)
        .catch(() => {});
      if (result && result.status === 200) {
        notification.success({
          message: 'Success',
          description: 'Message sentiment edited.',
        });
        onEditMessage({
          messageId,
          sentiment: score,
        });
      } else {
        notification.error({
          message: 'Error',
          description: 'Message sentiment edit failed.',
        });
      }
      onEditMessage({
        messageId,
        editing: false,
      });
    };

    const sentimentIcon = computed(() => {
      if (data.value && data.value.analytic) {
        if (data.value.analytic.sentiment_score > 1) return 'smile';
        if (data.value.analytic.sentiment_score < -1) return 'frown';
        return 'meh';
      }
      return '';
    });
    const sentimentClass = computed(() => {
      let result = '';
      if (data.value && data.value.analytic) {
        if (data.value.analytic.sentiment_score > 1) result = 'positive-bg';
        else if (data.value.analytic.sentiment_score < -1)
          result = 'negative-bg';
        else result = 'neutral-bg';
      }
      if (small.value) {
        result = result + ' small';
      }

      if (![UserRole.VIEWER, UserRole.USER].includes(role.value)) {
        result += ' cursor-pointer';
      }
      return result;
    });
    const iconSize = computed(() => {
      if (small.value) {
        return 18;
      }
      return 28;
    });

    return {
      setSentiment,
      sentimentIcon,
      sentimentClass,
      iconSize,
      role,
    };
  },
};
</script>

<style lang="scss" scoped>
.sentiment-circle {
  height: 48px;
  width: 48px;
  border-radius: 48px;
  padding: 10px;
  &.positive-bg {
    background-color: rgba(32, 201, 151, 0.15);
    color: #20c997;
  }
  &.neutral-bg {
    background-color: #fff5e2;
    color: #febc3c;
  }
  &.negative-bg {
    background-color: rgba(255, 77, 79, 0.15);
    color: #ff4d4f;
  }
  &.small {
    height: 34px;
    width: 34px;
    border-radius: 34px;
    padding: 8px;
  }
}
</style>
