<template>
  <a-card
    class="top-influencer"
    :widget="true"
    :title="'Top Influencer'"
  >
    <template #extra>
      <span
        v-if="!loading && onlyTop5Data.length === 0"
        class="logo top-menu"
      >
        <a class="view-all disabled-link">
          View All
          <FeatherIcon
            class="button-icon"
            type="chevron-right"
            :size="16"
          />
        </a>
      </span>
      <router-link
        v-else
        class="logo top-menu"
        :to="{
          name: 'influencer',
          state: {
            sortBy: mode === 'view_count' ? 'view_count' : 'engagement',
          },
        }"
      >
        <a class="view-all">
          View All
          <FeatherIcon
            class="button-icon"
            type="chevron-right"
            :size="16"
          />
        </a>
      </router-link>
    </template>

    <div
      v-if="loading"
      class="top-influencer-loading"
    >
      <a-skeleton
        :loading="loading"
        active
      />
    </div>

    <TopInfluencerGrids
      v-if="!loading && onlyTop5Data.length !== 0 && !isMobileScreen"
      :influencerDataList="onlyTop5Data"
      :sortBy="mode"
      :filterResult="filter"
    ></TopInfluencerGrids>
    <InfluencerOverview
      v-if="!loading && onlyTop5Data.length !== 0 && isMobileScreen"
      :influencerDataList="onlyTop5Data"
      :sortBy="mode"
      :filterResult="filter"
    ></InfluencerOverview>

    <div
      v-else-if="!loading && onlyTop5Data.length === 0"
      class="no-data"
    >
      <NodataFound></NodataFound>
    </div>
  </a-card>
</template>

<script>
import { ref, reactive, computed, toRefs, watch } from 'vue';
import { useStore } from 'vuex';
import { notification } from 'ant-design-vue';
import api from '@/services/api';
import helper from '@/services/helper';
import InfluencerOverview from '@/components/Lists/InfluencerOverview.vue';
import TopInfluencerGrids from '@/components/Grids/TopInfluencerGrids.vue';
import NodataFound from '@/components/Error/NoDataFound.vue';

export default {
  components: {
    NodataFound,
    InfluencerOverview,
    TopInfluencerGrids,
  },
  props: {
    mode: {
      type: String,
      default: 'engagement',
    },
    filter: {
      type: Object,
      default: () => {},
    },
  },
  setup(props) {
    const store = useStore();
    const isMobileScreen = computed(() => store.state.isMobileScreen);
    const { mode, filter } = toRefs(props);
    const loading = ref(true);
    const error = ref(false);
    const influencerTrendData = reactive([]);

    const onlyTop5Data = computed(() => {
      if (influencerTrendData && influencerTrendData.value) {
        return influencerTrendData.value.slice(0, 5);
      }
      return [];
    });

    const init = async () => {
      loading.value = true;
      error.value = false;

      const result = await api
        .getInfluencerByMode(mode.value, filter.value)
        .catch(() => {
          notification.error({
            message: 'Error',
            description: 'Cannot get influencer data.',
          });
        });
      if (
        result &&
        result.message != null &&
        result.message.influencers != null &&
        result.message.peopleInfo != null
      ) {
        let finalResult = [];
        for (let peopleObj of result.message.influencers) {
          const info = result.message.peopleInfo[peopleObj.id];
          const newProfileImage = info.user_photo;
          const fallbackImage = info.fallback_user_photo;
          let totalEngage = peopleObj.total_engagement;
          let totalView = 0;
          let totalMention = 0;
          for (let a of peopleObj.actions) {
            totalMention += a.value;
          }

          for (let a of peopleObj.reactions) {
            if (a.key === 'view') {
              totalView += a.value;
            }
          }

          const types = info.interests.map((cat) => cat.interest_name);
          const showTag = [];
          const tooltip = [];
          let moreItem = '';
          if (types.length >= 2) {
            let strLength = 0;
            for (let index in types) {
              strLength += types[index].length;
              if (strLength < 40) {
                showTag.push(types[index]);
              } else {
                tooltip.push(types[index]);
              }
            }
            moreItem = '+ ' + tooltip.length;
          }

          finalResult.push({
            ...peopleObj,
            info,
            totalEngage: helper.numeral(totalEngage),
            totalMention: helper.numeral(totalMention),
            totalView: helper.numeral(totalView),
            newProfileImage,
            fallbackImage,
            types,
            tooltip,
            showTag,
            moreItem,
          });
        }
        influencerTrendData.value = finalResult;
      }
      loading.value = false;
      // console.log(influencerTrendData.value);
    };

    const onClickInfluencer = (item) => {
      const { peopleInfo } = item;
      console.log(item.peopleInfo.id);
      let f = {
        type: 'influencer',
        payload: {
          title: `${peopleInfo.user_name}'s posts`,
        },
        criteria: {
          ...filter.value,
          userId: peopleInfo.id,
          paging: {
            page: 1,
            recordPerPage: 10,
          },
          sort: {
            direction: 'desc',
            field: 'engagement_score',
          },
          highlight: {
            enable: true,
          },
        },
      };
      store.dispatch('message/showMessageModal', f);
    };

    watch(mode, () => {
      init();
    });

    watch(
      () => filter.value,
      () => {
        init();
      },
    );

    return {
      loading,
      error,
      onlyTop5Data,
      onClickInfluencer,
      isMobileScreen,
    };
  },
};
</script>

<style lang="scss" scoped>
.top-influencer {
  .view-all {
    font-weight: 500;
    font-size: 15px;
    line-height: 18px;
    color: #3371ff;
    cursor: pointer;
    .button-icon {
      padding-left: 10px;
      font-size: 16px;
      line-height: 18px !important;
      vertical-align: middle;
    }
  }
  .influencer-list-item {
    padding: 30px 0;
    &:not(:last-of-type) {
      border-bottom: 1px solid #f1f2f6;
    }
  }

  .no-data {
    height: 465px;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .list-wrapper {
    height: 505px;
    margin: -20px 0;
    padding: 0 20px;
  }

  .top-influencer-loading {
    min-height: 465px;
    max-height: 465px;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }

  .disabled-link {
    cursor: not-allowed;
    color: gray;
  }
}
</style>
<style scoped>
@media only screen and (max-width: 575px) {
  :deep(.ant-card-head-wrapper .ant-card-extra) {
    padding: 21px 0 !important;
  }
}
</style>
