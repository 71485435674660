<template>
  <div class="pbw">
    <template v-if="selectedData && selectedData.length">
      <div class="progress-bar">
        <div class="left-block">
          <div class="progress-bar-title">
            <span v-if="mode === 'engagement'">By Total Engagement</span>
            <span v-else-if="mode === 'view_count'">By Total View</span>
            <span v-else>By Total Mentioned</span>
          </div>
        </div>
        <div class="right-block">
          <div class="progress-bar-button">
            <a-button
              :type="isTotalActive ? 'primary' : 'ghost'"
              class="circular"
              :class="{ 'button-primary-light': !isTotalActive }"
              style="width: 42px; margin-right: 7px"
              @click="handleActiveChange('total')"
            >
              <span style="margin-left: 0px">#</span>
            </a-button>
            <a-button
              :type="isPercentActive ? 'primary' : 'ghost'"
              class="circular"
              :class="{ 'button-primary-light': !isPercentActive }"
              style="width: 42px"
              @click="handleActiveChange('percent')"
            >
              <span style="margin-left: 0px">%</span>
            </a-button>
          </div>
        </div>
      </div>
      <div class="progress-bar-content">
        <div
          v-for="item of selectedData"
          :key="item.name"
          class="progress-bar-block"
          :item="item"
          @click="emitClick(item)"
        >
          <div class="block-name">
            <div
              class="source-tag"
              :class="item.n"
            >
              <img
                v-if="item.n === 'facebook'"
                class="logo-img"
                :src="
                  require('../../assets/images/source-icon/svg/facebook.svg')
                "
                alt="logo"
              />
              <img
                v-else-if="item.n === 'twitter'"
                class="logo-img"
                :src="
                  require('../../assets/images/source-icon/svg/twitter.svg')
                "
                alt="logo"
              />
              <img
                v-else-if="item.n === 'instagram'"
                class="logo-img"
                :src="
                  require('../../assets/images/source-icon/svg/instagram.svg')
                "
                alt="logo"
              />
              <img
                v-else-if="item.n === 'pantip'"
                class="logo-img"
                :src="require('../../assets/images/source-icon/svg/pantip.svg')"
                alt="logo"
              />
              <img
                v-else-if="item.n === 'youtube'"
                class="logo-img"
                :src="
                  require('../../assets/images/source-icon/svg/youtube.svg')
                "
                alt="logo"
              />
              <img
                v-else-if="item.n === 'blockdit'"
                class="logo-img"
                :src="
                  require('../../assets/images/source-icon/svg/blockdit.svg')
                "
                alt="logo"
              />
              <img
                v-else-if="item.n === 'tiktok'"
                class="logo-img"
                :src="require('../../assets/images/source-icon/svg/tiktok.svg')"
                alt="logo"
              />
              <img
                v-else-if="item.n === 'website'"
                class="logo-img"
                :src="
                  require('../../assets/images/source-icon/svg/website.svg')
                "
                alt="logo"
              />
              <a-badge
                v-else
                :color="item.itemStyle.color"
                class="category-status-dot"
              />
              <!-- <img v-else class="logo-img" :src="require('../../assets/images/source-icon/website.png')" alt="logo" /> -->
            </div>
            <a-tooltip
              v-if="!isMobileScreen"
              placement="bottom"
            >
              <template #title>
                {{
                  item.name.toLowerCase() === 'twitter'
                    ? 'X (Twitter)'
                    : item.name
                }}
              </template>
              <div class="block-name-text">
                {{
                  item.name.toLowerCase() === 'twitter'
                    ? 'X (Twitter)'
                    : item.name
                }}
              </div>
            </a-tooltip>
            <div v-else>
              <template>
                {{
                  item.name.toLowerCase() === 'twitter'
                    ? 'X (Twitter)'
                    : item.name
                }}
              </template>
              <div class="block-name-text">
                {{
                  item.name.toLowerCase() === 'twitter'
                    ? 'X (Twitter)'
                    : item.name
                }}
              </div>
            </div>
          </div>
          <div class="block-progress-bar">
            <a-progress
              :percent="item.floatValue"
              :stroke-width="10"
              :stroke-color="item.itemStyle.color"
              :show-info="false"
              stroke-linecap="square"
            >
            </a-progress>
          </div>
          <div class="block-value">
            <a-tooltip
              v-if="!isMobileScreen"
              placement="bottom"
            >
              <template #title> Total: {{ item.formattedValue }} </template>
              <span>
                <span
                  v-if="isTotalActive"
                  class="block-value-text"
                  @click="handleActiveChange('total')"
                >
                  {{ item.valueText }}
                </span>
                <span
                  v-if="isPercentActive"
                  class="block-value-text"
                  @click="handleActiveChange('percent')"
                >
                  {{ item.valueAsPercent }} %
                </span>
              </span>
            </a-tooltip>
            <div v-else>
              <template> Total: {{ item.formattedValue }} </template>
              <span>
                <span
                  v-if="isTotalActive"
                  class="block-value-text"
                  @click="handleActiveChange('total')"
                >
                  {{ item.valueText }}
                </span>
                <span
                  v-if="isPercentActive"
                  class="block-value-text"
                  @click="handleActiveChange('percent')"
                >
                  {{ item.valueAsPercent }} %
                </span>
              </span>
            </div>
          </div>
        </div>
      </div>
    </template>
    <NoResult v-else></NoResult>
  </div>
</template>

<script>
import { ref, toRefs, computed } from 'vue';
import { useStore } from 'vuex';
import api from '@/services/api';
import helper from '@/services/helper';
import {
  faFacebookF,
  faYoutube,
  faTwitter,
  faInstagram,
} from '@fortawesome/free-brands-svg-icons';

import NoResult from '../../components/Error/NoResult.vue';

export default {
  name: 'ProgressBar',
  components: {
    NoResult,
  },
  props: {
    data: {
      type: Array,
      default: () => [],
    },
    chartName: {
      type: String,
      default: 'Chart',
    },
    chartType: {
      type: String,
      default: 'category',
    },
    mode: {
      type: String,
      default: 'engagement',
    },
  },
  emits: ['progressClick'],
  setup(props, { emit }) {
    const store = useStore();
    const isMobileScreen = computed(() => store.state.isMobileScreen);
    const { data, chartType } = toRefs(props);
    const tabActive = ref('total');
    let isTotalActive = ref(true);
    let isPercentActive = ref(false);
    const socialList = api.getAllSource();
    const prepareData = (d) => {
      const newData = d.map((obj) => {
        return {
          ...obj,
          floatValue: parseFloat(obj.valueAsPercent),
          valueText: helper.numeral(obj.value, '0,0.0a', true),
          formattedValue: helper.numeral(obj.value, '0,0', true),
          n: obj.name.toLowerCase(),
        };
      });
      return newData;
    };

    const selectedData = computed(() => {
      const newData = prepareData(data.value);
      let ct;
      if (chartType.value) {
        ct = chartType.value.toLowerCase();
      }
      if (ct === 'category') {
        return newData
          .sort((a, b) => b.value - a.value)
          .sort((a, b) => {
            if (a.name === 'Others') {
              return 1;
            } else if (b.name === 'Others') {
              return -1;
            } else {
              return 0;
            }
          });
      } else if (ct === 'channel') {
        return newData.sort(
          (a, b) =>
            socialList.indexOf(a.name.toLowerCase()) -
            socialList.indexOf(b.name.toLowerCase()),
        );
      }
      return newData;
    });

    const handleActiveChange = (value) => {
      if (value === 'total') {
        isTotalActive.value = true;
        isPercentActive.value = false;
      } else {
        isPercentActive.value = true;
        isTotalActive.value = false;
      }
      tabActive.value = value;
    };

    const emitClick = (item) => {
      emit('progressClick', {
        name: item.name,
      });
    };

    return {
      handleActiveChange,
      isTotalActive,
      isPercentActive,
      selectedData,
      emitClick,
      faFacebookF,
      faYoutube,
      faTwitter,
      faInstagram,
      isMobileScreen,
    };
  },
};
</script>

<style lang="scss" scoped>
@import '../../config/theme/colors.json';

.progress-bar {
  /* font-size: 13px;
  color: #5a5f7d;
  font-weight: 500; */
  display: flex;
  justify-content: space-between;
  align-items: center;
  // padding: 12.25px;
  margin-bottom: 30px;

  .progress-bar-title {
    color: #868eae;
    font-size: 13px;
  }
  .left-block,
  .right-block {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
  }

  .progress-bar-button {
    .button-primary-light {
      /* TODO Refactor to outline btn */
      background: #eff0f3;
      border-width: 0px;
      // color: $primary-color;
      &:focus {
        background: #2c99ff15;
        border-width: 0px;
        // color: $primary-color;
      }
    }
  }
}

.progress-bar-content {
  .progress-bar-block {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 24px;
    .block-name {
      flex: 1;
      display: flex;
      min-width: 100px;
      transform: translateX(0px);
      overflow: hidden;
      white-space: nowrap;
      align-items: center;
      .block-name-text {
        color: #9299b8;
        overflow: hidden;
        text-overflow: ellipsis;
      }
      .source-tag {
        display: inline-block;
        margin-right: 8px;
        .logo-img {
          width: 30px;
          height: 30px;
        }
      }
    }

    .block-progress-bar {
      flex: 4;
      display: flex;
      transform: translateX(10px);
    }

    .block-value {
      flex: 1;
      display: flex;
      min-width: 90px;
      // margin-left: 10px;
      transform: translateX(20px);
      .block-value-text {
        font-weight: 500;
        font-size: 18px;
        line-height: 23px;
        color: #272b41;
      }
    }
  }
}
.ps {
  // TODO? is this necessary
  height: 300px !important;
}

.custom-card-scroll {
  min-height: 300px;
  max-height: 300px;
  overflow-y: auto;
  overflow-x: hidden;
}

.custom-text-overflow {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

@media only screen and (max-width: 360px) {
  .progress-bar-content .progress-bar-block .block-value .block-value-text {
    font-size: 12px;
  }
  .progress-bar-content .progress-bar-block .block-value {
    min-width: 45px;
    margin-right: 20px;
  }
  .progress-bar {
    margin-right: 5px;
  }

  .progress-bar-content .progress-bar-block .block-name .block-name-text {
    font-size: 12px;
  }
}
</style>
<style lang="scss">
.progress-bar-content {
  .progress-bar-block {
    .block-name {
      .source-tag {
        .category-status-dot {
          .ant-badge-status-dot {
            width: 10px;
            height: 10px;
          }
        }
      }
    }
  }
}
</style>
