
import { computed, onBeforeMount, PropType, ref, toRef } from 'vue';
import {
  ConvertedPresetType,
  PresetCampaignType,
} from '@/types/preset-management/PresetType';
import CampaignShareUser from './PresetDetail/CampaignShareUser.vue';
import { useStore } from 'vuex';
import { notification } from 'ant-design-vue';
import api from '@/services/api';
import { UserType } from '@/types/users/UserType';

export default {
  components: {
    CampaignShareUser,
  },
  props: {
    item: {
      type: Object as PropType<ConvertedPresetType>,
      required: true,
    },
  },
  emits: ['update:visible'],
  setup(props, { emit }) {
    const preset = ref({});
    const store = useStore();
    const users = ref<UserType[]>([]);
    const isLoading = ref(false);
    const closeModal = () => {
      preset.value.campaign.isShareCampaign =
        itemData.value.campaign.isShareCampaign;
      preset.value.campaign.shareUser = itemData.value.campaign.shareUser;

      emit('update:visible', false);
    };
    const shareCampaign = async () => {
      const presetData = preset.value;
      if (
        presetData.campaign.isShareCampaign &&
        presetData.campaign.shareUser.length == 0
      ) {
        return;
      }

      const res = await store.dispatch('presetFilter/updatePreset', {
        id: presetData.id,
        data: {
          criteria: JSON.stringify(presetData.filter),
          presetDescription: presetData.preset.presetDescription,
          presetName: presetData.preset.presetName,
          isEnableCampaign: presetData.campaign.isEnableCampaign,
          isShareCampaign: presetData.campaign.isShareCampaign,
          shareUser: presetData.campaign.shareUser,
        },
      });
      if (res) {
        notification.success({
          message: `${truncateText(
            preset.value.preset.presetName
          )} has been saved successfully.`,
        });

        itemData.value.campaign.isShareCampaign =
          presetData.campaign.isShareCampaign;
        itemData.value.campaign.shareUser = presetData.campaign.shareUser;
        await store.dispatch('presetFilter/loadCampaigns');
      }
      emit('update:visible', false);
    };

    function truncateText(text: string | undefined): string {
      if (text == undefined) {
        return '';
      } else {
        return text.length > 60 ? text.substring(0, 60) + '...' : text;
      }
    }

    const onChangeUser = (info: PresetCampaignType) => {
      preset.value.campaign.isShareCampaign = info.isShareCampaign;
      preset.value.campaign.shareUser = info.isShareCampaign
        ? info.shareUser
        : [];
    };

    const isDisable = computed(() => {
      return (
        preset.value.campaign.shareUser.length === 0 &&
        preset.value.campaign.isShareCampaign
      );
    });

    const loadUsers = async () => {
      isLoading.value = true;
      const result = await api.getAllUsersByAccount().catch(() => {});
      if (result && result.message) {
        users.value = result.message;
      }
      isLoading.value = false;
    };

    const itemData = toRef(props, 'item');

    onBeforeMount(() => {
      loadUsers();
      preset.value = JSON.parse(JSON.stringify(itemData.value));
    });
    return {
      closeModal,
      shareCampaign,
      onChangeUser,
      users,
      isLoading,
      itemData,
      isDisable,
    };
  },
};
