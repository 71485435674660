
import { computed, Ref, toRef, PropType } from 'vue';
export default {
  props: {
    text: String,
    trigger: {
      type: String as PropType<String | null>,
      default: 'hover',
    },
  },
  setup(props) {
    const text: Ref<String | undefined> = toRef(props, 'text');
    const hoverText = computed(() => {
      return text.value?.replaceAll('\n', '<br/>');
    });
    return {
      hoverText,
    };
  },
};
