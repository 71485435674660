<template>
  <a-card
    class="campaign-sentiment"
    title="Sentiment by Channel"
  >
    <a-row :gutter="25">
      <a-col
        :xs="24"
        :sm="24"
        :md="24"
        class="sentimen-col"
      >
        <a-card
          v-resize="hideOverflowTitle"
          :widget="true"
          class="sentiment-comparison no-body-padding"
        >
          <div
            v-if="loading"
            class="loading-wrapper"
          >
            <a-skeleton
              :loading="loading"
              active
            />
          </div>
          <div
            v-else-if="!loading && noData"
            class="load-wrapper"
          >
            <NoDataFound></NoDataFound>
          </div>
          <div
            v-else-if="!loading && sentimentData.length > 0"
            class="item-wrapper"
          >
            <SourceChart
              class="chart"
              :chart-type="chartType"
              :series="sentimentData"
            ></SourceChart>
          </div>
        </a-card>
      </a-col>
    </a-row>
  </a-card>
</template>

<script>
import { ref, reactive, toRefs, computed, watchEffect, nextTick } from 'vue';
import { useStore } from 'vuex';
import api from '@/services/api';
import helper from '@/services/helper';
import chartHelper from '@/services/chartHelper';
import numeral from 'numeral';
import NoDataFound from '@/components/Error/NoDataFound.vue';
import SourceChart from '../../../components/Chart/SourceChart.vue';

export default {
  name: 'SentimentBySource',
  components: {
    NoDataFound,
    SourceChart,
  },
  props: {
    mode: String,
    filterResult: Object,
  },
  setup(props) {
    const store = useStore();
    let loading = ref(false);
    let { mode, filterResult } = toRefs(props);
    const sList = api.getSocialSource();
    const socialList = reactive(sList);
    const sourceLists = ref([...socialList, 'website']);
    const sentimentDataByMode = reactive({
      engagement: [],
      count: [],
      view: [],
    });
    const sentimentData = computed(() => {
      if (mode.value) {
        if (mode.value.toLowerCase() === 'engagement') {
          return sentimentDataByMode.engagement;
        } else if (mode.value.toLowerCase() === 'count') {
          return sentimentDataByMode.count;
        } else if (mode.value.toLowerCase() === 'view_count') {
          return sentimentDataByMode.view;
        } else {
          return sentimentDataByMode.engagement;
        }
      } else {
        return [];
      }
    });
    let noData = computed(() => {
      return sentimentData.value.every((category) =>
        category.data.every((value) => value === 0),
      );
    });
    const chartType = ref('column');

    const isMobileScreen = computed(() => store.state.isMobileScreen);
    const brand = computed(() => store.state.account.brand);
    const currentGraphCriteria = ref({});

    const checkSelectedSerieData = () => {
      if (sentimentData.value && sentimentData.value.length > 0) {
        noData.value = false;
      } else {
        noData.value = true;
      }
    };

    const getSentiment = async (fv) => {
      if (fv) {
        const { graphCriteria, granularity } = chartHelper.modifyGranularity(
          {},
          fv,
        );
        currentGraphCriteria.value = graphCriteria;
        let count = 0;
        const result = await api.getCampaignSentimentSource(fv, brand.value);
        if (result.message && result.message.data) {
          if (sourceLists.value && sourceLists.value.length > 0) {
            for (const social of sourceLists.value) {
              result.message.data.sort((a, b) =>
                a.source === social ? 1 : b.source === social ? -1 : 0,
              );
            }
          }
          // { cat: { count, engagement, view }}
          // TO count[], engage[], view[]
          const negativeEngage = [];
          const neutralEngage = [];
          const positiveEngage = [];

          const negativeCount = [];
          const neutralCount = [];
          const positiveCount = [];

          const negativeView = [];
          const neutralView = [];
          const positiveView = [];

          for (let key in result.message.data) {
            const { view, count, engagement } = result.message.data[key];
            negativeEngage.push(engagement.negative);
            neutralEngage.push(engagement.neutral);
            positiveEngage.push(engagement.positive);

            negativeCount.push(count.negative);
            neutralCount.push(count.neutral);
            positiveCount.push(count.positive);

            negativeView.push(view.negative);
            neutralView.push(view.neutral);
            positiveView.push(view.positive);
          }

          const engageSeries = [
            {
              name: 'Positive',
              data: positiveEngage,
              color: chartHelper.getColor('positive', count++),
              point: {
                events: {
                  click: (e) =>
                    drilldownFn(e.point.category, {
                      criteria: { sentiment: ['positive'] },
                      title: 'Positive sentiment',
                    }),
                },
              },
              custom: {
                granularity,
              },
            },
            {
              name: 'Neutral',
              data: neutralEngage,
              color: chartHelper.getColor('neutral', count++),
              point: {
                events: {
                  click: (e) =>
                    drilldownFn(e.point.category, {
                      criteria: { sentiment: ['neutral'] },
                      title: 'Neutral sentiment',
                    }),
                },
              },
              custom: {
                granularity,
              },
            },
            {
              name: 'Negative',
              data: negativeEngage,
              color: chartHelper.getColor('negative', count++),
              point: {
                events: {
                  click: (e) =>
                    drilldownFn(e.point.category, {
                      criteria: { sentiment: ['negative'] },
                      title: 'Negative sentiment',
                    }),
                },
              },
              custom: {
                granularity,
              },
            },
          ];
          const countSeries = [
            {
              name: 'Positive',
              data: positiveCount,
              color: chartHelper.getColor('positive', count++),
              point: {
                events: {
                  click: (e) =>
                    drilldownFn(e.point.category, {
                      criteria: { sentiment: ['positive'] },
                      title: 'Positive sentiment',
                    }),
                },
              },
              custom: {
                granularity,
              },
            },
            {
              name: 'Neutral',
              data: neutralCount,
              color: chartHelper.getColor('neutral', count++),
              point: {
                events: {
                  click: (e) =>
                    drilldownFn(e.point.category, {
                      criteria: { sentiment: ['neutral'] },
                      title: 'Neutral sentiment',
                    }),
                },
              },
              custom: {
                granularity,
              },
            },
            {
              name: 'Negative',
              data: negativeCount,
              color: chartHelper.getColor('negative', count++),
              point: {
                events: {
                  click: (e) =>
                    drilldownFn(e.point.category, {
                      criteria: { sentiment: ['negative'] },
                      title: 'Negative sentiment',
                    }),
                },
              },
              custom: {
                granularity,
              },
            },
          ];
          const viewSeries = [
            {
              name: 'Positive',
              data: positiveView,
              color: chartHelper.getColor('positive', count++),
              point: {
                events: {
                  click: (e) =>
                    drilldownFn(e.point.category, {
                      criteria: { sentiment: ['positive'] },
                      title: 'Positive sentiment',
                    }),
                },
              },
              custom: {
                granularity,
              },
            },
            {
              name: 'Neutral',
              data: neutralView,
              color: chartHelper.getColor('neutral', count++),
              point: {
                events: {
                  click: (e) =>
                    drilldownFn(e.point.category, {
                      criteria: { sentiment: ['neutral'] },
                      title: 'Neutral sentiment',
                    }),
                },
              },
              custom: {
                granularity,
              },
            },
            {
              name: 'Negative',
              data: negativeView,
              color: chartHelper.getColor('negative', count++),
              point: {
                events: {
                  click: (e) =>
                    drilldownFn(e.point.category, {
                      criteria: { sentiment: ['negative'] },
                      title: 'Negative sentiment',
                    }),
                },
              },
              custom: {
                granularity,
              },
            },
          ];

          sentimentDataByMode.engagement = engageSeries;
          sentimentDataByMode.count = countSeries;
          sentimentDataByMode.view = viewSeries;

          checkSelectedSerieData();

          await nextTick();
          hideOverflowTitle();
        }
      }
    };

    const init = async (fv) => {
      loading.value = true;
      await getSentiment(fv);
      loading.value = false;
    };

    const drilldownFn = (source, payload = { title: 'data' }) => {
      const { graphTime } = currentGraphCriteria.value;
      let f = {
        payload: {
          title: `${helper.capitalize(payload.title)}`,
        },
        criteria: {
          ...filterResult.value,
          graphTime,
          source: [source],
          paging: {
            page: 1,
            recordPerPage: 10,
          },
          sort: {
            direction: 'desc',
            field:
              mode.value === 'view_count' ? 'view_count' : 'engagement_score',
          },
          highlight: {
            enable: true,
          },
          sentiment: payload.criteria.sentiment,
        },
      };

      store.dispatch('message/showMessageModal', f);
    };

    const hideOverflowTitle = () => {
      const allSegment = document.querySelectorAll('.prog-inner-element');

      if (allSegment) {
        allSegment.forEach((obj) => {
          if (obj.offsetWidth > obj.parentElement.offsetWidth) {
            obj.style.visibility = 'hidden';
          } else {
            obj.style.visibility = 'visible';
          }
        });
      }
    };

    watchEffect(() => {
      const fv = filterResult.value;
      init(fv);
    });

    const selectedModeStyle = {
      color: ' #097cff',
      background: 'rgba(9, 124, 255, 0.05)',
    };

    const unSelectedModeStyle = {
      color: '#5A5F7D',
    };

    return {
      sentimentData,
      sentimentDataByMode,
      hideOverflowTitle,
      loading,
      isMobileScreen,
      selectedModeStyle,
      unSelectedModeStyle,
      numeral,
      chartType,
      noData,
    };
  },
};
</script>

<style lang="scss" scoped>
.campaign-sentiment {
  min-height: 555px;

  .sentimen-col {
    min-height: 450px;

    .sentiment-comparison {
      min-height: 450px;

      .item-wrapper {
        height: 450px;
      }
    }
  }
}

.table-sentiment {
  .no-text {
    font-weight: 500;
    color: #272b41;
  }

  .ant-table-without-column-header table {
    color: #5a5f7d;
  }
}

.align-right {
  text-align: right;
  padding-right: 50px;
}

.tab-engagement {
  font-size: 12px;
  color: #868eae;
}

.sentiment-comparison {
  margin-bottom: 0;

  .loading-wrapper {
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin: 0px 20px;
  }

  .item-wrapper {
    overflow-y: auto;
    padding-left: unset;
  }

  .item-row {
    margin: 16px 0px 16px 0px; //16px 16px 16px 32px;
    display: flex;
    align-items: center;
    overflow: hidden;

    .title {
      padding-left: 15px;
      color: #5a5f7d;
      font-size: 14px;
      // min-width: 120px;
      flex: 0 0 120px;
      text-transform: capitalize;

      .youtube-img {
        width: 30px;
        height: 30px;
      }
    }

    .title.content-text {
      display: contents;
    }

    .title-thead {
      color: #272b41;
      font-size: 14px;
      // min-width: 120px;
      flex: 0 0 120px;
      text-transform: capitalize;
    }

    .prog-bar {
      flex: 1 1 auto;
      display: flex;
      margin-right: 5%;

      .prog-segment {
        text-align: center;
        color: #fff;
        height: 18px;
        line-height: 18px;

        .prog-inner-element {
          font-size: 12px;
        }
      }

      .prog-column-sentiment {
        font-size: 12px;
        text-align: center;
        color: #868eae;
        height: 18px;
        line-height: 18px;
      }

      .has-data {
        cursor: pointer;
      }

      .no-data {
        background-color: #aaa;
        width: 100%;
      }

      .positive {
        background-color: #20c997;
      }

      .neutral {
        background-color: #febc3c;
      }

      .negative {
        background-color: #ff4d4f;
      }
    }
  }

  .ant-card-head .ant-card-head-wrapper {
    flex-flow: row;
    align-items: center;
  }

  .ant-card-head-wrapper .ant-card-extra {
    padding: 0px !important;
  }

  .more-menu {
    color: #9299b8;
  }
}

@media only screen and (max-width: 823px) {
  .sentiment-comparison {
    .item-row {
      .title {
        flex: 0 0 100px;
        padding-left: 8px;
      }

      .title-thead {
        flex: 0 0 100px;
      }
    }
  }
}

@media only screen and (max-width: 560px) {
  .sentiment-comparison {
    .item-row {
      .title {
        font-size: 11px !important;
      }

      .content-text {
        .logo-img {
          width: 20px;
          height: 20px;
        }
      }

      .prog-bar {
        margin-right: 0 !important;
      }
    }
  }
}
</style>
<style scoped>
@media only screen and (max-width: 767px) {
  .sentimen-col {
    padding: 0 !important;
  }

  :deep(.campaign-sentiment.ant-card.card-height .ant-card-body) {
    padding: 0 !important;
  }

  :deep(.sentiment-comparison.ant-card.no-body-padding .ant-card-body) {
    padding: 20px !important;
  }
}

@media only screen and (max-width: 575px) {
  :deep(.ant-card-head-wrapper) {
    height: fit-content !important;
    align-items: flex-start;
  }
}
</style>
