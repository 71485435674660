<template>
  <template v-if="layout">
    <component :is="layout">
      <slot />
    </component>
  </template>
  <template v-else>
    <slot></slot>
  </template>
</template>

<script>
import { computed } from 'vue';
import { useRoute } from 'vue-router';
import NavLayout from './NavLayout.vue';
import BlankLayout from './BlankLayout.vue';

export default {
  name: 'AppLayout',
  components: {
    NavLayout,
    BlankLayout,
  },
  setup() {
    const route = useRoute();
    const layout = computed(() => {
      const name = route.meta.layout;
      console.log(name);
      return name;
    });
    return {
      layout,
    };
  },
};
</script>
