import dayjs from 'dayjs';
import api from '@/services/api';
import helper from '@/services/helper';
import { UserRole } from '@/enum/UserRoleEnum';

const sList = api.getSocialSource();
const allSources = api.getAllSource();

export default {
  namespaced: true,
  state: {
    brand: '',
    colors: {},
    userAccount: {},
    userInfo: {},
    summary: {},
    mode: '',
    totalUsage: 0,
    campaignMenuData: {},
  },
  getters: {
    colors: (state) => state.colors,
    campaignMenuData: (state) => state.campaignMenuData,
    hasUserInfo({ userInfo }) {
      if (userInfo && userInfo.id) {
        return true;
      }
      return false;
    },
    sourceNameList({ userAccount }) {
      if (userAccount && userAccount.sources) {
        return userAccount.sources;
      }
      return [];
    },
    sourceList({ userAccount }) {
      if (userAccount && userAccount.sources) {
        return userAccount.sources.map((s) => {
          return {
            value: s,
            text: helper.capitalize(s),
          };
        });
      }
      return [];
    },
    sortedSourceList({ userAccount }) {
      if (userAccount && userAccount.visibleSources) {
        let srcList = userAccount.visibleSources.sort();
        let finalSrc = [];
        sList.forEach((s) => {
          if (srcList.includes(s)) {
            finalSrc.push(s);
          }
        });
        for (let src of srcList) {
          if (!sList.includes(src)) {
            finalSrc.push(src);
          }
        }
        return finalSrc;
      }
      return [];
    },
    sourceTreeData({ userAccount }) {
      const result = {
        tree: [],
        idMap: {},
        defaultState: {},
      };
      if (userAccount && userAccount.visibleSources) {
        let srcList = userAccount.visibleSources.sort();
        let finalSrc = [
          {
            id: 'allChannels',
            name: 'all Channels',
          },
        ];
        const df = {
          Website: [],
        };

        let websiteSrc = {
          id: 'website',
          name: 'Website',
          hasSearch: true,
          searchPlaceholder: 'Search source name',
          children: [],
        };

        for (let src of srcList) {
          if (!sList.includes(src)) {
            const v = `website::${src}`;
            websiteSrc.children.push({
              value: v,
              label: helper.capitalize(src),
              category: 'website',
            });
            df.Website.push(v);
          }
        }
        df['all Channels'] = true;

        allSources.forEach((n) => {
          if (n === 'website') {
            finalSrc.push(websiteSrc);
          } else {
            finalSrc.push({
              id: n,
              name: n,
            });
          }

          df[n] = true;
        });

        result.tree = finalSrc;
        result.defaultState = df;
      }
      return result;
    },
    accessRights({ userAccount }) {
      if (userAccount && userAccount.accessRights) {
        return userAccount.accessRights;
      }
      return [];
    },
    queryLength({ userAccount }) {
      if (userAccount && userAccount.queryLength) {
        return userAccount.queryLength;
      }
      return 28;
    },
    userRole({ userInfo }) {
      if (userInfo && userInfo.role) {
        return userInfo.role;
      }
      return 'user';
    },
    userRoleText({ userInfo }) {
      if (userInfo && userInfo.role) {
        switch (userInfo.role) {
          case 'admin':
            return 'Admin';
          case 'domadmin':
            return 'DOM Admin';
          case 'feedonly':
            return 'Inspector';
          default:
            return 'User';
        }
      }
      return 'User';
    },
    isDomAdmin({ userInfo }) {
      if (userInfo && userInfo.role) {
        if (userInfo.role === 'domadmin') {
          return true;
        }
      }
      return false;
    },
    isViewer({ userInfo }) {
      if (userInfo && userInfo.role) {
        if (userInfo.role === UserRole.VIEWER) {
          return true;
        }
      }
      return false;
    },
    messageEditableRole({ userInfo }) {
      if (userInfo && userInfo.role) {
        if (userInfo.role === 'admin' || userInfo.role === 'domadmin') {
          return true;
        }
      }
      return false;
    },
    userName({ userInfo }) {
      if (userInfo && userInfo.username) {
        return userInfo.username;
      }
      return '';
    },
    userId({ userInfo }) {
      if (userInfo && userInfo.id) {
        return userInfo.id;
      }
      return '';
    },
    userEmail({ userInfo }) {
      if (userInfo && userInfo.email) {
        return userInfo.email;
      }
      return '';
    },
    accountId({ userAccount }) {
      if (userAccount && userAccount.id) {
        return userAccount.id;
      }
      return '';
    },
    // isAcceptReport({ userInfo }) {
    //   if (userInfo && userInfo.isAcceptReport) {
    //     return userInfo.isAcceptReport;
    //   }
    //   return '';
    // },
    // isAcceptAnomaly({ userInfo }) {
    //   if (userInfo && userInfo.isAcceptAnomaly) {
    //     return userInfo.isAcceptAnomaly;
    //   }
    //   return '';
    // },
    // isAcceptSettingChange({ userInfo }) {
    //   if (userInfo && userInfo.isAcceptSettingChange) {
    //     return userInfo.isAcceptSettingChange;
    //   }
    //   return '';
    // },
    // isAcceptSpecialKeyword({ userInfo }) {
    //   if (userInfo && userInfo.isAcceptSpecialKeyword) {
    //     return userInfo.isAcceptSpecialKeyword;
    //   }
    //   return '';
    // },
    userNotificationSettings({ userInfo }) {
      if (userInfo) {
        return {
          isAcceptReport: userInfo.isAcceptReport,
          isAcceptSettingChange: userInfo.isAcceptSettingChange,
          isAcceptAnomaly: userInfo.isAcceptAnomaly,
          isAcceptSpecialKeyword: userInfo.isAcceptSpecialKeyword,
        };
      }
      return '';
    },
    userInfo({ userInfo }) {
      if (userInfo) {
        return userInfo;
      }
      return '';
    },
    isAcceptConsent({ userInfo }) {
      if (userInfo) {
        return userInfo.isAcceptConsent;
      }
      return false;
    },
    // userAccount({ userAccount }) {
    //   if (userAccount) {
    //     return userAccount;
    //   }
    //   return '';
    // },
  },
  actions: {
    async logout({ commit, dispatch }) {
      await dispatch('filter/setDefaultFilter', {}, { root: true });
      await api.logout();
      commit('clearConfig');
    },
    clearConfig({ commit }) {
      commit('clearConfig');
    },
    setConfig({ commit, dispatch }, configData) {
      commit('setConfig', configData);
      commit('setColorConfig', configData);
      dispatch('getCampaignMenu');
    },
    setEmail({ commit }, configData) {
      commit('setEmail', configData);
    },
    setNotifications({ commit }, configData) {
      commit('setNotifications', configData);
    },
    async getUsageStats({ commit }) {
      const since = dayjs().startOf('month').valueOf();
      const until = dayjs().endOf('month').valueOf();
      const result = await api.getUsageStats(since, until).catch(() => {});
      if (result.message && result.message.total) {
        commit('setTotalUsage', result.message.total);
      }
    },
    async getCampaignMenu({ commit }) {
      const result = await api.getCampaignMenu().catch(() => {});
      if (result && result.message) {
        commit('setCampaignMenuData', result.message);
      }
    },
    setConsent({ commit }, consentState) {
      commit('setConsent', consentState);
    },
  },
  mutations: {
    clearConfig(state) {
      state.campaignMenuData = {};
      state.brand = '';
      state.colors = {};
      state.userAccount = {};
      state.userInfo = {};
      state.summary = {};
      state.mode = '';
      state.totalUsage = 0;
    },
    setConfig(state, data) {
      state.brand = data.brand;
      state.userAccount = data.userAccount;
      state.userInfo = data.userInfo;
      state.summary = data.summary;
      state.mode = data.mode;
    },
    setColorConfig(state, data) {
      const result = {};
      data.colors.forEach((c) => {
        result[c.id] = c.color;
      });
      state.colors = result;
    },
    setEmail(state, data) {
      state.userInfo.email = data;
    },
    setNotifications(state, data) {
      state.userInfo.isAcceptReport = data.isAcceptReport;
      state.userInfo.isAcceptSettingChange = data.isAcceptSettingChange;
      state.userInfo.isAcceptAnomaly = data.isAcceptAnomaly;
      state.userInfo.isAcceptSpecialKeyword = data.isAcceptSpecialKeyword;
    },
    setTotalUsage(state, data) {
      state.totalUsage = data;
    },
    setConsent(state, data) {
      state.userInfo.isAcceptConsent = data;
    },
    setCampaignMenuData(state, data) {
      state.campaignMenuData = data;
    },
  },
};
