<template>
  <div class="campaign-performance-page">
    <ShareOfVoice :mode="mode"></ShareOfVoice>
    <ChannelPerformance
      :mode="mode"
      :filterResult="filterCriteria"
    ></ChannelPerformance>
    <WordcloudOverview
      :mode="mode"
      :filterResult="filterCriteria"
    ></WordcloudOverview>
    <TopEngagePost
      :mode="mode"
      :filterResult="filterCriteria"
    ></TopEngagePost>
  </div>
</template>

<script>
import { computed } from 'vue';
import { useStore } from 'vuex';
import ChannelPerformance from './ChannelPerformance.vue';
import WordcloudOverview from './WordcloudOverview.vue';
import TopEngagePost from './TopEngagePost.vue';
import ShareOfVoice from './ShareOfVoice.vue';

export default {
  name: 'TopicTrendingSection',
  components: {
    ChannelPerformance,
    WordcloudOverview,
    TopEngagePost,
    ShareOfVoice,
  },
  props: {
    mode: {
      type: String,
      default: '',
    },
  },
  setup() {
    const store = useStore();
    const filterCriteria = computed(
      () => store.getters['filter/filterCriteria']
    );
    return {
      filterCriteria,
    };
  },
};
</script>

<style lang="scss" scoped>
.campaign-performance-page {
  height: 100%;
  width: 100%;
}
.card-height {
  height: 96%;
}
</style>
