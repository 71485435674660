<template>
  <a-card
    class="top-post"
    :widget="true"
    :title="'Top post by ' + (mode === 'view_count' ? 'View' : 'Engagement')"
  >
    <template #extra>
      <span
        v-if="!loading && onlyTop5Data && onlyTop5Data.length === 0"
        class="logo top-menu"
      >
        <a class="view-all disabled-link">
          View All
          <FeatherIcon
            class="button-icon"
            type="chevron-right"
            :size="16"
          />
        </a>
      </span>
      <router-link
        v-else
        class="logo top-menu"
        :to="{
          name: 'explore',
          state: {
            sortBy: mode === 'view_count' ? 'view_count' : 'engagement_score',
          },
        }"
      >
        <a class="view-all">
          View All
          <FeatherIcon
            class="button-icon"
            type="chevron-right"
            :size="16"
          />
        </a>
      </router-link>
    </template>

    <div
      v-if="loading"
      class="top-post-loading"
    >
      <a-skeleton
        :loading="loading"
        active
      />
    </div>

    <div
      v-if="mode === 'count'"
      class="error-wrapper"
    >
      <NoDataAspect />
    </div>

    <div
      v-else-if="!loading && onlyTop5Data && onlyTop5Data.length === 0"
      class="error-wrapper"
    >
      <NoDataFound />
    </div>

    <div
      v-else-if="!loading && onlyTop5Data && onlyTop5Data.length > 0"
      class="list-wrapper"
    >
      <div
        v-for="(item, index) in onlyTop5Data"
        :key="item.user_name"
        class="list-item"
      >
        <MessageItem
          :item="item"
          :order-no="index + 1"
          :show-category="true"
          :has-thumb="true"
          :showLongText="true"
          :parentName="'toppost'"
          @click="drilldown(item)"
        />
      </div>
    </div>
  </a-card>
  <!-- <SeeMoreDialog /> -->
</template>

<script>
import { ref, reactive, computed, toRefs, watchEffect, provide } from 'vue';
import { useStore } from 'vuex';
import api from '@/services/api';
import helper from '@/services/helper';
import NoDataFound from '@/components/Error/NoDataFound.vue';
import MessageItem from '@/views/Overview/MessageItem.vue';
import NoDataAspect from '@/components/Error/NoDataAspect.vue';
// import SeeMoreDialog from '@/components/Seemore/SeeMoreDialog.vue';

export default {
  components: {
    NoDataFound,
    MessageItem,
    NoDataAspect,
    // SeeMoreDialog,
  },
  props: {
    mode: {
      type: String,
      default: 'engagement',
    },
    filter: {
      type: Object,
      default: () => {},
    },
  },
  setup(props) {
    const store = useStore();
    const { filter, mode } = toRefs(props);
    const loading = ref(true);
    const error = ref(false);
    const topPostList = reactive({
      data: [],
      subCategoryName: {},
    });
    // const subCategoryName = ref({});

    const onlyTop5Data = computed(() => {
      if (topPostList.data) {
        return topPostList.data.slice(0, 5);
      }
      return [];
    });
    const subCategoryName = computed(() => {
      if (topPostList.subCategoryName) {
        return topPostList.subCategoryName;
      }
      return [];
    });
    const init = async (fv) => {
      loading.value = true;
      error.value = false;
      const result = await api
        .getSearchData({
          ...fv,
          sort: {
            field:
              mode.value === 'view_count' ? 'view_count' : 'engagement_score',
            direction: 'desc',
          },
          highlight: {
            enable: true,
          },
        })
        .catch(() => {
          error.value = true;
        });
      if (result && result.message && result.message.data) {
        const highlightResult = await store.dispatch(
          'config/highlightKeywords',
          {
            messageList: result.message.data,
            truncateAt: 0,
          },
        );
        topPostList.data = highlightResult;
        topPostList.subCategoryName = result.message.subCategoryName;
      }
      loading.value = false;
      console.log(topPostList);
    };

    const drilldown = (item) => {
      store.dispatch('seemore/show', item);
    };

    // onMounted(() => init());
    watchEffect(() => {
      const fv = filter.value;
      init(fv);
    });

    provide('onEditMessage', (e) => {
      helper.saveLocalMessage(e, topPostList.data);
    });
    provide('showMode', '');

    provide('subCategoryName', subCategoryName);

    return {
      loading,
      error,
      onlyTop5Data,
      subCategoryName,
      drilldown,
    };
  },
};
</script>

<style lang="scss" scoped>
.top-post {
  .error-wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 920px;
    // height: 700px;
  }
  .view-all {
    font-weight: 500;
    font-size: 15px;
    line-height: 18px;
    color: #3371ff;
    cursor: pointer;
    .button-icon {
      padding-left: 10px;
      font-size: 16px;
      line-height: 18px !important;
      vertical-align: middle;
    }
  }
  .list-wrapper {
    margin: 0;
    padding: 0;
  }

  .top-post-loading {
    height: 920px;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
  .list-item {
    margin-bottom: 10px;
    // border: 1px solid #f0f2f5;
    box-shadow: 0 5px 20px rgb(172 172 172 / 23%);
    border-radius: 5px;
  }
  .disabled-link {
    cursor: not-allowed;
    color: gray;
  }
}
</style>
<style scoped>
:deep(.ant-card-body) {
  min-height: 980px;
  overflow: auto;
}
@media only screen and (max-width: 575px) {
  :deep(.ant-card-head-wrapper .ant-card-extra) {
    padding: 21px 0 !important;
  }
}
</style>
