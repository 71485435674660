<template>
  <a-card
    v-resize="hideOverflowTitle"
    :widget="true"
    class="word-cloud-page no-body-padding"
    title="Word Cloud"
  >
    <template
      v-if="!isMobileScreen"
      #extra
    >
      <a-radio-group
        v-model:value="wordCloudMode"
        class="radio-wordcloud"
        button-style="solid"
        size="default"
      >
        <a-radio-button
          v-for="opt in modeOptions"
          :key="opt.value"
          :disabled="noData"
          :value="noData ? null : opt.value"
          style="height: 34px"
        >
          {{ opt.label }}
        </a-radio-button>
      </a-radio-group>
    </template>
    <template
      v-else
      #extra
    >
      <a-dropdown
        placement="bottomRight"
        :getPopupContainer="(trigger) => trigger.parentNode"
      >
        <FeatherIcon
          class="more-menu"
          type="more-horizontal"
          size="16"
        />
        <template #overlay>
          <a-menu id="mode-menu-mobile">
            <a-menu-item-group title="TYPE:">
              <a-menu-item
                class="item-btn"
                style="background: #fff"
              >
                <a-button
                  :type="
                    wordCloudMode === 'hashtag' && selectModeType.length !== 0
                      ? 'primary'
                      : 'ghost'
                  "
                  class="circular btn-line"
                  :class="{
                    'button-primary-light':
                      wordCloudMode === 'hashtag' &&
                      selectModeType.length !== 0,
                  }"
                  style="width: 42px"
                  :disabled="selectModeType.length == 0"
                  @click="wordCloudMode = 'hashtag'"
                >
                  <FeatherIcon
                    type="hash"
                    size="18"
                  />
                </a-button>
                <a-button
                  :type="
                    wordCloudMode === 'word' && selectModeType.length !== 0
                      ? 'primary'
                      : 'ghost'
                  "
                  class="circular"
                  :class="{
                    'button-primary-light':
                      wordCloudMode === 'word' && selectModeType.length !== 0,
                  }"
                  style="width: 42px"
                  :disabled="selectModeType.length == 0"
                  @click="wordCloudMode = 'word'"
                >
                  <span
                    ><img
                      class="icon-font-case"
                      src="@/assets/images/icon/font-case.svg" /></span
                ></a-button>
              </a-menu-item>
            </a-menu-item-group>
          </a-menu>
        </template>
      </a-dropdown>
    </template>

    <div
      v-if="loading"
      class="loading-wrapper"
    >
      <a-skeleton
        :loading="loading"
        active
      />
    </div>

    <!-- Content -->
    <div v-else-if="!loading && selectModeType.length !== 0">
      <a-row class="word-cloud-content">
        <a-col
          :xs="24"
          :sm="24"
          :md="12"
          class="word-cloud-card"
        >
          <canvas
            id="wc"
            ref="wc"
            class="wordCloud-canvas"
            width="480"
            height="300"
          ></canvas>
        </a-col>
        <a-col
          v-if="!isMobileScreen"
          :xs="24"
          :sm="24"
          :md="12"
          class="word-list-block"
        >
          <div class="table-word-cloud table-bordered">
            <a-table
              rowKey="no"
              :dataSource="selectModeType"
              :columns="wordCloudMode == 'hashtag' ? columns : columnsWords"
              :pagination="false"
              :showHeader="true"
              class="table-trending"
              ellipsize="{true}"
              rowClassName="table-row"
              :customRow="rowClick"
            >
              <template #no="{ text }">
                <span class="no-text">{{ text }}</span>
              </template>
              <template #percent="{ text }">
                <a-progress
                  v-if="!isMobileScreen"
                  :percent="text"
                  :show-info="false"
                />
              </template>
            </a-table>
          </div>
        </a-col>
        <a-col
          v-else
          :xs="24"
          :sm="24"
          :md="12"
          class="word-list-block"
        >
          <div class="word-cloud-lists">
            <a-row
              v-if="wordCloudMode == 'hashtag'"
              class="list-header-row"
            >
              <a-col
                v-for="header in columns"
                :key="header.key"
                class="list-header-col"
                ><span class="header-lists">{{ header.title }}</span></a-col
              >
            </a-row>
            <a-row
              v-if="wordCloudMode == 'word'"
              class="list-header-row"
            >
              <a-col
                v-for="header in columnsWords"
                :key="header.key"
                class="list-header-col"
                ><span class="header-lists">{{ header.title }}</span></a-col
              >
            </a-row>
            <a-row
              v-for="data in selectModeType"
              :key="data.no"
              class="list-data-row"
            >
              <a-col class="list-data-col">
                <span class="no-text">{{ data.no }}</span>
              </a-col>
              <a-col class="list-data-col">
                <span class="list-data-text">{{ data.hashtag }}</span>
              </a-col>
              <a-col class="list-data-col">
                <span class="list-data-text">{{ data.engagement }}</span>
              </a-col>
              <a-progress
                class="prog-bar-mobile"
                :percent="data.percent"
                :show-info="false"
              />
            </a-row>
          </div>
        </a-col>
      </a-row>
    </div>
    <div v-else>
      <NoResult></NoResult>
    </div>
  </a-card>
</template>

<script>
import { ref, computed, toRefs, watch, reactive, onMounted } from 'vue';
import { useStore } from 'vuex';
import WordCloud from 'wordcloud';
import numeral from 'numeral';
import helper from '@/services/helper';
import api from '@/services/api';
import NoResult from '../../../components/Error/NoResult.vue';

export default {
  name: 'WordcloudOverview',
  props: {
    type: String,
    mode: String,
    filterResult: Object,
    categoryByLevel: Object,
  },
  components: {
    NoResult,
  },
  setup(props) {
    let { mode, filterResult } = toRefs(props);
    const store = useStore();
    const isMobileScreen = computed(() => store.state.isMobileScreen);
    const brand = computed(() => store.state.account.brand);
    const wordCloudMode = ref('hashtag');
    let loading = ref(false);
    let wc = ref();
    let maxVal = 0;
    let totalCount = 0;
    const wordEngagementData = ref([]);
    const wordMentionData = ref([]);
    const wordViewData = ref([]);
    const wordDataByMode = reactive({
      engagement: [],
      mention: [],
      view: [],
    });
    const hashtagEngagementData = ref([]);
    const hashtagMentionData = ref([]);
    const hashtagViewData = ref([]);
    let sortBy = ref();
    const hashtagDataByMode = reactive({
      engagement: [],
      mention: [],
      view: [],
    });
    const modeOptions = [
      { label: 'Hashtag', value: 'hashtag' },
      { label: 'Word', value: 'word' },
    ];

    const columnByMode = {
      engagement: [
        {
          title: '#',
          dataIndex: 'no',
          key: 'no',
          slots: { customRender: 'no' },
          width: 38,
        },
        {
          title: 'Hashtag',
          dataIndex: 'hashtag',
          key: 'hashtag',
          ellipsis: true,
          width: 90,
          onCell: () => {
            return {
              style: {
                whiteSpace: 'nowrap',
                maxWidth: 100,
              },
            };
          },
        },
        {
          title: 'Engagement',
          dataIndex: 'engagement',
          key: 'engagement',
          width: 110,
        },
        {
          title: '',
          dataIndex: 'percent',
          key: 'percent',
          slots: { customRender: 'percent' },
          width: 110,
        },
      ],
      mention: [
        {
          title: '#',
          dataIndex: 'no',
          key: 'no',
          slots: { customRender: 'no' },
          width: 65,
        },
        {
          title: 'Hashtag',
          dataIndex: 'hashtag',
          key: 'hashtag',
          ellipsis: true,
          width: 100,
          onCell: () => {
            return {
              style: {
                whiteSpace: 'nowrap',
                maxWidth: 100,
              },
            };
          },
        },
        {
          title: 'Mentioned',
          dataIndex: 'engagement',
          key: 'engagement',
          width: 110,
        },
        {
          title: '',
          dataIndex: 'percent',
          key: 'percent',
          slots: { customRender: 'percent' },
          width: 110,
        },
      ],
      view: [
        {
          title: '#',
          dataIndex: 'no',
          key: 'no',
          slots: { customRender: 'no' },
          width: 65,
        },
        {
          title: 'Hashtag',
          dataIndex: 'hashtag',
          key: 'hashtag',
          ellipsis: true,
          width: 100,
          onCell: () => {
            return {
              style: {
                whiteSpace: 'nowrap',
                maxWidth: 100,
              },
            };
          },
        },
        {
          title: 'View',
          dataIndex: 'engagement',
          key: 'engagement',
          width: 110,
        },
        {
          title: '',
          dataIndex: 'percent',
          key: 'percent',
          slots: { customRender: 'percent' },
          width: 110,
        },
      ],
    };

    const columnsWordsByMode = {
      engagement: [
        {
          title: '#',
          dataIndex: 'no',
          key: 'no',
          slots: { customRender: 'no' },
          width: 65,
        },
        {
          title: 'Word',
          dataIndex: 'hashtag',
          key: 'hashtag',
          ellipsis: true,
          width: 100,
          onCell: () => {
            return {
              style: {
                whiteSpace: 'nowrap',
                maxWidth: 100,
              },
            };
          },
        },
        {
          title: 'Engagement',
          dataIndex: 'engagement',
          key: 'engagement',
          width: 110,
        },
        {
          title: '',
          dataIndex: 'percent',
          key: 'percent',
          slots: { customRender: 'percent' },
          width: 110,
        },
      ],
      mention: [
        {
          title: '#',
          dataIndex: 'no',
          key: 'no',
          slots: { customRender: 'no' },
          width: 65,
        },
        {
          title: 'Word',
          dataIndex: 'hashtag',
          key: 'hashtag',
          ellipsis: true,
          width: 100,
          onCell: () => {
            return {
              style: {
                whiteSpace: 'nowrap',
                maxWidth: 100,
              },
            };
          },
        },
        {
          title: 'Mentioned',
          dataIndex: 'engagement',
          key: 'engagement',
          width: 110,
        },
        {
          title: '',
          dataIndex: 'percent',
          key: 'percent',
          slots: { customRender: 'percent' },
          width: 110,
        },
      ],
      view: [
        {
          title: '#',
          dataIndex: 'no',
          key: 'no',
          slots: { customRender: 'no' },
          width: 65,
        },
        {
          title: 'Word',
          dataIndex: 'hashtag',
          key: 'hashtag',
          ellipsis: true,
          width: 100,
          onCell: () => {
            return {
              style: {
                whiteSpace: 'nowrap',
                maxWidth: 100,
              },
            };
          },
        },
        {
          title: 'View',
          dataIndex: 'engagement',
          key: 'engagement',
          width: 110,
        },
        {
          title: '',
          dataIndex: 'percent',
          key: 'percent',
          slots: { customRender: 'percent' },
          width: 110,
        },
      ],
    };

    const columns = ref([]);
    const columnsWords = ref([]);
    const rowClick = (record) => {
      return {
        onClick: () => {
          drilldownFn('("' + record.hashtag + '")');
        },
      };
    };

    // mode by Engagement
    const initHashtagEngagementData = async () => {
      const limit = 20;
      let response;
      response = await api
        .getHashtagEngagement(filterResult.value, brand.value, limit)
        .catch(() => {});
      hashtagDataByMode.engagement = response.message;
      hashtagEngagementData.value = hashtagDataByMode[mode.value];
    };

    const initWordEngagementData = async () => {
      const limit = 20;
      let response = await api
        .getWordEngagement(filterResult.value, brand.value, limit)
        .catch(() => {});
      wordDataByMode.engagement = response.message;
      wordEngagementData.value = wordDataByMode[mode.value];
    };

    // mode by Mentioned
    const initHashtagMentionData = async () => {
      const limit = 20;
      let response;
      response = await api
        .getHashtagCount(filterResult.value, brand.value, limit)
        .catch(() => {});
      hashtagDataByMode.mention = response.message;
      hashtagMentionData.value = hashtagDataByMode[mode.value];
    };

    const initWordMentionData = async () => {
      const limit = 20;
      let response;
      response = await api
        .getWordCount(filterResult.value, brand.value, limit)
        .catch(() => {});
      wordDataByMode.mention = response.message;
      wordMentionData.value = wordDataByMode[mode.value];
    };

    // mode by view
    const initHashtagViewData = async () => {
      const limit = 20;
      let response;
      response = await api
        .getHashtagView(filterResult.value, brand.value, limit)
        .catch(() => {});
      hashtagDataByMode.view = response.message;
      hashtagViewData.value = hashtagDataByMode[mode.value];
    };

    const initWordViewData = async () => {
      const limit = 20;
      let response;
      response = await api
        .getWordView(filterResult.value, brand.value, limit)
        .catch(() => {});
      wordDataByMode.view = response.message;
      wordViewData.value = wordDataByMode[mode.value];
    };

    const convertWordEngagementList = computed(() => {
      let data;
      let sum = 0;
      const list = [];
      totalCount = 0;
      if (wordCloudMode.value === 'hashtag') {
        data = hashtagEngagementData.value;
        if (data && data.length > 0) {
          data.forEach((value) => {
            sum += parseInt(value.count);
          });
          data.forEach((value, index) => {
            list.push({
              no: index + 1,
              hashtag: value.word,
              engagement:
                helper.numeral(value.count) +
                ' (' +
                numeral((value.count / sum) * 100).format('0.00') +
                '%)',
              percent: numeral((value.count / sum) * 100).format('0.00'),
            });
            totalCount += value.count;
          });
        }
      } else if (wordCloudMode.value === 'word') {
        data = wordEngagementData.value;
        if (data && data.length > 0) {
          data.forEach((value) => {
            sum += parseInt(value.count);
          });
          data.forEach((value, index) => {
            list.push({
              no: index + 1,
              hashtag: value.word,
              engagement:
                helper.numeral(value.count) +
                ' (' +
                numeral((value.count / sum) * 100).format('0.00') +
                '%)',
              percent: numeral((value.count / sum) * 100).format('0.00'),
            });
            totalCount += value.count;
          });
        }
      }
      return list;
    });

    const convertWordMentionList = computed(() => {
      let data;
      const list = [];
      let sum = 0;
      totalCount = 0;
      if (wordCloudMode.value === 'hashtag') {
        data = hashtagMentionData.value;
        if (data && data.length > 0) {
          data.forEach((value) => {
            sum += parseInt(value.count);
          });
          data.forEach((value, index) => {
            list.push({
              no: index + 1,
              hashtag: value.word,
              engagement:
                helper.numeral(value.count) +
                ' (' +
                numeral((value.count / sum) * 100).format('0.00') +
                '%)',
              percent: numeral((value.count / sum) * 100).format('0.00'),
            });
            totalCount += value.count;
          });
        }
      } else if (wordCloudMode.value === 'word') {
        data = wordMentionData.value;
        if (data && data.length > 0) {
          data.forEach((value) => {
            sum += parseInt(value.count);
          });
          data.forEach((value, index) => {
            list.push({
              no: index + 1,
              hashtag: value.word,
              engagement:
                helper.numeral(value.count) +
                ' (' +
                numeral((value.count / sum) * 100).format('0.00') +
                '%)',
              percent: numeral((value.count / sum) * 100).format('0.00'),
            });
            totalCount += value.count;
          });
        }
      }
      return list;
    });

    const convertWordViewList = computed(() => {
      let data;
      const list = [];
      let sum = 0;
      totalCount = 0;
      if (wordCloudMode.value === 'hashtag') {
        data = hashtagViewData.value;
        if (data && data.length > 0) {
          data.forEach((value) => {
            sum += parseInt(value.count);
          });
          data.forEach((value, index) => {
            list.push({
              no: index + 1,
              hashtag: value.word,
              engagement:
                helper.numeral(value.count) +
                ' (' +
                numeral((value.count / sum) * 100).format('0.00') +
                '%)',
              percent: numeral((value.count / sum) * 100).format('0.00'),
            });
            totalCount += value.count;
          });
        }
      } else if (wordCloudMode.value === 'word') {
        data = wordViewData.value;
        if (data && data.length > 0) {
          data.forEach((value) => {
            sum += parseInt(value.count);
          });
          data.forEach((value, index) => {
            list.push({
              no: index + 1,
              hashtag: value.word,
              engagement:
                helper.numeral(value.count) +
                ' (' +
                numeral((value.count / sum) * 100).format('0.00') +
                '%)',
              percent: numeral((value.count / sum) * 100).format('0.00'),
            });
            totalCount += value.count;
          });
        }
      }
      return list;
    });

    const wordTable = computed(() => {
      if (mode.value) {
        if (mode.value.toLowerCase() === 'engagement') {
          return convertWordEngagementList.value;
        } else if (mode.value.toLowerCase() === 'mention') {
          return convertWordMentionList.value;
        } else if (mode.value.toLowerCase() === 'view') {
          return convertWordViewList.value;
        } else {
          return convertWordEngagementList.value;
        }
      } else {
        return convertWordEngagementList.value;
      }
    });

    const convertWordCloudEngagement = computed(() => {
      let data;
      const list = [];
      const maxValue = [];
      totalCount = 0;
      if (wordCloudMode.value === 'hashtag') {
        data = hashtagEngagementData.value;
      } else if (wordCloudMode.value === 'word') {
        data = wordEngagementData.value;
      }
      for (var i in data) {
        list.push([data[i]['word'], data[i]['count']]);
        maxValue.push(data[i]['count']);
        totalCount += data[i]['count'];
      }

      maxVal = Math.max.apply(null, maxValue);
      return list;
    });

    const convertWordCloudMention = computed(() => {
      let data;
      const list = [];
      const maxValue = [];
      totalCount = 0;
      if (wordCloudMode.value === 'hashtag') {
        data = hashtagMentionData.value;
      } else if (wordCloudMode.value === 'word') {
        data = wordMentionData.value;
      }
      for (var i in data) {
        list.push([data[i]['word'], data[i]['count']]);
        maxValue.push(data[i]['count']);
        totalCount += data[i]['count'];
      }

      maxVal = Math.max.apply(null, maxValue);
      return list;
    });

    const convertWordCloudView = computed(() => {
      let data;
      const list = [];
      const maxValue = [];
      totalCount = 0;
      if (wordCloudMode.value === 'hashtag') {
        data = hashtagViewData.value;
      } else if (wordCloudMode.value === 'word') {
        data = wordViewData.value;
      }
      for (var i in data) {
        list.push([data[i]['word'], data[i]['count']]);
        maxValue.push(data[i]['count']);
        totalCount += data[i]['count'];
      }

      maxVal = Math.max.apply(null, maxValue);
      return list;
    });

    const wordCloud = computed(() => {
      if (mode.value) {
        if (mode.value.toLowerCase() === 'engagement') {
          return convertWordCloudEngagement.value;
        } else if (mode.value.toLowerCase() === 'mention') {
          return convertWordCloudMention.value;
        } else if (mode.value.toLowerCase() === 'view') {
          return convertWordCloudView.value;
        } else {
          return convertWordCloudEngagement.value;
        }
      } else {
        return convertWordCloudEngagement.value;
      }
    });

    const selectModeType = computed(() => {
      if (mode.value) {
        if (mode.value.toLowerCase() === 'engagement') {
          return convertWordEngagementList.value;
        } else if (mode.value.toLowerCase() === 'mention') {
          return convertWordMentionList.value;
        } else if (mode.value.toLowerCase() === 'view') {
          return convertWordViewList.value;
        } else {
          return convertWordEngagementList.value;
        }
      } else {
        return convertWordEngagementList.value;
      }
    });

    const noData = computed(
      () => !loading.value && selectModeType.value.length == 0
    );

    const drilldownFn = (pointOption) => {
      if (mode.value === 'engagement') {
        sortBy.value = 'engagement_score';
      } else if (mode.value === 'view') {
        sortBy.value = 'view_count';
      } else if (mode.value === 'mention') {
        sortBy.value = 'comment_count';
      }
      let f = {
        payload: {
          title: `Data for "${helper.capitalize(pointOption)}"`,
        },
        criteria: {
          category: filterResult.value.category,
          subCategory: filterResult.value.subCategory,
          time: filterResult.value.time,
          keywordPhrase: pointOption,
          sort: {
            direction: 'desc',
            field: sortBy.value,
          },
          highlight: {
            enable: true,
          },
        },
      };
      store.dispatch('message/showMessageModal', f);
    };

    const initWordCloud = async () => {
      const result = wordCloud.value;
      const minSize = 15;
      const maxSize = 28;
      const options = {
        gridSize: 1,
        drawOutOfBound: false,
        rotateRatio: 0,
        // rotationSteps: 0,
        shuffle: false,
        shrinkToFit: true,
        fontFamily: 'DM Sans, IBM Plex Sans Thai',
        weightFactor: function (size) {
          let p = helper.numeral((size / maxVal) * maxSize);
          if (!isNaN(p)) {
            if (p >= maxSize) p = maxSize;
            if (p <= minSize) p = minSize;
          } else {
            p = 11;
          }
          return p;
        },
        color: function (word, weight) {
          let weightColors = helper.numeral((weight / totalCount) * 100);
          return weightColors > 10
            ? '#009EFA'
            : weightColors > 5
            ? '#44b6f8'
            : '#8BDAFE';
        },
        click: function (item) {
          drilldownFn('("' + item[0] + '")');
        },
      };

      await WordCloud(wc.value, {
        list: result,
        ...options,
      });
    };

    const init = async () => {
      loading.value = true;
      if (mode.value.toLowerCase() === 'engagement') {
        if (wordCloudMode.value === 'hashtag') {
          columns.value = columnByMode[mode.value];
          await initHashtagEngagementData();
        } else if (wordCloudMode.value === 'word') {
          columnsWords.value = columnsWordsByMode[mode.value];
          await initWordEngagementData();
        }
      } else if (mode.value.toLowerCase() === 'mention') {
        if (wordCloudMode.value === 'hashtag') {
          columns.value = columnByMode[mode.value];
          await initHashtagMentionData();
        } else if (wordCloudMode.value === 'word') {
          columnsWords.value = columnsWordsByMode[mode.value];
          await initWordMentionData();
        }
      } else if (mode.value.toLowerCase() === 'view') {
        if (wordCloudMode.value === 'hashtag') {
          columns.value = columnByMode[mode.value];
          await initHashtagViewData();
        } else if (wordCloudMode.value === 'word') {
          columnsWords.value = columnsWordsByMode[mode.value];
          await initWordViewData();
        }
      }
      hideOverflowTitle();
      loading.value = false;
    };

    const hideOverflowTitle = () => {
      const allSegment = document.querySelectorAll('.no-text');
      if (allSegment) {
        allSegment.forEach((obj) => {
          if (obj.offsetWidth > obj.parentElement.offsetWidth) {
            obj.style.visibility = 'hidden';
          } else {
            obj.style.visibility = 'visible';
          }
        });
      }
    };

    watch(
      () => filterResult.value,
      async () => {
        const f = filterResult.value;
        await init(f);
        initWordCloud();
      }
    );

    watch(
      () => wordCloudMode.value,
      async () => {
        const f = filterResult.value;
        await init(f);
        initWordCloud();
      }
    );

    watch(
      () => mode.value,
      async () => {
        const f = filterResult.value;
        await init(f);
        initWordCloud();
      }
    );

    onMounted(async () => {
      const f = filterResult.value;
      await init(f);
      initWordCloud();
    });

    return {
      wordCloudMode,
      modeOptions,
      loading,
      isMobileScreen,
      columns,
      columnsWords,
      wordTable,
      wordCloud,
      wc,
      selectModeType,
      numeral,
      helper,
      hideOverflowTitle,
      rowClick,
      sortBy,
      noData,
    };
  },
};
</script>

<style lang="scss" scoped>
.ant-btn.ant-btn-primary {
  background: rgba(51, 113, 255, 0.1);
  color: rgba(51, 113, 255);
}
.table-trending {
  cursor: pointer;
  padding-right: 15px;
}
.align-right {
  text-align: right;
  padding-right: 40px;
}
.title {
  padding-left: 15px;
  color: #868eae;
  font-size: 12px;
  min-width: 120px;
  flex: 0 0 120px;
  text-transform: capitalize;
}
.word-cloud-page {
  .ant-table-thead > tr:first-child > th:first-child {
    border-radius: 0px !important;
    border-left: 0px !important;
  }
  .ant-table-thead > tr:last-child > th:last-child {
    border-radius: 0px !important;
    border-right: 0px !important;
  }

  .ant-dropdown-menu {
    min-width: 0;
  }

  .loading-wrapper {
    height: 400px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin: 0px 20px;
  }

  .word-cloud-content {
    padding: 24px 0 32px;
    @media (max-width: 1040px) {
      display: block;
    }

    .word-cloud-card {
      align-self: center;
      text-align: center;
      @media (max-width: 1040px) {
        width: 100%;
        max-width: 100%;
      }
      .wordCloud-canvas {
        width: 100%;
        max-width: fit-content;
      }

      @media (max-width: 768px) {
        margin-bottom: 20px;
      }
    }
    .word-list-block {
      min-height: 428px;
      height: 428px;
      overflow-y: auto;
      @media (max-width: 1040px) {
        width: 100%;
        max-width: 100%;
      }

      .table-word-cloud {
        .no-text {
          font-weight: 500;
          color: #272b41;
        }

        .engagement-mobile {
          margin-left: auto;
          margin-right: 0;
        }

        .ant-table-without-column-header table {
          color: #5a5f7d;
        }
      }
    }
  }

  .ant-card-head .ant-card-head-wrapper {
    flex-flow: row;
    align-items: center;
  }
  .ant-card-head-wrapper .ant-card-extra {
    padding: 0px !important;
  }
  .more-menu {
    color: #9299b8;
  }
}
#wc {
  cursor: pointer;
}

.word-cloud-lists {
  width: 100%;
  max-width: 100%;
  margin: 0 auto;
  .list-header-row {
    column-gap: 50px;
    padding-bottom: 2px;
    border-bottom: 1px solid #f1f2f6;
    @media (max-width: 367px) {
      column-gap: 25px;
    }
    @media (max-width: 335px) {
      column-gap: 10px;
    }
    .list-header-col {
      padding: 8px 5px;
      .header-lists {
        font-weight: 500;
        font-size: 14px;
        line-height: 22px;
        color: #272b41;
        text-align: center;
      }
      &:nth-child(3) {
        margin-left: auto;
        margin-right: 17px;
      }
      &:nth-child(4) {
        display: none;
      }
    }
  }
  .list-data-row {
    column-gap: 50px;
    padding-top: 12px;
    padding-bottom: 3px;
    border-bottom: 1px solid #f1f2f6;
    @media (max-width: 367px) {
      column-gap: 25px;
    }
    @media (max-width: 335px) {
      column-gap: 10px;
    }
    .list-data-col {
      padding: 4px 5px;
      .list-data-text {
        font-size: 14px;
        line-height: 22px;
        color: #5a5f7d;
      }
      .no-text {
        font-size: 14px;
        line-height: 22px;
        font-weight: 500;
        color: #272b41;
        text-align: center;
      }
      &:nth-child(3) {
        margin-left: auto;
        margin-right: 17px;
      }
    }
  }
}

.prog-bar-mobile {
  display: block;
  width: -webkit-fill-available;
}

@media (max-width: 768px) {
  .word-cloud-page .word-cloud-content .word-list-block {
    min-height: 700px;
    height: 700px;
  }
  .ant-btn.ant-btn-ghost {
    background: rgba(244, 245, 247, 0.6);
    border-radius: 36px;
    color: #9299b8;
  }
  .btn-line {
    margin-right: 5px;
  }

  .ant-btn.ant-btn-primary > span > .icon-font-case {
    filter: invert(36%) sepia(39%) saturate(3877%) hue-rotate(212deg)
      brightness(99%) contrast(105%);
  }
}
</style>
<style lang="scss">
.ant-progress-status-success .ant-progress-bg,
.ant-progress-bg {
  background-color: #3371ff;
}

.radio-wordcloud
  .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled) {
  background: #3371ff;
  border-color: #3371ff;
}

.radio-wordcloud
  .ant-radio-button-wrapper-checked:not(
    .ant-radio-button-wrapper-disabled
  ):hover {
  background: #0870e6;
  border-color: #0870e6;
}

.radio-wordcloud .ant-radio-button-wrapper:first-child {
  border-top-left-radius: 5px;
  border-bottom-left-radius: 5px;
}

.radio-wordcloud .ant-radio-button-wrapper:last-child {
  border-bottom-right-radius: 5px;
  border-top-right-radius: 5px;
}
</style>
<style scoped>
:deep(.table-word-cloud.table-bordered .ant-table-thead > tr > th) {
  border-top: none;
}
:deep(.table-word-cloud.table-bordered .ant-table-thead tr th) {
  max-width: 65px;
  min-height: 38px;
  padding: 8px 0;
}
:deep(.table-word-cloud.table-bordered .ant-table-thead tr th:nth-child(1)) {
  text-align: center;
}
:deep(.table-word-cloud.table-bordered .ant-table-thead tr th span) {
  font-weight: 500;
  font-size: 14px;
  line-height: 22px;
  color: #272b41;
}
:deep(.table-word-cloud.table-bordered .ant-table-tbody tr td) {
  max-width: 65px;
  min-height: 38px;
  padding: 8px 0;
}
:deep(.table-word-cloud.table-bordered .ant-table-tbody tr td:nth-child(1)) {
  text-align: center;
}
:deep(.table-word-cloud.table-bordered .ant-table-tbody tr td span) {
  font-size: 14px;
  line-height: 22px;
  font-weight: 400;
  color: #5a5f7d;
}
:deep(
    .table-word-cloud.table-bordered .ant-table-tbody tr td:nth-child(1) span
  ) {
  font-weight: 500;
  color: #272b41;
}
:deep(.prog-bar-mobile .ant-progress-outer) {
  margin-top: 4px;
}
</style>
