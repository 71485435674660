/* eslint-disable no-unused-vars, max-len */

import axios from 'axios';

const loginUrl = '/services/login';
const logoutUrl = '/services/logout';
const tokenCheckUrl = '/services/tcheck';
const forgotPasswordUrl = '/services/forgot-password';
const resetPasswordUrl = '/services/reset-password';

const apiMainUrl = '/api';
const authApiUrl = `${apiMainUrl}/auth`;
const analyticApiUrl = `${apiMainUrl}/analytic`;
const corpusApiUrl = `${apiMainUrl}/corpus`;
const corpusV2ApiUrl = `${apiMainUrl}/corpusV2`;
const exportApiUrl = `${apiMainUrl}/export`;
const notificationApiUrl = `${apiMainUrl}/notification`;

// Interceptors for API call
const apiInterceptor = (response) => {
  // Auto extract data from response
  if (response.data) {
    if (response.data.data) {
      return response.data.data;
    }
    return response.data;
  }
  return response;
};

// Setup Global intercepter
axios.interceptors.response.use(apiInterceptor, (error) =>
  Promise.reject(error)
);

// Api Instance + intercepter
const apiInstance = axios.create({
  timeout: 600000,
});
apiInstance.interceptors.response.use(apiInterceptor, (error) =>
  Promise.reject(error)
);

function onExpiredSession(e) {
  if (e.response && e.response.status === 401) {
    if (!window.location.pathname.startsWith('/share-preset')) {
      window.localStorage.removeItem('domv3');
      window.location = '/login?expired=1';
    }
  }
}

function apiRequest(url, method, path, data, params) {
  return apiInstance
    .request({
      method,
      url: `${url}/${path}`,
      data,
      params,
    })
    .catch((e) => {
      // TODO handle more error here if possible
      onExpiredSession(e);
      throw e;
    });
}

function getReq(apiUrl, path, params, data) {
  return apiRequest(apiUrl, 'get', path, undefined, params);
}

function delReq(apiUrl, path, params, data) {
  return apiRequest(apiUrl, 'delete', path, data, params);
}

function postReq(apiUrl, path, data, params) {
  return apiRequest(apiUrl, 'post', path, data, params);
}
function patchReq(apiUrl, path, data, params) {
  return apiRequest(apiUrl, 'patch', path, data, params);
}

function putReq(apiUrl, path, data, params) {
  return apiRequest(apiUrl, 'put', path, data, params);
}

function extractErrorMessage(e) {
  console.log(e.response);
  if (typeof e === 'string') {
    return e;
  }
  if (e.response && e.response.data) {
    const d = e.response.data;
    return d.description || d.message || 'Unknown error';
  }
}

function login(data) {
  const { username, password, brand, mode } = data;
  const grant_type = 'password';
  return axios.post(loginUrl, {
    username,
    password,
    grant_type,
    brand,
    mode,
  });
}

function logout() {
  return axios.get(logoutUrl);
}

function tokenCheck() {
  return axios.get(tokenCheckUrl);
}

function engageUrl(isEngage) {
  return isEngage ? 'engagement' : 'count';
}

const socialSrcList = [
  'facebook',
  'twitter',
  'instagram',
  'youtube',
  'pantip',
  'tiktok',
];

// prettier-ignore
export default {
  // Fixed src
  getSocialSource: () => [...socialSrcList, 'blockdit'],
  getAllSource: () => [...socialSrcList, 'website', 'blockdit'],
  // Handler
  onExpiredSession,
  extractErrorMessage,
  // Internal
  login,
  logout,
  tokenCheck,

  // Analytic
  // -- Drilldown
  getDrilldown: (data) => postReq(analyticApiUrl, 'drilldown', data),
  getEngageDrilldown: (data) => postReq(analyticApiUrl, 'engagement-drilldown', data),
  getInfluencerDrilldown: (data) => postReq(analyticApiUrl, 'influencer/drilldown', data),
  // -- Home
  getHomeSummary: (isEngage) => postReq(analyticApiUrl, `home/${engageUrl(isEngage)}`),
  getHomeSentiment: (isEngage, time) => postReq(analyticApiUrl, `home/sentiment/${engageUrl(isEngage)}`, {}, { time }),
  getHomeWordTrend: (isEngage, time) => postReq(analyticApiUrl, `home/word-trend/${engageUrl(isEngage)}`, {}, { time }),
  getHomeInfluencerTrend: (mode, data) => postReq(analyticApiUrl, `home/influencer-trend/${mode}`, data, { time: 'custom' }),
  getHomeSourceTraffic: (isEngage, time) => postReq(analyticApiUrl, `home/source-traffic/${engageUrl(isEngage)}`, {}, { time }),
  getHomeCategoryTrend: (isEngage, time, level = 0) => postReq(analyticApiUrl, `home/category-trend/${engageUrl(isEngage)}`, {}, { time, level }),
  // -- Graph
  getActiveTimeData: (data, options) => postReq(analyticApiUrl, 'graph/active-time/highchart', data, options),
  getHistoryData: (data, options) => postReq(analyticApiUrl, 'graph/history', data, options),
  getSentimentPie: (data, options) => postReq(analyticApiUrl, 'graph/sentiment-pie', data, options),
  getCategoryPieSentiment: (data, options) => postReq(analyticApiUrl, 'graph/category-pie/sentiment', data, options),
  getCategoryPie: (data, options) => postReq(analyticApiUrl, 'graph/category-pie', data, options),
  getSourcePie: (data, options) => postReq(analyticApiUrl, 'graph/source-pie', data, options),
  getCategoryHistoryGraph: (data, options) => postReq(analyticApiUrl, 'graph/category-history', data, options),
  getSentimentHistoryGraph: (data, options) => postReq(analyticApiUrl, 'graph/sentiment-history', data, options),
  getCategorySentimentHistoryGraph: (data, options) => postReq(analyticApiUrl, 'graph/category-history/sentiment', data, options),
  getCategorySOV: (data, level) => postReq(analyticApiUrl, 'graph/category-sov', data, { level }),
  getOverviewHistory: (data, options) => postReq(analyticApiUrl, 'campaign/channel/overview-history', data, options),
  // -- Location
  getLocation: (data) => postReq(analyticApiUrl, 'location', data),
  getMapDrilldown: (data) => postReq(analyticApiUrl, 'map-drilldown', data),
  getFeedbyID: (id) => getReq(analyticApiUrl, 'search/find-by-id', { id }),
  // -- Influencer
  getInfluencer: (isEngage, data) => postReq(analyticApiUrl, `influencer/get-parent/${engageUrl(isEngage)}`, data),
  getInfluencerByMode: (mode, data) => postReq(analyticApiUrl, `influencer/get-parent/${mode}`, data, { time: 'custom' }),
  getInfluencerChildNode: (isEngage, parentID, data) => postReq(analyticApiUrl, `influencer/get-children/${engageUrl(isEngage)}`, data, { parentIds: parentID }),
  // -- Feed
  getLatestFeed: (data) => postReq(analyticApiUrl, 'feed', data),
  getImageFeed: (data) => postReq(analyticApiUrl, 'feed/image', data),
  getVideoFeed: (data) => postReq(analyticApiUrl, 'feed/video', data),
  getOlderLatestFeed: (data) => postReq(analyticApiUrl, 'feed/seemore', data),
  getOlderImageFeed: (data) => postReq(analyticApiUrl, 'feed/image/seemore', data),
  getOlderVideoFeed: (data) => postReq(analyticApiUrl, 'feed/video/seemore', data),
  // -- Stat
  getSocialStatSummaryData: (data) => postReq(analyticApiUrl, 'stat', data),
  getCategoryPivotStat: (data, level) => postReq(analyticApiUrl, 'stat/categroy-compare/pivot', data, { level: level }),
  getCategoryCompareStat: (data, level) => postReq(analyticApiUrl, 'stat/categroy-compare', data, { level: level }),
  getSentimentStat: (data) => postReq(analyticApiUrl, 'stat/sentiment', data),
  // -- Search
  isLineageAvailable: (id) => getReq(analyticApiUrl, 'search/find-by-id/is-lineage-available', { id }),
  getSearchData: (data) => postReq(analyticApiUrl, 'search', data),
  getSearchImage: (data) => postReq(analyticApiUrl, 'search/image', data),
  getSearchVideo: (data) => postReq(analyticApiUrl, 'search/video', data),
  // -- Seemore
  getSeemoreData: (originId, paging) => postReq(analyticApiUrl, 'seemore', { originId, paging }),
  getSeemoreLineage: (id) => postReq(analyticApiUrl, 'seemore/lineage', null, { 'record-id': id }),
  getNextLineage: (id) => postReq(analyticApiUrl, 'seemore/lineage/next', null, { 'record-id': id, limit: 5 }),
  getPreviousLineage: (id) => postReq(analyticApiUrl, 'seemore/lineage/previous', null, { 'record-id': id, limit: 5 }),
  getLineageReplies: (id) => postReq(analyticApiUrl, 'seemore/lineage/sub', null, { 'record-id': id, limit: 5 }),
  // -- Usage
  getUsageStats: (sinceDate, untilDate) => getReq(analyticApiUrl, 'usage/stats', { sinceDate, untilDate }),
  getTopKeywordStat: () => postReq(analyticApiUrl, 'usage/stat/top-keyword', {}),
  // -- Message
  rejectMessage: (id, mode) => putReq(analyticApiUrl, 'message/reject', null, { 'message-id': id, mode }),
  markAsAlert: (id) => putReq(analyticApiUrl, 'message/alert', null, { 'message-id': id }),
  markAsUnalert: (id) => putReq(analyticApiUrl, 'message/unalert', null, { 'message-id': id }),
  markAsReviewed: (id) => putReq(analyticApiUrl, 'message/reviewed', null, { 'message-id': id }),
  markAsUnreviewed: (id) => putReq(analyticApiUrl, 'message/unreviewed', null, { 'message-id': id }),
  markAsRead: (id) => putReq(analyticApiUrl, 'message/read', null, { 'message-id': id }),
  markAsUnread: (id) => putReq(analyticApiUrl, 'message/unread', null, { 'message-id': id }),
  updateSentiment: (id, mode, sentiment) => putReq(analyticApiUrl, 'message/sentiment-edit', null, { 'message-id': id, mode, sentiment }),
  updateCategory: (id, mode, category) => putReq(analyticApiUrl, 'message/sub-category-edit', category, { 'message-id': id, mode }),

  //hashtag
  getHashtagCount: (data, brand, limit) => postReq(analyticApiUrl, 'hashtag-count', data, { account: brand, limit }),
  getHashtagEngagement: (data, brand, limit) => postReq(analyticApiUrl, 'hashtag-engagement', data, { account: brand, limit }),
  getHashtagView: (data, brand, limit) => postReq(analyticApiUrl, 'hashtag-view', data, { account: brand, limit }),

  //word
  getWordCount: (data, brand, limit) => postReq(analyticApiUrl, 'word-count', data, { account: brand, limit }),
  getWordEngagement: (data, brand, limit) => postReq(analyticApiUrl, 'word-engagement', data, { account: brand, limit }),
  getWordView: (data, brand, limit) => postReq(analyticApiUrl, 'word-view', data, { account: brand, limit }),

  // Export
  newExport: (data, fileType, fileName) => postReq(exportApiUrl, 'export', data, { fileType, fileName, offset: 7 }),
  newExport32: (data, fileType, fileName) => postReq(exportApiUrl, 'v3-dot-2-export', data, { fileType, fileName, offset: 7 }),
  getExportStatus: (user, pageNumber) => postReq(exportApiUrl, 'userexportinfo', { statuses: [] }, { user, pageNumber }),
  setDownloadedStatus: (ticketID) => getReq(exportApiUrl, 'setdownloaded', { ticketID }),
  setRetryStatus: (ticketID) => getReq(exportApiUrl, 'retryexport', { ticketID }),
  removeExport: (ticketID) => getReq(exportApiUrl, 'removeexport', { ticketID }),
  // CorpusV2
  getAccountCategory: () => getReq(corpusV2ApiUrl, 'category/get-account-category'),
  forgotPassword: (account, email) => postReq(forgotPasswordUrl, '', { account, email }),
  resetPassword: (token, password) => postReq(resetPasswordUrl, '', { token, password }),

  getInstagramUserByAccountId: () => getReq(corpusV2ApiUrl, 'instagram/get-account-users'),
  updateInstagramUserByAccountId: (accountId, { link, isConsiderKeyword }) => postReq(corpusV2ApiUrl, 'instagram/update-user', { link, isConsiderKeyword }, { 'account-id': accountId }),
  addInstagramUserByAccountId: (accountId, { link, isConsiderKeyword }) => postReq(corpusV2ApiUrl, 'instagram/add-user', { link, isConsiderKeyword }, { 'account-id': accountId }),
  deleteInstagramUserByAccountId: (link) => postReq(corpusV2ApiUrl, 'instagram/delete-user', null, { link }),
  resolveInstagramUser: (link) => postReq(corpusV2ApiUrl, 'instagram/resolve-user', null, { link }),
  bulkDeleteInstagramUser: (userAccountId) => delReq(corpusV2ApiUrl, 'instagram/bulk-delete-user', null, { userAccountId }),

  addInstagramUserAccessToken: (accessToken) => postReq(corpusV2ApiUrl, 'instagram/add-user-access-token', { accessToken }),
  addFanpageByAccessToken: (accessToken) => postReq(corpusApiUrl, 'fanpage/add-fanpage-by-token', { accessToken }),

  getYoutubeChannelByAccountId: () => getReq(corpusV2ApiUrl, 'youtube/get-account-channels'),
  updateYoutubeChannelByAccountId: (accountId, { link, isConsiderKeyword }) => postReq(corpusV2ApiUrl, 'youtube/update-channel', { link, isConsiderKeyword, name }, { 'account-id': accountId }),
  addYoutubeChannelByAccountId: (accountId, { link, isConsiderKeyword, name }) => postReq(corpusV2ApiUrl, 'youtube/add-channel', { link, isConsiderKeyword, name }, { 'account-id': accountId }),
  deleteYoutubeChannelByAccountId: (link) => postReq(corpusV2ApiUrl, 'youtube/delete-channel', null, { link }),
  resolveYoutubeChannel: (link) => postReq(corpusV2ApiUrl, 'youtube/resolve-channel', null, { link }),
  bulkDeleteYoutubeChannel: (channelAccountId) => delReq(corpusV2ApiUrl, 'youtube/bulk-delete-channel', null, { channelAccountId }),

  getPaginateSocialMediaGroups: (search, skip, limit) => getReq(corpusV2ApiUrl, 'media-groups/get-paginate', { search, skip, limit }),
  getSocialMediaGroups: () => getReq(corpusV2ApiUrl, 'media-groups/get-all'),
  updateSocialMediaGroupById: (id, data) => putReq(corpusV2ApiUrl, 'media-groups/update', data, { id }),
  addSocialMediaGroup: (data) => postReq(corpusV2ApiUrl, 'media-groups/create', data),
  deleteSocialMediaGroup: (id) => delReq(corpusV2ApiUrl, 'media-groups/delete', { id }),
  resolveFacebookPageInfo: (link) => getReq(corpusApiUrl, 'fanpage/resolve-fanpage-url', { 'fanpage-url': link }),
  bulkDeleteFacebookFanpage: (fanpageId) => delReq(corpusApiUrl, 'fanpage/bulk-delete-fanpage', null, { fanpageId }),
  // resolveFacebookPageInfo:(link) => postReq(corpusApiUrl,'fanpage/resolve-fanpage-url',null,{'fanpage-url':link}),

  getAllSocialMediaGroupByAccountId: (accountId) => getReq(corpusV2ApiUrl, 'media-groups', { 'account-id': accountId }),
  getSocialMediaUsers: (username) => getReq(analyticApiUrl, 'username/search', { username }),

  // -- Category group
  addCategoryGroup: (groupName) => postReq(corpusV2ApiUrl, 'category/add-category-group', null, { groupName }),
  renameCategoryGroup: (level, newGroupName) => putReq(corpusV2ApiUrl, 'category/rename-category-group', null, { level, newGroupName }),
  deleteLatestCategoryGroup: () => delReq(corpusV2ApiUrl, 'category/delete-latest-category-group'),
  deleteCategoryGroup: (level) => delReq(corpusV2ApiUrl, 'category/delete-category-group', { level }),
  changeCategoryGroupOrder: (categoryLevelIdList) => postReq(corpusV2ApiUrl, 'category/change-category-group-order', categoryLevelIdList),
  // -- Category
  addCategory: (level, category, color, isVisible) => postReq(corpusV2ApiUrl, 'category/add-category', null, { level, category, color, isVisible }),
  editCategory: (level, category, color, isVisible) => putReq(corpusV2ApiUrl, 'category/update-category', { color, isVisible }, { category, level }),
  deleteCategory: (level, category) => delReq(corpusV2ApiUrl, 'category/delete-category', { level, category }),
  addVisibleCategory: (level, category) => postReq(corpusV2ApiUrl, 'category/add-visible-category', null, { level, category }),
  deleteVisibleCategory: (level, category) => delReq(corpusV2ApiUrl, 'category/delete-visible-category', { level, category }),
  // -- Category Keyword
  getCategoryKeywords: (level, category) => getReq(corpusV2ApiUrl, 'category/get-keyword-from-category', { level, category }),
  getCategoryExcludeKeywords: (level, category) => getReq(corpusV2ApiUrl, 'category/get-exclude-keyword-from-category', { level, category }),
  getKeywordStat: (keywords) => postReq(analyticApiUrl, 'usage/stat/keyword', { keywords }),
  addKeywordToCategory: (level, id, category, value, isExcluded) => postReq(corpusV2ApiUrl, 'category/add-keyword-to-category', { level, id, category, value, isExcluded }),
  deleteCategoryKeyword: (level, id, category) => delReq(corpusV2ApiUrl, 'category/delete-keyword-from-category', null, { level, id, category }),
  deleteCategoryExcludeKeyword: (level, id, category) => delReq(corpusV2ApiUrl, 'category/delete-exclude-keyword-from-category', null, { level, id, category }),
  updateCategoryKeyword: (level, oldWord, category, value) => putReq(corpusV2ApiUrl, 'category/update-keyword-from-category', { level, oldWord, category, value }),
  updateCategoryExcludeKeyword: (level, oldWord, category, value) => putReq(corpusV2ApiUrl, 'category/update-exclude-keyword-from-category', { level, oldWord, category, value }),
  // -- Bulk category keyword
  bulkCreateCategoryKeyword: (level, category, keywords) => postReq(corpusV2ApiUrl, 'category/bulk-add-keyword-to-category', keywords, { level, category }),
  bulkCreateCategoryExcludeKeyword: (level, category, keywords) => postReq(corpusV2ApiUrl, 'category/bulk-add-exclude-keyword-to-category', keywords, { level, category }),
  bulkDeleteCategoryKeyword: (level, category, keywords) => delReq(corpusV2ApiUrl, 'category/bulk-delete-keyword-from-category', { level, category }, { word: keywords }),
  bulkDeleteCategoryExcludeKeyword: (level, category, keywords) => delReq(corpusV2ApiUrl, 'category/bulk-delete-exclude-keyword-from-category', { level, category }, { word: keywords }),

  // -- Campaign
  getCampaignMenu: () => getReq(corpusV2ApiUrl, 'campaign'),
  getCampaignPerformanceOverall: (data, brand) => postReq(analyticApiUrl, 'campaign/performance/overall', data, { account: brand }),
  addCampaign: (data, brand, category, level, isVisible) => postReq(corpusV2ApiUrl, 'category/add-category-with-keyword', data, { account: brand, category, 'is-visible': isVisible, color: 'none', level }),
  getCampaignSentimentSource: (data, brand) => postReq(analyticApiUrl, 'stat/source/sentiment', data, { account: brand }),
  getCampaignPerformanceChannelTimeline: (data, brand) => postReq(analyticApiUrl, 'campaign/performance/channel-timeline', data, { account: brand }),
  getCampaignPerformanceChannelSOV: (data, brand) => postReq(analyticApiUrl, 'campaign/performance/channel-sov', data, { account: brand }),
  getCampaignComparsionOverview: (data, brand, level) => postReq(analyticApiUrl, 'campaign/comparison/overall', data, { account: brand, level }),
  getCampaignHistoryGraph: (data, brand, level) => postReq(analyticApiUrl, 'graph/category-history', data, { account: brand, level }),

  // -- User Preset Campaign

  // -- Filter Preset
  getUserFilterPreset: ({ isEnableCampaign, presetName, sortDirection = 'DESC', sortField = 'UPDATE_AT' }) => postReq(corpusApiUrl, 'filter-preset', { isEnableCampaign, presetName, sortDirection, sortField }),
  getUserPresetCampaign: () => getReq(corpusApiUrl, 'user-preset-campaign'),
  getUserPresetCampaignById: (userPresetCampaignId) => getReq(corpusApiUrl, `user-preset-campaign/${userPresetCampaignId}`),
  getUserFilterPresetById: ({ presetId }) => getReq(corpusApiUrl, `filter-preset/${presetId}`),
  getUserFilterPresetByName: ({ name }) => getReq(corpusApiUrl, 'filter-preset/preset-by-name',{name}),

  addUserFilterPreset: (data) => postReq(corpusApiUrl, 'filter-preset/add-preset', data),
  updateUserFilterPreset: (presetId, { presetName, presetDescription, criteria, isEnableCampaign, isShareCampaign, shareUser }) =>
    patchReq(corpusApiUrl, `filter-preset/${presetId}/update`, { presetName, presetDescription, criteria, isEnableCampaign, isShareCampaign, shareUser }),
  changeStateUserFilterPreset: (presetId, { stateChange, typeChange }) => postReq(corpusApiUrl, `filter-preset/${presetId}/change-state`, { stateChange, typeChange }),
  deleteUserFilterPreset: (presetId) => delReq(corpusApiUrl, `filter-preset/${presetId}`),

  // CorpusV1
  // -- Bulk keyword
  bulkCreateKeyword: (data) => postReq(corpusApiUrl, 'keyword/bulk-add-account-keyword', data),
  bulkCreateExcludeKeyword: (data) => postReq(corpusApiUrl, 'keyword/bulk-add-account-exclude-keyword', data),
  bulkDeleteKeyword: (data) => delReq(corpusApiUrl, 'keyword/bulk-delete-account-keyword', null, { word: data }),
  bulkDeleteExcludeKeyword: (data) => delReq(corpusApiUrl, 'keyword/bulk-delete-account-exclude-keyword', null, { word: data }),
  // -- Bulk User
  bulkDeleteUser: (data) => delReq(corpusApiUrl, 'user/bulk-delete-by-id', null, data),
  // -- Legacy
  addAccountKeyword: (data) => postReq(corpusApiUrl, 'keyword/add-account-keyword', data),
  getAccountKeyword: () => getReq(corpusApiUrl, 'keyword/get-account-keyword'),
  deleteAccountKeyword: (keywordId) => delReq(corpusApiUrl, 'keyword/delete-account-keyword', { keyword: keywordId }),
  addAccountExcludedKeyword: (data) => postReq(corpusApiUrl, 'keyword/add-account-exclude-keyword', data),
  getAccountExcludedKeyword: () => getReq(corpusApiUrl, 'keyword/get-account-exclude-keyword'),
  getCentralExcludedKeyword: (excludeAccountsData) => getReq(corpusV2ApiUrl, 'central-exclude-keyword/get-all-keyword', { excludeAccounts: excludeAccountsData }),
  deleteAccountExcludedKeyword: (keywordId) => delReq(corpusApiUrl, 'keyword/delete-account-exclude-keyword', { keyword: keywordId }),
  updateAccountKeyword: (data) => putReq(corpusApiUrl, 'keyword/update-account-keyword', data),
  updateAccountExcludeKeyword: (data) => putReq(corpusApiUrl, 'keyword/update-account-exclude-keyword', data),
  getAccountFanpage: () => getReq(corpusApiUrl, '/fanpage/get-fanpage-list'),
  addAccountFanpage: (data) => postReq(corpusApiUrl, '/fanpage/add-fanpage', data, { 'account-id': data.accountId }),
  updateAccountFanpage: (data) => putReq(corpusApiUrl, '/fanpage/update-fanpage', data, { 'account-id': data.accountId }),
  deleteAccountFanpage: (accountId, fanpageId) => delReq(corpusApiUrl, '/fanpage/delete-fanpage', { 'fanpage-id': fanpageId, 'account-id': accountId }),
  changeUserPassword: (options) => putReq(corpusApiUrl, 'user/change-password', null, options),
  getUserList: () => getReq(corpusApiUrl, 'user/get-all-account/admin', { type: 'all' }),
  getAllUsersByAccount: () => getReq(corpusApiUrl, 'user/get-all-users', ),
  removeUser: (id, role) => delReq(corpusApiUrl, `user/delete-by-id/${role}`, { id }),
  addUser: (data) => postReq(corpusApiUrl, `user/add-user/${data.role}`, data),
  updateUser: (data, options) => putReq(corpusApiUrl, `user/update-by-id/${data.role}`, data, options),
  getAlertKeyword: () => getReq(corpusApiUrl, 'keyword/get-account-alert-keyword'),
  addAlertKeyword: (id, value) => postReq(corpusApiUrl, 'keyword/add-account-alert-keyword', { id, value }),
  removeAlertKeyword: (keyword) => delReq(corpusApiUrl, 'keyword/delete-account-alert-keyword', { keyword }),
  bulkAlertKeywordLists: (keywordId) => delReq(corpusApiUrl, 'keyword/bulk-delete-account-alert-keyword', null, { word: keywordId }),
  editAlertKeyword: (id, value) => putReq(corpusApiUrl, 'keyword/update-account-alert-keyword', { id, value }),
  getAllLocation: () => getReq(corpusApiUrl, 'location/', { noop: true }),
  getLocationBoundary: (id) => getReq(corpusApiUrl, `location/${id}/boundary`, { noop: true }),
  getLocationBoundaryNew: (id) => getReq(corpusApiUrl, `location/${id}/boundary-new`, { noop: true }),
  getAccountStatSummary: (id) => getReq(corpusApiUrl, 'account/stat-summary', { id }),

  getConsent: () => getReq(corpusApiUrl, 'user/consent'),
  acceptConsent: () => postReq(corpusApiUrl, 'user/consent/accept'),

  // -- Bulk Edit in Explore
  bulkMarkAsRead: (idList) => putReq(analyticApiUrl, 'message/bulk-read', { 'message-id-list': idList }, null),
  bulkRejectMessage: (idList) => putReq(analyticApiUrl, 'message/bulk-reject', { 'message-id-list': idList }, null),
  bulkUpdateSentiment: (idList, sentiment) => putReq(analyticApiUrl, 'message/bulk-sentiment-edit', { 'message-id-list': idList }, { sentiment: sentiment }),

  getKeywordPagination: (page, size, sortBy, order) => getReq(corpusApiUrl, 'keyword/get-account-keyword', { page: page, size: size, sortBy: sortBy, order: order }),
};
