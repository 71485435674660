import { AdvanceFilterType } from '@/types/AdvanceSearchFilterType';
import api from './api';
import {
  ConvertedPresetType,
  PresetType,
} from '@/types/preset-management/PresetType';

export default {
  convertSourceToRawSource(sources: string[], sourceTreeData: any) {
    const socialSources = api.getSocialSource();
    const defaultWebsiteSources = sourceTreeData.defaultState.Website;
    const selectedSources = {
      Website: [] as string[],
    };
    for (const source of sources) {
      if (socialSources.includes(source)) {
        selectedSources[source] = true;
      } else {
        selectedSources['Website'].push(`website::${source}`);
      }
    }

    const socialList: string[] = [];
    const websiteList: string[] = [];
    Object.keys(selectedSources).forEach((srcName) => {
      if (srcName === 'Website') {
        // if select all convert to 'website' word
        if (
          selectedSources.Website.length == 1 &&
          selectedSources.Website[0] === 'website::website'
        ) {
          selectedSources.Website = defaultWebsiteSources;
        }

        if (selectedSources.Website.length === defaultWebsiteSources.length) {
          websiteList.push('website');
          selectedSources['website'] = true;
        } else {
          for (const c of selectedSources.Website) {
            const [_, sourceName] = c.split('::');
            websiteList.push(sourceName);
          }
        }
      } else if (
        selectedSources[srcName] &&
        srcName !== 'all Channels' &&
        srcName !== 'website'
      ) {
        socialList.push(srcName);
      }
    });
    const finalList = [...socialList, ...websiteList];
    const deselectSource = {};
    for (const key in sourceTreeData.defaultState) {
      const checked = sourceTreeData.defaultState[key];
      if (typeof checked === 'boolean') {
        deselectSource[key] = false;
      }
    }

    let isSelectAll = true;
    for (const source of socialSources) {
      if (!selectedSources[source]) {
        isSelectAll = false;
        break;
      }
    }
    if (!selectedSources['website']) {
      isSelectAll = false;
    }

    selectedSources['all Channels'] = isSelectAll;

    return {
      rawSource: Object.assign(deselectSource, selectedSources),
      source: finalList,
    };
  },
  convertCategoryToRawCategory(
    category: string[],
    subCategory: { level: string; category: string[] }[],
    categoryTreeData: any
  ) {
    const categoryAndLevel = {};
    for (const cat of categoryTreeData.tree) {
      const catLevel = Number(cat.level);
      if (catLevel === 0) {
        if (category.length > 0) {
          categoryAndLevel[cat.name] = category.map((cat) => `0::${cat}`);
        } else {
          categoryAndLevel[cat.name] = cat.children.map((v) => v.value);
        }
      } else {
        const subCat = subCategory.find(
          (cat) => Number(cat.level) === catLevel
        );
        if (subCat) {
          if (subCat.category.length > 0) {
            categoryAndLevel[cat.name] = subCat.category.map(
              (cat) => `${catLevel}::${cat}`
            );
          } else {
            categoryAndLevel[cat.name] = cat.children.map((v) => v.value);
          }
        } else {
          categoryAndLevel[cat.name] = cat.children.map((v) => v.value);
        }
      }
    }
    return categoryAndLevel;
  },
  prepareCriteria(filter: AdvanceFilterType, sourceTreeData, categoryTreeData) {
    const newFilter = { ...filter } as any;
    const { source, rawSource } = this.convertSourceToRawSource(
      newFilter.source,
      sourceTreeData
    );
    newFilter.rawSource = rawSource;
    newFilter.source = source;
    const rawCategory = this.convertCategoryToRawCategory(
      newFilter.category,
      newFilter.subCategory,
      categoryTreeData
    );
    newFilter.rawCategory = rawCategory;
    return newFilter;
  },
  convertPreset(
    data: PresetType,
    sourceTreeData: any,
    categoryTreeData: any
  ): ConvertedPresetType | null {
    if (!data) return null;

    let filter = JSON.parse(data.criteria);

    filter = this.prepareCriteria(filter, sourceTreeData, categoryTreeData);
    filter.matchCategoryMode = 'any';
    return {
      id: data.id,
      user: data.user,
      username: data.username,
      preset: {
        id: data.id,
        presetName: data.presetName,
        presetDescription: data.presetDescription,
        createdAt: data.createdAt,
        updatedAt: data.updatedAt,
        isFavorite: data.isFavorite,
        link: data.link,
      },
      filter,
      campaign: {
        isEnableCampaign: data.isEnableCampaign,
        shareUser: data.shareUser,
        isShareCampaign: data.isShareCampaign,
      },
    };
  },
  convertPresets(
    presets: PresetType[],
    sourceTreeData: any,
    categoryTreeData: any
  ) {
    return presets.map((data) => {
      return this.convertPreset(data, sourceTreeData, categoryTreeData);
    });
  },
  areObjectsEqual(obj1, obj2) {
    // Check if both are objects
    if (
      typeof obj1 !== 'object' ||
      typeof obj2 !== 'object' ||
      obj1 === null ||
      obj2 === null
    ) {
      return obj1 === obj2; // Direct comparison for non-objects
    }

    // Check if they have the same number of keys
    const keys1 = Object.keys(obj1);
    const keys2 = Object.keys(obj2);
    if (keys1.length !== keys2.length) {
      return false;
    }

    // Compare each key-value pair
    for (const key of keys1) {
      if (!keys2.includes(key) || !this.areObjectsEqual(obj1[key], obj2[key])) {
        return false;
      }
    }

    return true;
  },
};
