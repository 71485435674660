<template>
  <a-card title="Share of voice">
    <template #extra>
      <div class="category-top-right-action d-flex">
        <slot
          name="category"
          v-bind:noResult="noResult"
        ></slot>
      </div>
    </template>
    <a-row :gutter="25">
      <a-col
        :xl="24"
        :lg="24"
        :xs="24"
      >
        <a-skeleton
          v-if="loading"
          class="loading-wrapper"
          :loading="loading"
          active
        />
        <NoResult
          v-else-if="!loading && noResult"
          class="piechart-no-data"
        />
      </a-col>
      <a-col
        :xl="12"
        :lg="24"
        :xs="24"
        class="category-col"
      >
        <div
          v-if="!loading && selectedData && selectedData.length !== 0"
          class="piechart-content"
        >
          <EChart
            v-if="!loading"
            :type="'pie'"
            :data="selectedData"
            :level="qs.level"
            :doughnut-chart="false"
            :chart-type="'category'"
            :category-by-level="categoryByLevel"
            @chartClick="onChartClick"
          />
        </div>
      </a-col>
      <a-col
        v-if="!loading && !noResult"
        :xl="12"
        :lg="24"
        :xs="24"
        class="sentiment-col"
      >
        <div class="item-wrapper">
          <a-row class="sentiment-item-row sentiment-item-row-header">
            <a-col class="sentiment-item-col"
              ><span class="title-header title-count">#</span></a-col
            >
            <a-col class="sentiment-item-col"
              ><span class="title-header title-name-header"
                >Category</span
              ></a-col
            >
            <a-col class="sentiment-item-col sentiment-prog-header"
              ><span class="title-header prog-bar">Sentiment</span></a-col
            >
            <a-col class="sentiment-item-col"
              ><span class="title-header">{{
                mode === 'mention' ? 'mentioned' : mode
              }}</span></a-col
            >
          </a-row>
          <div
            v-for="(c, index) in sentimentData"
            :key="`${c.name}-${index}`"
            class="sentiment-data"
          >
            <a-row
              v-if="!c.noData"
              class="sentiment-item-row"
            >
              <a-col class="sentiment-item-col"
                ><span class="title-count-number">{{ index + 1 }}</span></a-col
              >
              <a-col class="sentiment-item-col"
                ><span class="title-name">
                  <a-badge :color="getColor(c.name, index)"></a-badge>
                  {{ c.name }}
                </span></a-col
              >
              <div class="prog-bar prob-bar-desktop">
                <span
                  v-for="sentiment in c.data"
                  :key="sentiment.name"
                  class="prog-segment has-data"
                  :class="sentiment.name"
                  :style="{ width: sentiment.percent + '%' }"
                  :title="`${sentiment.name}: ${sentiment.percent}% (${sentiment.value})`"
                  @click="onSegmentClick(c.name, sentiment.name)"
                >
                  <span class="prog-inner-element">
                    {{ sentiment.percent }}%
                  </span>
                </span>
              </div>
              <a-col class="sentiment-item-col sumTotal-col"
                ><span class="title"
                  >{{ numeral(c.sum).format('0,0')
                  }}<span class="text-gray">
                    {{
                      ' (' +
                      numeral((c.sum / c.sumTotal) * 100).format('0.00') +
                      '%)'
                    }}</span
                  ></span
                ></a-col
              >
            </a-row>
            <div class="prog-bar prog-bar-mobile">
              <span
                v-for="sentiment in c.data"
                :key="sentiment.name"
                class="prog-segment has-data prog-segment-mobile"
                :class="sentiment.name"
                :style="{ width: sentiment.percent + '%' }"
                :title="`${sentiment.name}: ${sentiment.percent}% (${sentiment.value})`"
                @click="onSegmentClick(c.name, sentiment.name)"
              ></span>
            </div>
          </div>
        </div>
      </a-col>
    </a-row>
  </a-card>
</template>

<script>
import {
  ref,
  reactive,
  computed,
  toRefs,
  watch,
  onMounted,
  nextTick,
} from 'vue';
import { useStore } from 'vuex';
import api from '@/services/api';
import numeral from 'numeral';
import EChart from '@/components/Chart/Echart.vue';
import ProgressBar from '@/components/Chart/ProgressBar.vue';
import helper from '@/services/helper';
import NoResult from '../../../components/Error/NoResult.vue';

const ChannelAndCategory = {
  name: 'ChartAndSentiment',
  props: {
    type: String,
    mode: String,
    filterResult: Object,
    categoryByLevel: Object,
  },
  components: {
    EChart,
    ProgressBarList: ProgressBar,
    NoResult,
  },
  setup(props) {
    let { mode, categoryByLevel, filterResult, type } = toRefs(props);
    const { state, dispatch } = useStore();
    const store = useStore();
    const isMobileScreen = computed(() => store.state.isMobileScreen);
    const categoryData = computed(() => state.config.categoryData);
    const campaignLevel = computed(
      () => store.state.account.campaignMenuData.categoryLevel
    );
    let categoryLevel = ref(0);
    const modeOptions = [
      { label: 'Engagement', value: 'engagement' },
      { label: 'Mentioned', value: 'mention' },
      // { label: 'view', value: 'View' },
    ];
    const sentimentData = ref([]);
    const sumSentiment = ref([]);
    const sentimentDataByMode = reactive({
      engagement: [],
      mention: [],
      view: [],
    });
    let loading = ref(true);
    let engagementData = ref([]);
    let countData = ref([]);
    let viewData = ref([]);
    let sortBy = ref();
    let qs = reactive({
      'chart-type': 'pie',
      level: 0,
    });

    const selectedData = computed(() => {
      if (mode.value) {
        if (mode.value.toLowerCase() === 'engagement') {
          return engagementData.value;
        } else if (mode.value.toLowerCase() === 'mention') {
          return countData.value;
        } else if (mode.value.toLowerCase() === 'view') {
          return viewData.value;
        } else {
          return engagementData.value;
        }
      } else {
        return engagementData.value;
      }
    });

    const noResult = computed(() => {
      // channel pie chart is no data
      if (selectedData.value) {
        return selectedData.value.every((ele) => ele.value === 0);
      }
      return true;
    });

    const visibleCategories = computed(() => {
      if (
        categoryByLevel.value &&
        categoryData.value &&
        categoryData.value[categoryByLevel.value.level] &&
        categoryData.value[categoryByLevel.value.level].categories
      ) {
        return categoryData.value[categoryByLevel.value.level].categories
          .filter((cat) => cat.visible)
          .map((cat) => cat.category);
      } else {
        return [];
      }
    });

    const getCategory = async () => {
      let result = await api
        .getCategoryPie(filterResult.value, qs)
        .catch(() => {});
      let engagementDataObjResponse = null;
      let countDataObjResponse = null;
      let viewDataObjResponse = null;

      if (
        result &&
        result.message != null &&
        result.message.graphData != null &&
        result.message.graphData.engagement != null &&
        result.message.graphData.count != null &&
        result.message.graphData.view != null
      ) {
        engagementDataObjResponse = result.message.graphData.engagement;
        countDataObjResponse = result.message.graphData.count;
        viewDataObjResponse = result.message.graphData.view;
      }

      // Engagement mode
      let engagementResult = [];
      if (engagementDataObjResponse !== null) {
        // check visible data
        let visibleEngagementDataTotal = 0;
        let visibleEngagementData = [];
        for (const [key, value] of Object.entries(engagementDataObjResponse)) {
          if (visibleCategories.value.includes(key)) {
            visibleEngagementDataTotal = visibleEngagementDataTotal + value;
            visibleEngagementData.push({
              name: key,
              value: value,
            });
          }
        }

        if (visibleEngagementData && visibleEngagementData.length) {
          for (let [index, data] of visibleEngagementData.entries()) {
            const color = getColor(data.name.toLowerCase(), parseInt(index));
            let valueAsPercent = convertValueToPercent(
              data.value,
              visibleEngagementDataTotal
            );
            engagementResult.push({
              name: data.name
                ? data.name.charAt(0).toUpperCase() + data.name.slice(1)
                : data.name,
              value: data.value,
              valueAsPercent: valueAsPercent,
              itemStyle: {
                color: color,
              },
            });
          }
        }
      }

      if (engagementResult && engagementResult.length) {
        engagementData.value = sortOther(
          engagementResult.sort((a, b) =>
            a.value > b.value ? -1 : b.value > a.value ? 1 : 0
          )
        );
      } else {
        engagementData.value = sortOther(engagementResult);
      }

      // Count (Mentioned) mode
      let countResult = [];
      if (countDataObjResponse !== null) {
        // check visible data
        let visibleCountDataTotal = 0;
        let visibleCountData = [];
        for (const [key, value] of Object.entries(countDataObjResponse)) {
          if (visibleCategories.value.includes(key)) {
            visibleCountDataTotal = visibleCountDataTotal + value;
            visibleCountData.push({
              name: key,
              value: value,
            });
          }
        }

        if (visibleCountData && visibleCountData.length) {
          for (let [index, data] of visibleCountData.entries()) {
            const color = getColor(data.name.toLowerCase(), parseInt(index));
            // const color = chartHelper.getColor(data.name.toLowerCase(), parseInt(index));
            // check data >= 2%
            // if (data.value && (data.value * 100) / visibleCountDataTotal >= 2) {
            let valueAsPercent = convertValueToPercent(
              data.value,
              visibleCountDataTotal
            );
            countResult.push({
              name: data.name
                ? data.name.charAt(0).toUpperCase() + data.name.slice(1)
                : data.name,
              value: data.value,
              valueAsPercent: valueAsPercent,
              itemStyle: {
                color: color,
              },
            });
            // }
          }
        }

        if (countResult && countResult.length) {
          countData.value = sortOther(
            countResult.sort((a, b) =>
              a.value > b.value ? -1 : b.value > a.value ? 1 : 0
            )
          );
        } else {
          countData.value = sortOther(countResult);
        }
      }

      // view mode
      let viewResult = [];
      if (viewDataObjResponse !== null) {
        // check visible data
        let visibleViewDataTotal = 0;
        let visibleViewData = [];
        for (const [key, value] of Object.entries(viewDataObjResponse)) {
          if (visibleCategories.value.includes(key)) {
            visibleViewDataTotal = visibleViewDataTotal + value;
            visibleViewData.push({
              name: key,
              value: value,
            });
          }
        }

        if (visibleViewData && visibleViewData.length) {
          for (let [index, data] of visibleViewData.entries()) {
            const color = getColor(data.name.toLowerCase(), parseInt(index));
            // const color = chartHelper.getColor(data.name.toLowerCase(), parseInt(index));
            // check data >= 2%
            // if (data.value && (data.value * 100) / visibleViewDataTotal >= 2) {
            let valueAsPercent = convertValueToPercent(
              data.value,
              visibleViewDataTotal
            );
            viewResult.push({
              name: data.name
                ? data.name.charAt(0).toUpperCase() + data.name.slice(1)
                : data.name,
              value: data.value,
              valueAsPercent: valueAsPercent,
              itemStyle: {
                color: color,
              },
            });
            // }
          }
        }

        if (viewResult && viewResult.length) {
          viewData.value = sortOther(
            viewResult.sort((a, b) =>
              a.value > b.value ? -1 : b.value > a.value ? 1 : 0
            )
          );
        } else {
          viewData.value = sortOther(viewResult);
        }
      }
    };

    const sortOther = (lists) => {
      return lists.sort((a, b) => {
        if (a.name === 'Others') {
          return 1;
        } else if (b.name === 'Others') {
          return -1;
        } else {
          return 0;
        }
      });
    };

    const convertPortionData = (key, obj) => {
      if (
        obj.negativePercent === 0 &&
        obj.positivePercent === 0 &&
        obj.neutralPercent === 0
      ) {
        return {
          name: key,
          noData: true,
          sum: 0,
        };
      }
      return {
        name: key,
        noData: false,
        data: [
          {
            name: 'negative',
            value: helper.numeral(obj.negative),
            percent: obj.negativePercent,
          },
          {
            name: 'neutral',
            value: helper.numeral(obj.neutral),
            percent: obj.neutralPercent,
          },
          {
            name: 'positive',
            value: helper.numeral(obj.positive),
            percent: obj.positivePercent,
          },
        ],
        sum:
          parseInt(obj.negative) +
          parseInt(obj.neutral) +
          parseInt(obj.positive),
      };
    };

    const getColor = (catKey, index) => {
      let color;
      let result;
      if (
        categoryData.value &&
        categoryData.value[qs.level] &&
        categoryData.value[qs.level].categories
      ) {
        const categories = categoryData.value[qs.level].categories;
        result = categories.find(
          (c) => c.visible && c.category === catKey.toLowerCase()
        );
      }
      color = result && result.color ? result.color : null;

      if (!color) {
        color = helper.getColor(catKey, index);
      }
      return color;
    };

    const convertValueToPercent = (value, total) => {
      return numeral((value / total) * 100).format('0.00');
    };

    // Modal
    const onChartClick = (params) => {
      if (mode.value === 'engagement') {
        sortBy.value = 'engagement_score';
      } else if (mode.value === 'view') {
        sortBy.value = 'view_count';
      } else if (mode.value === 'mention') {
        sortBy.value = 'comment_count';
      }
      const newFilter = JSON.parse(JSON.stringify(filterResult.value));
      let f = {
        payload: {
          title: '',
        },
        criteria: {
          ...newFilter,
          paging: {
            page: 1,
            recordPerPage: 10,
          },
          sort: {
            direction: 'desc',
            field: sortBy.value,
          },
          highlight: {
            enable: true,
          },
        },
      };
      if (type.value.toLowerCase() === 'category') {
        // level > 0 --> sub-category
        const category = params.name.toLowerCase();
        f.payload.title = categoryByLevel.value.name
          ? `${categoryByLevel.value.name}: ${params.name}`
          : `${type.value}: ${params.name}`;
        if (categoryByLevel.value.level > 0) {
          // f.criteria['category'] = [];
          // console.log('CHARTCLICK', f.criteria.subCategory, categoryByLevel.value);

          if (filterResult.value.matchCategoryMode === 'all') {
            if (filterResult.value.subCategory.length > 0) {
              for (let i in f.criteria['subCategory']) {
                if (!f.criteria['subCategory'][i].category.includes(category)) {
                  if (
                    f.criteria['subCategory'][i].level ==
                    categoryByLevel.value.level
                  ) {
                    f.criteria['subCategory'][i].category = [category];
                  }
                } else {
                  if (
                    f.criteria['subCategory'][i].level ==
                    categoryByLevel.value.level
                  ) {
                    f.criteria['subCategory'][i].category = [category];
                  }
                  // f.criteria['subCategory'] = JSON.parse(JSON.stringify(filterResult.value.subCategory));
                }
              }
              f.criteria['subCategory'] = f.criteria['subCategory'].concat({
                level: categoryByLevel.value.level.toString(),
                category: [category],
              });
            } else {
              f.criteria['subCategory'] = [category];
            }
          } else {
            if (filterResult.value.subCategory.length > 0) {
              for (let i in f.criteria['subCategory']) {
                if (!f.criteria['subCategory'][i].category.includes(category)) {
                  if (
                    f.criteria['subCategory'][i].level ==
                    categoryByLevel.value.level
                  ) {
                    f.criteria['subCategory'][i].category = [category];
                  }
                } else {
                  if (
                    f.criteria['subCategory'][i].level ==
                    categoryByLevel.value.level
                  ) {
                    f.criteria['subCategory'][i].category = [category];
                  }
                }
              }

              const levelList = f.criteria['subCategory'].map(
                (item) => item.level
              );
              if (!levelList.includes(categoryByLevel.value.level)) {
                f.criteria['subCategory'].push({
                  level: `${categoryByLevel.value.level}`,
                  category: [category],
                });
              }
            } else {
              f.criteria['subCategory'] = [
                {
                  level: categoryByLevel.value.level,
                  category: [category],
                },
              ];
            }
          }
        } else {
          if (filterResult.value.matchCategoryMode === 'all') {
            if (filterResult.value.category.length > 0) {
              f.criteria['category'] = JSON.parse(
                JSON.stringify(filterResult.value.category)
              );
            } else {
              f.criteria['category'] = [category];
            }
          } else {
            f.criteria['category'] = [category];
          }
        }
        // console.log('FIN', f.criteria);
      } else if (type.value.toLowerCase() === 'channel') {
        const source = params.name.toLowerCase();

        if (filterResult.value.source.length > 0) {
          if (
            JSON.parse(
              JSON.stringify(filterResult.value.source).includes(source)
            )
          ) {
            f.criteria['source'] = [source];
          } else {
            f.criteria['source'] = JSON.parse(
              JSON.stringify(filterResult.value.source)
            );
          }
        } else {
          f.criteria['source'] = [source];
        }

        f.payload.title = `${params.name} channel`;
        f.criteria['title'] = params.name.toLowerCase();
        f.criteria['type'] = 'channel';
      } else if (type.value.toLowerCase() === 'sentiment') {
        const sentiment = params.name.toLowerCase();
        f.criteria['sentiment'] = [sentiment];
        f.payload.title = `${params.name} sentiment`;
      }
      dispatch('message/showMessageModal', f);
    };

    const getSentiment = async () => {
      if (filterResult.value) {
        let level = qs.level;
        const result = await api.getCategoryPieSentiment(filterResult.value, {
          level,
        });
        if (result.message && result.message.categoryPortionSentiment) {
          // { cat: { count, engagement, view }}
          // TO count[], engage[], view[]
          const countList = [];
          const engageList = [];
          const viewList = [];
          for (let categoryKey in result.message.categoryPortionSentiment) {
            const { count, engagement, view } =
              result.message.categoryPortionSentiment[categoryKey];
            countList.push(convertPortionData(categoryKey, count));
            engageList.push(convertPortionData(categoryKey, engagement));
            viewList.push(convertPortionData(categoryKey, view));
          }
          sentimentDataByMode.engagement = engageList;
          sentimentDataByMode.mention = countList;
          sentimentDataByMode.view = viewList;

          let sumTotalEngagement = 0;
          let sumTotalMention = 0;
          let sumTotalView = 0;
          for (let i = 0; i < sentimentDataByMode.engagement.length; i++) {
            sumTotalEngagement += parseInt(
              sentimentDataByMode.engagement[i].sum
            );
          }
          sentimentDataByMode.engagement.forEach((object) => {
            object.sumTotal = sumTotalEngagement;
          });
          for (let i = 0; i < sentimentDataByMode.mention.length; i++) {
            sumTotalMention += parseInt(sentimentDataByMode.mention[i].sum);
          }
          sentimentDataByMode.mention.forEach((object) => {
            object.sumTotal = sumTotalMention;
          });
          for (let i = 0; i < sentimentDataByMode.view.length; i++) {
            sumTotalView += parseInt(sentimentDataByMode.view[i].sum);
          }
          sentimentDataByMode.view.forEach((object) => {
            object.sumTotal = sumTotalView;
          });

          sentimentData.value = sentimentDataByMode[mode.value].sort((a, b) => {
            if (a.name === 'others') {
              return 1;
            } else if (b.name === 'others') {
              return -1;
            } else if (a.sum >= b.sum) {
              return -1;
            } else {
              return 0;
            }
          });
          await nextTick();
          hideOverflowTitle();
        }
      }
      loading.value = false;
    };

    // INIT part
    const init = () => {
      loading.value = true;
      getCategory();
      getSentiment();
    };

    const onSegmentClick = (categoryData, sentimentData) => {
      const level = categoryByLevel.value.level;
      const newFilter = JSON.parse(JSON.stringify(filterResult.value));
      const sentiment = [sentimentData];
      const category = [categoryData]; // selected
      let f = {
        payload: {
          title: `Data for "${categoryData}" category and "${sentimentData}" sentiment `,
        },
        criteria: {
          sentiment,
          category: newFilter.category,
          subCategory: newFilter.subCategory,
          time: newFilter.time,
          sort: {
            direction: 'desc',
            field: 'engagement_score',
          },
          highlight: {
            enable: true,
          },
        },
      };
      if (level > 0) {
        for (let i in f.criteria['subCategory']) {
          if (f.criteria['subCategory'][i].level == level) {
            f.criteria['subCategory'][i].category = category;
          }
        }
      } else {
        f.criteria['category'] = category;
      }

      // if (level === 0) {
      //   f.criteria.category = category;
      // } else {
      //   f.criteria.subCategory = [
      //     {
      //       level,
      //       category,
      //     },
      //   ];
      // }
      store.dispatch('message/showMessageModal', f);
    };

    const hideOverflowTitle = () => {
      const allSegment = document.querySelectorAll('.prog-inner-element');
      // console.log('ALLSS', allSegment);
      if (allSegment) {
        allSegment.forEach((obj) => {
          // console.log(obj.offsetWidth, obj.parentElement.offsetWidth);
          if (obj.offsetWidth > obj.parentElement.offsetWidth) {
            obj.style.visibility = 'hidden';
          } else {
            obj.style.visibility = 'visible';
          }
        });
      }
    };

    const handleModeClick = (e) => {
      mode.value = e.key;
    };

    const selectedModeStyle = {
      color: ' #097cff',
      background: 'rgba(9, 124, 255, 0.05)',
    };

    const unSelectedModeStyle = {
      color: '#5A5F7D',
    };

    watch(
      () => filterResult.value,
      () => {
        init();
      },
      { deep: true }
    );

    watch(mode, () => {
      sentimentData.value = sentimentDataByMode[mode.value].sort((a, b) => {
        if (a.name === 'others') {
          return 1;
        } else if (b.name === 'others') {
          return -1;
        } else if (a.sum >= b.sum) {
          return -1;
        } else {
          return 0;
        }
      });
    });

    watch(categoryByLevel, () => {
      // change level on category pie chart
      qs.level = categoryByLevel.value.level;
      init();
    });

    onMounted(() => {
      init();
    });

    return {
      loading,
      selectedData,
      onChartClick,
      noResult,
      qs,
      sentimentData,
      onSegmentClick,
      hideOverflowTitle,
      isMobileScreen,
      handleModeClick,
      modeOptions,
      selectedModeStyle,
      unSelectedModeStyle,
      categoryByLevel,
      categoryLevel,
      campaignLevel,
      sumSentiment,
      numeral,
      getColor,
      sortBy,
    };
  },
};

export default ChannelAndCategory;
</script>

<style lang="scss" scoped>
@import '../../../config/theme/colors.json';
.category-col {
  padding: 0 !important;
}
.sentiment-col {
  padding: 0 !important;
  @media (max-width: 1199px) {
    margin-top: 8px;
  }
}
.item-wrapper {
  list-style-type: none;
  counter-reset: css-counter 0;
}

.title-count-number {
  color: #272b41;
  font-size: 14px;
  min-width: 20px;
  flex: 0 0 20px;
  font-weight: 500;
  text-transform: capitalize;
}

.item-wrapper div div.item-row span.title-count-number {
  counter-increment: css-counter 1;
}

.item-wrapper div div.item-row span.title-count-number::before {
  content: counter(css-counter) '. ';
}

.piechart-content {
  width: auto;
  height: 530px;
  display: flex;
  align-items: center;
  .piechart-no-data {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    text-align: center;
  }
}

.progress-bar-loading {
  min-height: 470px;
  max-height: 470px;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.progress-bar-category {
  overflow-y: auto;
  overflow-x: hidden;
  min-height: 470px;
  max-height: 470px;
  scroll-behavior: smooth;

  &::-webkit-scrollbar {
    width: 5px;
  }

  &::-webkit-scrollbar-track {
    background-color: #eee;
  }

  &::-webkit-scrollbar-thumb {
    background-color: #c1c1c1;
    border-radius: 6px;
  }
}

.category-top-right-action {
  align-items: center;

  .button-primary-light {
    /* TODO Refactor to outline btn */
    background: #2c99ff15;
    border-width: 0px;
    color: $primary-color;

    &:focus {
      background: #2c99ff15;
      border-width: 0px;
      color: $primary-color;
    }
  }

  // button {
  //   padding: 0;
  //   min-width: 40px;
  //   height: 40px;
  //   border-radius: 50%;
  //   display: inline-flex;
  //   align-items: center;
  //   justify-content: center;
  //   span {
  //     margin-right: 0;
  //     color: #fff;
  //   }
  // }
}

.loading-wrapper {
  height: 438px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin: 0px 20px;
}
.item-wrapper {
  height: 438px;
  overflow-y: auto;
  .sentiment-data {
    @media (max-width: 590px) {
      border-bottom: 1px solid #f1f2f6;
      padding-bottom: 12px;
    }
  }
  .sentiment-item-row {
    column-gap: 25px;
    padding: 8px 0;
    border-bottom: 1px solid #f1f2f6;
    &.sentiment-item-row-header {
      border-bottom: 1px solid #f1f2f6;
    }
    @media (max-width: 590px) {
      border-bottom: none;
    }
    @media (max-width: 367px) {
      column-gap: 15px;
    }
    @media (max-width: 335px) {
      column-gap: 10px;
    }
    .sentiment-item-col {
      padding: 0 5px;
      &:nth-child(2) {
        max-width: 120px;
        min-width: 120px;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        @media (max-width: 1370px) {
          max-width: 80px;
          min-width: 80px;
        }
        @media (max-width: 1235px) {
          max-width: 60px;
          min-width: 60px;
        }
        @media (max-width: 1199px) {
          max-width: 120px;
          min-width: 120px;
        }
      }
      &:nth-child(4) {
        margin-right: 28px;
        margin-left: auto;
        @media (max-width: 340px) {
          margin-right: 20px;
        }
      }
      &.sentiment-prog-header {
        @media (max-width: 590px) {
          display: none;
        }
      }
      &.sumTotal-col {
        margin-right: 0;
      }
      .title-header {
        color: #272b41;
        font-size: 14px;
        text-transform: capitalize;
        font-weight: 500;
      }
      .title {
        color: #272b41;
        font-size: 14px;
        text-transform: capitalize;
      }
      .title-name {
        color: #272b41;
        font-size: 14px;
        font-weight: 400;
        text-transform: capitalize;
      }
    }
  }
}
.prog-bar {
  color: #272b41;
  font-size: 14px;
  font-weight: 500;
  flex: 1 1 auto;
  display: flex;
  .prog-segment {
    text-align: center;
    color: #fff;
    height: 24px;
    line-height: 24px;
    .prog-inner-element {
      font-size: 12px;
    }
    &.prog-segment-mobile {
      height: 2px;
    }
  }
  .has-data {
    cursor: pointer;
  }
  .no-data {
    background-color: #aaa;
    width: 100%;
  }
  .positive {
    background-color: #20c997;
  }
  .neutral {
    background-color: #febc3c;
  }
  .negative {
    background-color: #ff4d4f;
  }
  &.prog-bar-mobile {
    display: none;
    @media (max-width: 590px) {
      display: flex;
    }
  }
  &.prob-bar-desktop {
    @media (max-width: 590px) {
      display: none;
    }
  }
}
.ant-card-head .ant-card-head-wrapper {
  flex-flow: row;
  align-items: center;
}
.ant-card-head-wrapper .ant-card-extra {
  padding: 0px !important;
}
.more-menu {
  color: #9299b8;
}

@media screen and (max-width: 575px) {
  .text-gray {
    color: #9299b8;
  }
  .item-row .prog-bar {
    position: absolute;
    width: calc(100% - 70px);
    padding-top: 29px;
    // width: -webkit-fill-available;
    padding-right: 30px;
  }

  .item-row {
    padding-bottom: 10px;
  }

  .item-row .title-header,
  .item-row .title-name-header {
    padding-left: 10px;
  }

  .item-row .prog-bar .prog-segment {
    height: 2px;
  }

  .item-row .title-name-header,
  .item-row .title-name {
    min-width: calc(100% - 200px);
  }
}

@media screen and (max-width: 390px) {
  .item-row .title-name-header,
  .item-row .title-name {
    min-width: 100px;
    flex: 0 0 100px;
  }
}
</style>
